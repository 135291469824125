import React, { useState } from 'react';
import {allPackages} from './packageDetails';
var ksort = require('json-ksort')
var crypto = require('crypto');
const ProductContext = React.createContext();;

const ProductProvider = (props) => {

    const [loggedUser, setLoggedUser] = useState({ username: 'Guest', email: '', phoneno: '', startDate: '', address: '', name: '', clientID:'', orgName:'', profile:'', isAuthenticated: false, gstNo:'' });
    const [allClientAccounts, setAllClientAccounts] = useState('');
    const [selectedDomain, setSelectedDomain] = useState('');
    const [webHostAccounts, setWebHostAccoAunts] = useState([]);
    const [emailAccounts, setEmailAccounts] = useState([]);
    const [webDevelopAccounts, setWebDevelopAccounts] = useState([]);
    const [orderId, setOrderId] = useState('');
    const [orderRefId, setOrderRefId] = useState('');
    const [transactionDate, setTransactionDate] = useState('');
    const [cart, setCart] = useState([]);
    const [packages, setPackages] = useState(allPackages);
    const [cartSubTotal, setCartSubTotal] = useState(0);
    const [itemCount, setItemCount] = useState(0);
    const [cartId, setCartId] = useState(0);
    const [cartTax, setCartTax] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [discountValue, setDiscountValue] = useState(0);
    const [discountCode, setDiscountCode] = useState('');
    const [cartTotal, setCartTotal] = useState(0);
    const [billingDetails, setBillingDetails] = useState({clientId:"", domainName:"", clientPlan:"", planPrice:0, pocName:"", pocEmail:"", pocPhone:"", pocAddress:"", lastRenewalDate:"", clientPlanDetails:[], renewYears:"", totalAmount:0, gstAmount:0, renewalUpto:""});
    const [bottomValue, setBottomValue]= useState('');
    const [showPage, setShowPage]=useState(false);
    const [accountsFetched, setAccountsFetched] = useState(false);
    const [generatedSignature, setGeneratedSignature] = useState('');
    const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});
    const [payTMTokenRes, setPayTMTokenRes]=useState();

    const alertClose=()=>{
        setAlertDetails({
            ...alertDetails,
            style:"",
            open:false,
            message:""
        });
    }

    const setProducts =() => {
        let tempProducts = [];
        //change made
        packages.forEach(item => {
            const singleItem = {...item };
            tempProducts = [...tempProducts, singleItem];
              })
        setPackages(tempProducts);
      //  console.log("Products :", this.state.products);
    };

    const setRefreshProducts =() => {
        let tempProducts = [];
        //change made
        packages.forEach(item => {
            var singleItem = {...item };
       //     console.log("Cart Value : ", singleItem.inCart);
            singleItem.inCart=false;
            tempProducts = [...tempProducts, singleItem];
              })
        setPackages(tempProducts);
      //  console.log("Products :", tempProducts);
    };

    const setAccounts =async() => {
        let tempHostAccounts = [];
        let tempDevAccounts=[];
        let tempEmailAccounts=[];
        //change made
        if(allClientAccounts.length>0){
        const tempHost = allClientAccounts.filter(plan => plan.packageId.includes('IDN-WH'));
        const tempDevelop = allClientAccounts.filter(plan => plan.packageId.includes('IDN-WD'));
        const tempEmail = allClientAccounts.filter(plan => plan.packageId.includes('IDN-EH'));
                        
       await tempHost.forEach(item => {
            const singleItem = {...item };
            tempHostAccounts= [...tempHostAccounts, singleItem];
              })
        await tempDevelop.forEach(item => {
                const singleItem = {...item };
                tempDevAccounts= [...tempDevAccounts, singleItem];
                  })
        await tempEmail.forEach(item => {
                    const singleItem = {...item };
                    tempEmailAccounts= [...tempEmailAccounts, singleItem];
                      })
        await setWebDevelopAccounts(tempDevAccounts);
        await setWebHostAccoAunts(tempHostAccounts);
        await setEmailAccounts(tempEmailAccounts);
        //console.log("temp web accounts :", tempHostAccounts);
                    }
    };

    const getItem = (id) => {
        const product = packages.find(item => item.packageId === id);
        return product;
    };

    const getAccount = (id) => {
        //console.log("ID : ", id);
        const product = allClientAccounts.find(item => item.accId === id);
        //console.log("Product :", product);
        return product;
    };
    
    /*const getCart = (id) => {
        const product = this.state.products.find(item => item.cartno === id);
        return product;
    };*/

    /*const handleDetail =(id)=>{
        const product = this.getItem(id);
        this.setState(()=>{
            return {detailProduct:product}
        })
     };*/
 
     const generateOrderdate=(year)=>{
        const current_datetime = new Date();
        const temp="";
        const date_year = (current_datetime.getFullYear()+year).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        const orderDate = temp.concat(date_year,"-",month_value,"-", date_value);
        return orderDate;
    }

    const generateOrderDateTime=()=>{
        const current_datetime = new Date();
        const temp="";
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hour_value="";
        date_hours.length===1?(hour_value=hour_value.toString().concat(0,date_hours)):(hour_value=date_hours);
        var date_mins = (current_datetime.getMinutes().toString());
        var mins_value="";
        date_mins.length===1?(mins_value=mins_value.toString().concat(0,date_mins)):(mins_value=date_mins);
        var date_secs = (current_datetime.getSeconds().toString());
        var secs_value="";
        date_secs.length===1?(secs_value=secs_value.toString().concat(0,date_secs)):(secs_value=date_secs);
        const orderDate = temp.concat(date_year,month_value,date_value,hour_value,mins_value,secs_value);
        return orderDate;
    }

    const generateRenewYear = async(tYear,action)=>{
        const tempDate= new Date(tYear);
        var tempYear="";
        if(action==="Add"){
            tempYear = (tempDate.getFullYear()+1).toString();
        }else{
            tempYear = (tempDate.getFullYear()-1).toString();
        }
        const tempMonth = (tempDate.getMonth()+1).toString();
        const tempDate1= tempDate.getDate().toString();
        var tempRenew = "";
        const renewDate = tempRenew.concat(tempYear,"-",tempMonth,"-",tempDate1);
        return renewDate;
    }
 
    const addToCart = async (id, type)=>{
            let cartidtemp = cartId;
            cartidtemp= cartidtemp + 1;
            let tempProducts = [...packages];
            let tempAccounts = [...allClientAccounts];
            let itemcount = itemCount;
            itemcount= itemcount + 1;
            var index="";
            var product="";
            var tempAccID="";
            if(type==="New"){
                const dateTimeValue=generateOrderDateTime();
                const dateValue=generateOrderdate(0);
                index = tempProducts.indexOf(getItem(id));
                product = tempProducts[index];
                product.accId=tempAccID.concat(id,dateTimeValue);
                product.packageStartDate=dateValue;
                product.validFrom=dateValue;
                product.validTo=generateOrderdate(1);
            }else{
                index = tempAccounts.indexOf(getAccount(id));
                product = tempAccounts[index];
                product.validTo=await generateRenewYear(product.validTo,"Add");
                product.renewedDate=await generateOrderdate(0);
            }
            product.inCart = true;
            product.years = 1;
            product.cartno=cartidtemp;
            product.category=type;
            const price = parseFloat(product.packagePrice);
            product.total = price;
            const tempCart=[...cart];
            tempCart.push(product);
            setCart([...tempCart]);
            setCartId(cartidtemp);
            setItemCount(itemcount);
            addTotals(tempCart);
            setProducts();
        
    };

 /*const allAccountsUpdate=async(accid)=>{
    let tempAccounts = [...allClientAccounts];
    let tempCart=[...cart];
    var selectedProduct; 
        selectedProduct = tempAccounts.find(item=>item.accId === accid)
    const index = tempAccounts.indexOf(selectedProduct);
    const product = tempAccounts[index];
   // const udpatedYear = await generateRenewYear(product.validTo,"Add");
   product.inCart = true;
    //product.validTo=udpatedYear;
    //product.years = product.years + 1;
    //let temptotal = product.years * product.packagePrice;
   // product.total = temptotal;
    await setAllClientAccounts([...tempCart]);
 }
*/
const increment = async(id, category, accid) =>{
    let tempCart=[...cart];
    var selectedProduct;
    if(category==="New"){
       // selectedProduct = tempCart.find(item=>item.packageId === id)
        selectedProduct = tempCart.find(item=>item.accId === accid)

    }else{
        selectedProduct = tempCart.find(item=>item.accId === accid)
    }
    const index = tempCart.indexOf(selectedProduct);
    const product = tempCart[index];
    const udpatedYear = await generateRenewYear(product.validTo,"Add");
    product.validTo=udpatedYear;
    product.years = product.years + 1;
    let temptotal = product.years * product.packagePrice;
    product.total = temptotal;
    await setCart([...tempCart]);
    addTotals(tempCart);    
};

const decrement = async(id, category,accid) =>{
    let tempCart=[...cart];
    var selectedProduct;
    if(category==="New"){
   //     selectedProduct = tempCart.find(item=>item.packageId === id)
        selectedProduct = tempCart.find(item=>item.accId === accid)

    }else{
        selectedProduct = tempCart.find(item=>item.accId === accid)
    }    
    const index = tempCart.indexOf(selectedProduct);
    const product = tempCart[index];
    product.validTo=await generateRenewYear(product.validTo,"Sub");
    product.years = product.years - 1;
    if(product.years === 0){
        if(category==="New")
        removeItem(id, accid, category);
        else
        removeItem(id, accid, category);

    }
    else {
        let temptotal = product.years * product.packagePrice;
        product.total = temptotal;
        setCart([...tempCart]);
        addTotals(tempCart);
      /*  this.setState(
            ()=>{
                return{cart:[...tempCart]};
            }, 
            ()=>{
                addTotals();
            });
    */
    }

};
const removeItem = async(id, accid, category) =>{
    let tempProducts = [...packages];
    let tempAccounts = [...allClientAccounts];
    let tempCart = [...cart];
    let itemcount = itemCount;
    itemcount= itemcount - 1;
    var index;
    let removedProduct;
    if(category==="New"){
        //tempCart = tempCart.filter(item => item.packageId !==id);
        tempCart = tempCart.filter(item => item.accId !==accid);
        index = tempProducts.indexOf(getItem(id));
        removedProduct = tempProducts[index];
    }else{
        tempCart = tempCart.filter(item => item.accId !==accid);
        index = tempAccounts.indexOf(getAccount(accid));
        removedProduct = tempAccounts[index];
    }
    //console.log("Removed product : ", removedProduct);
    removedProduct.inCart=false; 
    removedProduct.total=0;        
    setCart([...tempCart]);
    //console.log("Temp Cart : ", tempCart);
    //console.log("Temp accounts : ", tempAccounts);
    //setAllClientAccounts([...tempAccounts]);
    await setProducts();
    await setAccounts();
    setItemCount(itemcount);
    addTotals(tempCart);
}

const clearCart = () =>{
  //  console.log("Clearing cart");
    setCart([]);
    setRefreshProducts();
    const tempCart=[];
    addTotals(tempCart);
};

const addTotals =async(tempCart) =>{
    let subTotal=0;
    await tempCart.map(item => (subTotal += item.total));
    const tax = parseFloat(subTotal) * 18/100;
    const total = subTotal + tax;
    setCartSubTotal(parseFloat(subTotal).toFixed(2));
    setCartTax(parseFloat(tax).toFixed(2));
    setCartTotal(parseFloat(total).toFixed(2));
}

const processDiscount =async(tempdiscountValue, tempCode) =>{
    let subTotal=cartSubTotal;
    //await tempCart.map(item => (subTotal += item.total));
    const tempDiscountAmount=subTotal*tempdiscountValue/100;
    const tempsub=subTotal-tempDiscountAmount;
    const tax = parseFloat(tempsub) * 18/100;
    const total = (subTotal-tempDiscountAmount) + tax;
    //setCartSubTotal(parseFloat(subTotal).toFixed(2));
    await setCartTotal(parseFloat(total).toFixed(2));
    setCartTax(parseFloat(tax).toFixed(2));
    setDiscountValue(tempdiscountValue);
    setDiscountCode(tempCode);
    setDiscountAmount(parseFloat(tempDiscountAmount).toFixed(2));
    return total.toFixed(2);
}

const createSignature = async (tPhone, ttotal) => {
    // console.log("creating signature");
    var appId="";
    var sKey="";
    var actionURL="";
    const returnURL=process.env.REACT_APP_CF_RETURN_URL;
    if(process.env.NODE_ENV==="development"||process.env.NODE_ENV==="test"){
        appId=process.env.REACT_APP_CF_TEST_APPID;
        sKey=process.env.REACT_APP_CF_TEST_SKEY;
        actionURL="https://test.cashfree.com/billpay/checkout/post/submit";
    }else{
        appId=process.env.REACT_APP_CF_APPID;
        sKey=process.env.REACT_APP_CF_SKEY;
        actionURL="https://www.cashfree.com/checkout/post/submit";
    }
     var postData = {
         'appId': appId,
         'orderId': orderRefId,
         'orderAmount': parseFloat(ttotal),
         'customerName': loggedUser.username,
         'customerEmail': loggedUser.email,
         'customerPhone': tPhone,
         'orderCurrency': "INR",
         'orderNote': loggedUser.clientID,
         'returnUrl': returnURL
     }
   //  console.log("Signature data : ", postData);
     var sortedKeys = await ksort(postData);
     var signatureData = "";
     for (let key in sortedKeys) {
         signatureData += key + postData[key];
     }
     let token = crypto.createHmac("sha256", sKey).update(signatureData).digest().toString('base64');
     setGeneratedSignature(token);
    // console.log("signature created");
 }
 
    return (
        <ProductContext.Provider value={{
            loggedUser,
            setLoggedUser,
            webHostAccounts,
            setWebHostAccoAunts,
            emailAccounts,
            setEmailAccounts,
            webDevelopAccounts,
            setWebDevelopAccounts,
            allClientAccounts,
            setAllClientAccounts,
            selectedDomain,
            setSelectedDomain,
            orderId,
            setOrderId,
            orderRefId,
            setOrderRefId,
            transactionDate,
            setTransactionDate,
            billingDetails,
            setBillingDetails,
            packages,
            cart,
            setCart,
            cartSubTotal,
            itemCount,
            cartId,
            cartTax,
            discountAmount,
            discountValue,
            discountCode,
            cartTotal, 
            addToCart,
            clearCart,
            increment,
            decrement,
            removeItem,
            bottomValue,
            setBottomValue,
            setShowPage,
            processDiscount,
            accountsFetched,
            setAccountsFetched,
            createSignature,
            generatedSignature,
            setGeneratedSignature,
            alertDetails,
            setAlertDetails,
            alertClose,
            payTMTokenRes,
            setPayTMTokenRes
        }}>
            {props.children}
        </ProductContext.Provider>
    )
}

export { ProductProvider, ProductContext };