import React , {useContext, useEffect} from 'react'
//import {temp_client_accounts,tempClients} from '../../packageDetails';
import { ProductContext } from '../../ProductProvider';
import { Table } from 'reactstrap';
import useFullPageLoader from "../hooks/useFullPageLoader";
import { useHistory } from 'react-router-dom';
import {Helmet} from 'react-helmet-async';


export default function MyWebDevAcc() {
  let history=useHistory();
  const { webDevelopAccounts, addToCart, loggedUser } = useContext(ProductContext);
    const [loader] = useFullPageLoader();
    

    const handleRenew =async(event, id)=>{
        event.preventDefault();
        //console.log("clicked");
        addToCart(id,"Renew");
    }
    useEffect(() => {
   
      window.scrollTo(0,0);
      
  }, [])

  const handleReRoute =()=>{
    history.push("/signin");
  }
  

    return (
      <div>
      <Helmet>
      <title>My Web Development Plans - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="My Web Development Plans - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="My Web Development Plans - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

        
        {loggedUser.isAuthenticated?
       
            <div className="container" style={{paddingTop:"75px", paddingBottom:"60px"}}>
                <h4 className="text-title"><i className="fa fa-code fa-fw" aria-hidden="true"></i>&nbsp; My Web Development Details</h4>
                <div style={{textAlign:"center"}}> {loader} </div>
            {webDevelopAccounts.length>0?<div>
                <Table hover responsive bordered>
              <thead>
                <tr>
                <th>Action</th>
                <th>Acc ID</th>
                  <th>Package ID</th>
                  <th>Name</th>
                  <th>Details</th>
                  <th>Started</th>
                  <th>Valid</th>
                  <th>Price</th>
                </tr>
              </thead>      
              <tbody>
        {webDevelopAccounts.map((searchresult,index) =>(
        <tr key={index}>
          <td>{searchresult.inCart?<balloon aria-label="In Cart" data-balloon-pos="up"><div style={{fontSize:"30px"}}><i className="fa fa-check-circle"></i></div></balloon>:<balloon aria-label="Add to Cart" data-balloon-pos="up"><div style={{fontSize:"30px"}}><i className="fa fa-cart-plus" onClick={(event)=>{handleRenew(event, searchresult.accId)}}></i></div></balloon>}</td>
          <th scope="row" key={searchresult.accId}>{searchresult.accId}</th>
          <td>{searchresult.packageId}</td>
          <td>{searchresult.packageName}</td>
          <td>{searchresult.packageDetails}</td>
          <td>{searchresult.packageStartDate}</td>
          <td>{searchresult.validTo}</td>
          <td> {searchresult.packagePrice}</td>
        </tr>))}
      
        </tbody></Table>

            </div>:
            <h5 style={{textAlign:"center", padding:"20px"}} className="tile notification is-warning"><b>No Web development accounts found</b></h5>
            }  
            </div>:<div>{handleReRoute()}</div>}
            </div>
       
    )
}
