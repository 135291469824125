import React, { useEffect, useContext, useState } from 'react';
//import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import { ProductContext } from '../../ProductProvider';
//import PaymentCF from '../payment/PaymentCF';
//import {Col,Row, Container} from 'reactstrap';
import CartListCheckOut from '../checkout/CartListCheckOut';
import CartTotalCheckOut from '../checkout/CartTotalCheckOut';
import { useHistory } from 'react-router-dom';
import CartBillingDetails from '../checkout/CartBillingDetails';
import { Button, Alert, Container } from 'reactstrap';
import Preloader from '../preloader/Preloader';
//import paytmlogo from '../../images/logos/Paytm_Logo.png';
import axios from "axios";
import { Auth } from "aws-amplify";

//import ContactUs from '../ContactUs';
//import PayTM from '../payment/paytm/PayTM';
//import PayTMJS from '../payment/paytm/PayTMJS';
//import PayTM from '../payment/PayTM';
//import PayTM from '../payment/paytm/PayTM';
//import { PaytmButton } from '../payment/PayTMButton';
//import PayTMJS from '../payment/paytm/PayTMJS';
import {Helmet} from 'react-helmet-async';
const config = require("../../config");

export default function CheckOut() {
    let history=useHistory();
    //const { cart, alertDetails, alertClose} = useContext(ProductContext);
    const { orderId, orderRefId, transactionDate, cart, cartTotal, loggedUser, cartTax, cartSubTotal, 
        discountAmount, discountValue, discountCode, alertDetails, setAlertDetails,alertClose, 
        } = useContext(ProductContext);

    const [loaded, setLoaded] = useState(true);

    useEffect(() => {
        if(cart.length<1){
            history.push('/cart');
        }
    }, [])

    const handlePayTMRedirect=()=>{
        history.push('/order-preview');
    }

    const handleAddNewTransaction = async () => {
        //props.setDispMsg('');
       // console.log("add to database");
        // add call to AWS API Gateway add product endpoint here
        try {
                setLoaded(false);
                const session = await Auth.currentSession();
                const access_token=session.idToken.jwtToken;
                const orderid=orderId;
                const params = {
                    "clientId": loggedUser.clientID,
                    "orgName": loggedUser.orgName,
                    "pocName": loggedUser.username,
                    "pocEmail": loggedUser.email,    
                    "pocPhone": loggedUser.phoneno,
                    "pocAddress":loggedUser.address,
                    "orderId": orderid,
                    "orderRefId": orderRefId,
                    "orderAmount": cartTotal,
                    "referenceId":"NA",
                    "txStatus": "Payment Pending",
                    "paymentMode": "NA",
                    "txMsg": "NA",
                    "txTime": "NA",
                    "transactionDate" : transactionDate,
                    "gstCharges": cartTax,
                    "discountAmount":discountAmount,
                    "discountValue":discountValue,
                    "discountCode":discountCode,
                    "subTotal":cartSubTotal,
                    "cart":cart
                };
                //console.log("DB Values : ", params);
            await axios.post(`${config.api.invokeUrl}/client/accounts/transactions/${orderid}`, params,{
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }})
            .then(()=>{
              //  console.log("Success");
              setLoaded(true);
              history.push('/order-preview');
            })
            .catch((error)=>{
                setLoaded(true);
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                });                  
            })
        }catch (err) {
            setLoaded(true);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + err.message
            });     
        }
    }

  

   /* const handleCouponCodeValidation =(event) =>{
        event.preventDefault();
        console.log("Coupon code validated");
        if(couponCode===""){
            setDispMsg("No Code Entered");
        }else if(inputCode===couponCode){
            setDispMsg("Code matched");
            const tempTotal = parseFloat(totalAmount)*(discount/100);
            console.log("discount amount = ", tempTotal);
            setBillingDetails({...billingDetails,
                payableAmount:tempTotal
            });
        }else {
            setDispMsg("Incorrect Code");
        }
    }*/
   /* const handleRenewCalc = (event) => {
        setRenewYears(event.target.value);
        const tempPlan = parseFloat(event.target.value) * parseFloat(planPrice);
        //const tempTransaction = tempPlan * (2/100); 
        const tempGst = tempPlan * (16/100);
        //const tempAmount = parseFloat(tempTransaction) + parseFloat(tempPlan) + parseFloat(tempGst);
        const renewalYears = parseFloat(lastRenewalDate.substring(0,4))+parseFloat(event.target.value);
        const renewalMonths=renewalYears.toString()+lastRenewalDate.substring(4,lastRenewalDate.length);
        //console.log("new year : ", renewalMonths);
        setRenewalUpto(renewalMonths);
        setTotalAmount(tempPlan);
        //setTransactionAmount(tempTransaction);
        setGstAmount(tempGst);
        setPlanCharges(tempPlan);
    }*/

    return (
    <>
    <Helmet>
      <title>CheckOut - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="CheckOut - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="CheckOut - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

        {!loaded?<Preloader />:null}
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose}>
        {alertDetails.message}
        </Alert>

        <div className="main-signin" style={{paddingTop:"50px"}}>
             <h2 className="text-title" style={{color:"black", paddingTop:"40px"}}> Finalize your Order</h2>
     {/*   <div style={{paddingBottom:"75px"}}>
            
  <h2 className="text-title" style={{color:"black", paddingTop:"120px"}}> Finalize your Order</h2>

        <div className="navbar-bg" >
            <div style={{margin:"auto",display:"flex",  justifyContent:"center"}}>
                 <Col sm={8}>
             

           <CartListCheckOut />
           
           </Col>
           
                        <Col sm={4}>  
          
           <CartTotalCheckOut />
           <PaymentCF setDispMsg={setDispMsg}/>
           
            </Col> 
         
            </div>
          
           </div>
           <div className="navbar-mobile" >
           <Col sm={8}>
             

           <CartListCheckOut />
           
           </Col>
           
                        <Col sm={4}>  
        <CartBillingDetails />
           <CartTotalCheckOut />
           <PaymentCF setDispMsg={setDispMsg}/>
           
            </Col> 
         
           </div>
            </div>
     */}
       
            <div className="container-fluid" style={{paddingBottom:'50px'}}>
           
        

                <div className="scroll-div" id="style-1">
                    <div className="row">
                        <div className="col-12 col-md-12 col-sm-12" >
                            <React.Fragment>
                            <div className="cart-title-dis">
                                {/*}  <Title name="your" title="cart" />
                                <p>  Delivery Type: {deliveryType} <i className="fa fa-pencil-square-o" aria-hidden="true" onClick={this.handleUpdate}></i></p>*/}
                                <div className="cart-value-div" id="style-1">
                                <CartListCheckOut/>
                                </div>
                                <CartBillingDetails />
           <CartTotalCheckOut setLoaded={setLoaded}/>
           <Container>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Button type="submit" className="pull-center" onClick={handleAddNewTransaction}>Place Order</Button>

          {/*} <Button type="submit" className="pull-center" onClick={handlePayTMRedirect}><img src={paytmlogo} height="30" width="90"/></Button>*/}
          {/*} <div className="d-flex justify-content-center">  {<PaymentCF />} 

          </div>
          <div className="d-flex justify-content-center">  {<PayTMJS />} 

                            </div>*/}
        </div>
          </Container>
        </div>
    </React.Fragment>                
    </div>
    </div>
    </div>
    </div>
    </div>   
    </>
    )
}
