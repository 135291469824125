import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";
import PrivacyPolicy from './PrivacyPolicy';
import RefundPoilcy from './RefundPoilcy';
import TermsPolicy from './TermsPolicy';
//import policyLogo from '../../images/policies.png';
import {Helmet} from 'react-helmet-async';

export default function Policy() {
    const [collapsed, setCollapsed] = useState(true);
	
    useEffect(() => {
        
        window.scrollTo(0,0);
        
   }, [])
		const toggleNavbar = () => {
			setCollapsed(!collapsed);
		}
        function winScroll(){
            window.scrollTo(0,0);
        }
	
	/*	const closeNavbar = () => {
			if (collapsed) {
				toggleNavbar();
			}
		}*/
    const [privacyShow, setPrivacyShow] = useState(true);
    const [termsShow, setTermsShow] = useState(false);
    const [refundShow, setRefundShow] = useState(false);

    const handleShow =(type, event)=>{
        event.preventDefault();
        setPrivacyShow(false);
        setTermsShow(false);
        setRefundShow(false);
        if(type==="Privacy"){
            setPrivacyShow(true);
        }else if(type==="Terms"){
            setTermsShow(true);
        } else if(type==="Refund"){
            setRefundShow(true);
        }else{
            setPrivacyShow(true);
        }


    }
    return (
    <>
    <Helmet>
      <title>Policies - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="Policies - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="Policies - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

        <div  style={{paddingTop:"60px"}}>
            <div  >
          {/*}          <div className="col-6 col-sm-6 col-lg-6 navbar-bg" >

            <img src={policyLogo} style={{ width: "10rem", height: "7rem" }} alt="logo"/>
    </div> */}
                <div className="col-6 col-sm-6 col-lg-6" >

          {/*}  <Link to='/#' onClick={(event)=>handleShow("Privacy", event)}>
                <center>Privacy Policy</center>
            </Link>

            <Link to='/#' onClick={(event)=>{handleShow("Terms", event)}}>
                <center>Terms of Service</center>
            </Link>

            <Link to='/#' onClick={(event)=>{handleShow("Refund", event)}}>
                <center>Refund Policy</center>
            </Link>

    */}
    <HeaderWrapper>
      <Navbar className="navigation__navbar" style={{left:"0px", position:"fixed", width:"100%", padding:"5px"}} color="primary"  light expand="md">
                    
                    <NavbarToggler onClick={toggleNavbar} />
                    <Collapse isOpen={!collapsed} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem className="headerdiv" >
                               
                                    <Link className="nav-link navigation__navlinks" 
                                    to='' onClick={(event)=>handleShow("Privacy", event)}>
                                        Privacy Policy
                                    </Link>
    
                            </NavItem>
                            <NavItem className="headerdiv">
                              
                                    <Link className="nav-link navigation__navlinks"
                                         to='' onClick={(event)=>handleShow("Terms", event)}>
                                        Terms of Service
                                    </Link>

                            </NavItem>
                            <NavItem className="headerdiv">
                                <Link
                                    
                                    className="nav-link navigation__navlinks"  
                                    to='' onClick={(event)=>handleShow("Refund", event)}>
                                    Refund Policy
                                </Link>
                            </NavItem>
							
							
                        </Nav>
                    </Collapse>
                </Navbar>
				</HeaderWrapper>
            </div>
            </div>
            <div>
                {privacyShow?<PrivacyPolicy />:""}
                {termsShow?<TermsPolicy />:""}
                {refundShow?<RefundPoilcy />:""}
            </div>
          <button onClick={winScroll}>  <i className="fa fa-arrow-up" style={{position:"fixed", bottom:"20px", right:"20px"}}> <p className="navbar-bg"> Go To TOP </p></i> </button>
        </div>
    </>
    )
}
	
const HeaderWrapper = styled.nav`
background: transparent;
.nav-link {
  color: #fff !important;
  font-size: 1.0rem;
  text-transform: capitalize;
  padding: 10px 0px 10px 0px;
  border-radius: 8px;
  font-family: "Oswald";
background: none;
}
.nav-link:focus {
	
    border-bottom: 5px solid #1362b3;
	border-radius: 0px;
}
.nav-link:hover{
	background-color: #1362b3;
	color: white !important;
}
 .headerdiv{
	 padding: 0px 10px 0px 10px;
 }
 
    
    `