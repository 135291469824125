import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import '../ChatStyle.css';
import UrlIcon from "../icons/call.svg";
import EmailHostPlans from '../../services/emailhost/EmailPlans';

import { getData } from "../data";

const EmailPlans = () => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getStats = async () => {
      //const stats = await getData();

      // const filteredFlights = flights.filter((item) => item.Status === null);

      setStats(stats);
      setLoading(false);
    };
    //getStats();
  }, []);

  return (
    <>
    <a href="https://idntica.com/email-services" className="tel-link">
    <h6> Click here to see your best and customized email plans</h6>
  </a>
  <div className="tel-link option-item options-container">
    <EmailHostPlans />
    Looking for more options - type 'help' 
  </div>

  </>

  );
};

export default EmailPlans;
