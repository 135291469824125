import React, { Component } from 'react';
import { NavHashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';

export default class Footer extends Component {  

     handleClick = async()  => {
      //    console.log("Testing", this.props);
          this.props.displayTerms(true);
     }

    
    render() {
        return ( <FooterWrapper>
            <div className="footer-bn">
                

                 
                   <footer id="footer" data-stellar-background-ratio="0.5">
                        <div className="navbar-bg">
          <div className="container" style={{background:"#fff"}}> 
         
               <div className="row justify-content-center">

                    <div className="col-sm mx auto">
                   
                         <div className="footer-info">
                              <div className="section-title">
                                   <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Our Office</h2>
                              </div>
                              <address className="wow fadeInUp" data-wow-delay="0.4s">
                                   <p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Plot 124, Road No 6, Balaji Nagar,<br /> Nizampet, Hyderabad, 500090</p>
                              </address>
                         </div>
                        
                    </div>
                    <div className="col-sm mx aut">
                    
                         <div className="footer-info">
                              <div className="section-title">
                                   <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Contact Us</h2>
                              </div>
                              <address className="wow fadeInUp" data-wow-delay="0.4s">
                              <p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}><i className="fa fa-phone">   </i>  +91 - 97890 99422</p>
                                   < p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}><i className="fa fa-envelope">  </i>  admin@idntica.com </p>
                                   <p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}><i className="fa fa-globe"><a style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}} href="idntica.com">    www.idntica.com</a> </i></p>
                              </address>
                         
                         </div>
                    </div>
                    

                  
                    <div className="col-sm mx aut">
                   

                         <div className="wow fadeInUp copyright-text" data-wow-delay="0.8s"> 
                         <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Copyright</h2>
                           
                        <Link  to="/policies" ><p style={{fontFamily:"Oswald", fontSize:"1.3rem", color:"black"}} className="footer-terms">Our Policies </p></Link>
                       {/*} <p style={{color:"#1362b3"}}>Privacy Policy</p> 

                         <p  style={{color:"#1362b3"}}>Terms of Service</p> 

        <p  style={{color:"#1362b3"}}>Refund Policy</p>  */}
               
                  <strong> <p style={{fontFamily:"Sansita Swashed", fontSize:"1.0rem", color:"black"}} > Copyright &copy; 2020 idntica.com </p> </strong>
                         
                         
                         </div>
                    
                    </div>
                    </div>
               </div>
          </div>




          <div className="container navbar-mobile" style={{background:"#fff"}}> 
         
         <div className="justify-content-center">

              <div className="col-sm mx auto">
             
                   <div className="footer-info">
                        <div className="section-title">
                             <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Our Office</h2>
                        </div>
                        <address className="wow fadeInUp" data-wow-delay="0.4s">
                             <p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black", textAlign:"center"}}>Plot 124, Road No 6, Balaji Nagar,<br /> Nizampet, Hyderabad, 500090</p>
                        </address>
                   </div>
                  
              </div>
              <div className="col-sm mx aut">
              
                   <div className="footer-info">
                        <div className="section-title">
                             <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Contact Us</h2>
                        </div>
                        <address className="wow fadeInUp" data-wow-delay="0.4s" style={{textAlign:"center"}}>
                        <p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}><i className="fa fa-phone">   </i>  +91 - 97890 99422</p>
                             < p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}><i className="fa fa-envelope">  </i>  admin@idntica.com </p>
                             <p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}><i className="fa fa-globe"><a style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}} href="idntica.com">    www.idntica.com</a> </i></p>
                        </address>
                   
                   </div>
              </div>
              

            
              <div className="col-sm mx aut">
              <div className="footer-info">
                        <div className="section-title">
                              <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Copyright</h2>
                    
                        </div>

                   <div className="wow fadeInUp copyright-text" data-wow-delay="0.8s"> 
                   
                  <Link  to="/policies" ><p style={{fontFamily:"Oswald", fontSize:"1.3rem", color:"black", textAlign:"center"}} className="footer-terms">Our Policies </p></Link>
              
         
            <strong> <p style={{fontFamily:"Sansita Swashed", fontSize:"1.0rem", color:"black", textAlign:"center"}} > Copyright &copy; 2020 idntica.com </p> </strong>
                   
                   </div>
                   </div>
              
              </div>
              
         </div>
    </div>

     </footer>
     
     </div></FooterWrapper>
        )
    }
}

const FooterWrapper = styled.nav`
background: #1362b3;
padding: 20px;
padding-bottom: 60px;

    


 
    
    `