import React, { Component } from 'react';
//import { NavHashLink as Link } from 'react-router-hash-link';
//import styled from 'styled-components';
import { Link } from "react-router-dom";

import webhosting from '../images/web.png';
import webDev from '../images/webdev.gif';
import emailHost from '../images/email.jpg';
export default class OurServices extends Component {
    render() {
        return (
            <div className="services-bn" style={{background:"#fff", padding:"10px"}}>
                

                 
            <services id="" data-stellar-background-ratio="0.5" >
   <div > 
  {/*Web Hosting Full View*/}
  <div className="row navbar-bg card-service">

<div className="col-md-6 col-sm-6 col-xs-12" style={{display:"table",  lineHeight:"normal"}}>
     <div className="services-info" >
         {/* <div className="section-title">
               <h3 style={{textAlign:"center", fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Email</h3>
          </div>
          */}
          <img src={webhosting}  style={{display:"table-cell", verticalAlign:"middle"}} alt="store"  />
          <p className="wow fadeInUp" data-wow-delay="0.4s">
               {/*<p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}></p>*/}
          </p>
     </div>
</div>
<div className="services-main col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
          <div className="section-title">
               <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Cloud Hosting</h2>
          </div>

          <address className="wow fadeInUp" data-wow-delay="0.4s" style={{textAlign:"justify"}}>
          {/*<p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Guaranteed 99.9% Uptime</p>*/}
          Take the advantage of powerful, latest technology with 100% serverless cloud hosting for your websites. Auto-scalable to meet your business requirements and traffic anytime - anywhere - anydevice!
                          
                     {/*} <p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Superior Speed Performance</p>
               Take full advantage of the speed and power. No technical knowledge needed!
               < p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Powerful Control Panel </p>
Enjoy the great speed and power with a simple control panel optimised for everyone        */}
          </address>
          <Link to="/webhosting"
          className="btn" style={{border: "1px solid #1362b3",display:"block", padding:"12px", cursor:"pointer", margin:"auto", justifyContent:"center"}}
          type="submit"  name="submit">Learn More</Link>
     </div>
</div>   
</div>
{/*Web Hosting Mobile View*/}
<div className="navbar-mobile card-service">

<div className="col-md-6 col-sm-6 col-xs-12" style={{display:"table",  lineHeight:"normal"}}>
     <div className="services-info" >
         {/* <div className="section-title">
               <h3 style={{textAlign:"center", fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Email</h3>
          </div>
          */}
          <img src={webhosting}  style={{display:"table-cell", verticalAlign:"middle"}} alt="store"  />
          <p className="wow fadeInUp" data-wow-delay="0.4s">
               {/*<p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}></p>*/}
          </p>
     </div>
</div>
<div className="services-main col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
          <div className="section-title">
               <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Cloud Hosting</h2>
          </div>

          <address className="wow fadeInUp" data-wow-delay="0.4s" style={{textAlign:"justify"}}>
          {/*<p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Guaranteed 99.9% Uptime</p>*/}
          Take the advantage of powerful, latest technology with 100% serverless cloud hosting for your websites. Auto-scalable to meet your business requirements and traffic anytime - anywhere - anydevice!
                          
                     {/*} <p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Superior Speed Performance</p>
               Take full advantage of the speed and power. No technical knowledge needed!
               < p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Powerful Control Panel </p>
Enjoy the great speed and power with a simple control panel optimised for everyone        */}
          </address>
          <Link to="/webhosting"
          className="btn" style={{border: "1px solid #1362b3",display:"block", padding:"12px", cursor:"pointer", margin:"auto", justifyContent:"center"}}
          type="submit"  name="submit">Learn More</Link>
     </div>
</div>   
</div>

  {/*Domain Full View*/}

       <div className="row navbar-bg card-service">


<div className="services-main   col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
          <div className="section-title">
               <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Web Development</h2>
          </div>
 
          <address className="wow fadeInUp" data-wow-delay="0.4s" style={{textAlign:"justify"}}>
         
          Meet the team of expert developers, who can create a responsive, customized, fast and secured websites to meet your business needs. Faster Go To Market is today's business Mantra and our development team has nailed it over the years.
                    
          </address>
          <Link to="/webdevelopemnt"
className="btn" style={{border: "1px solid #1362b3",display:"block", padding:"12px", cursor:"pointer", margin:"auto", justifyContent:"center"}}
id="cf-submit"  name="submit">Learn More</Link>
     </div>
</div>

<div className="services-main col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
      
          <img src={webDev}  alt="store" />
          <p className="wow fadeInUp" data-wow-delay="0.4s">
             
          </p>
     </div>
</div>



</div>
{/*Domain Mobile View*/}
<div className="navbar-mobile card-service">


<div className="services col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
       <img src={webDev}  alt="store" />
          <p className="wow fadeInUp" data-wow-delay="0.4s">
           
          </p>
     </div>
</div>

<div className="services-main   col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
          <div className="section-title">
               <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Web Development</h2>
          </div>
 
          <address className="wow fadeInUp" data-wow-delay="0.4s" style={{textAlign:"justify"}}>
       
          Meet the team of expert developers, who can create a responsive, customized, fast and secured websites to meet your business needs. Faster Go To Market is today's business Mantra and our development team has nailed it over the years.
                 
          </address>
          <Link to="/webdevelopemnt"
className="btn" style={{border: "1px solid #1362b3",display:"block", padding:"12px", cursor:"pointer", margin:"auto", justifyContent:"center"}}
id="cf-submit"  name="submit">Learn More</Link>

     </div>
</div>
     </div>


  {/*E-Mail Full View*/}
  <div className="row navbar-bg card-service">

<div className="col-md-6 col-sm-6 col-xs-12" style={{display:"table",  lineHeight:"normal"}}>
     <div className="services-info" >
         {/* <div className="section-title">
               <h3 style={{textAlign:"center", fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Email</h3>
          </div>
          */}
          <img src={emailHost} style={{display:"table-cell", verticalAlign:"middle"}} alt="store"  />
          <p className="wow fadeInUp" data-wow-delay="0.4s">
               {/*<p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}></p>*/}
          </p>
     </div>
</div>
<div className="services-main col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
          <div className="section-title">
               <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Email</h2>
          </div>

          <address className="wow fadeInUp" data-wow-delay="0.4s" style={{textAlign:"justify"}}>
          {/*<p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Guaranteed 99.9% Uptime</p>*/}
          Stay Connected with your clients/partners/stake holders using your personalized, professional email address which suits all your business needs. Earn your customer's trust with a professional email address that matches your business name.              {/*} <p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Superior Speed Performance</p>
               Take full advantage of the speed and power. No technical knowledge needed!
               < p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Powerful Control Panel </p>
Enjoy the great speed and power with a simple control panel optimised for everyone        */}
          </address>
          <Link to="/email-services"
className="btn" style={{border: "1px solid #1362b3",display:"block", padding:"12px", cursor:"pointer", margin:"auto", justifyContent:"center"}} id="cf-submit"  name="submit">Learn More</Link>

     </div>
</div>   
</div>

  {/*E-Mail Mobile View*/}
<div className="navbar-mobile card-service">

<div className="col-md-6 col-sm-6 col-xs-12" style={{display:"table",  lineHeight:"normal"}}>
     <div className="services-info" >
         {/* <div className="section-title">
               <h3 style={{textAlign:"center", fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Email</h3>
          </div>
          */}
          <img src={emailHost} style={{display:"table-cell", verticalAlign:"middle"}} alt="store"  />
          <p className="wow fadeInUp" data-wow-delay="0.4s">
               {/*<p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}></p>*/}
          </p>
     </div>
</div>
<div className="services-main col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
          <div className="section-title">
               <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Email</h2>
          </div>

          <address className="wow fadeInUp" data-wow-delay="0.4s" style={{textAlign:"justify"}}>
          {/*<p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Guaranteed 99.9% Uptime</p>*/}
          Stay Connected with your clients/partners/stake holders using your personalized, professional email address which suits all your business needs. Earn your customers' trust with a professional email address that matches your business name.              {/*} <p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Superior Speed Performance</p>
               Take full advantage of the speed and power. No technical knowledge needed!
               < p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Powerful Control Panel </p>
Enjoy the great speed and power with a simple control panel optimised for everyone        */}
          </address>
          <Link to="/email-services"
className="btn" style={{border: "1px solid #1362b3",display:"block", padding:"12px", cursor:"pointer", margin:"auto", justifyContent:"center"}} id="cf-submit"  name="submit">Learn More</Link>

     </div>
</div>   
</div>


   </div>
</services>

</div>
        )
    }
}
