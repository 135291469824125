import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
//import { Auth } from "aws-amplify";

//import MyAccounts from './MyAccounts';
import { ProductContext } from '../../ProductProvider';
//import Bottombar from '../bottombar/Bottombar';
//import PreCheckOut from './PreCheckOut';
//import MyProfile from './MyProfile';
//import MyTransaction from './MyTransaction';
//import ImageUploader from '../manageimage/ImageUploader';
import Sidebar from '../sidebar/Sidebar';
import QuickView from './QuickView';
import {Helmet} from 'react-helmet-async';

function MyBoard() {
    let history = useHistory();
    const { loggedUser } = useContext(ProductContext);
    /*const [showAccounts, setShowAccounts] = useState(false);
    const [showProfile, setShowProfile] = useState(true);
    const [showCheckout, setShowCheckout] = useState(false);
    const [showTransaction, setShowTransaction] = useState(false);
    const [showManage, setShowManage] = useState(false);
    const [showMyProfile, setShowMyProfile] = useState(false);
    const [showManageUser, setShowManageUser] = useState(false);*/

    const handleReRoute=()=>{
        history.push("/signin");
    }

    /*const handleShow = (value, event) => {
        event.preventDefault();
        console.log("Value received : ", value);
        setShowAccounts(false);
        setShowProfile(false);
        setShowCheckout(false);
        setShowTransaction(false);
        setShowManage(false);
        setShowMyProfile(false);
        setShowManageUser(false);

        if (value === "Accounts") {
            setShowAccounts(true);
        } else if (value === "Profile") {
            setShowProfile(true);
        } else if (value === "Checkout") {
            setShowCheckout(true);
        } else if (value === "Transaction") {
            setShowTransaction(true);
        } else if (value === "Manage") {
            setShowManage(true);
        } else if (value === "MyProfile") {
            setShowMyProfile(true);
        } else if (value === "ManageUser") {
            setShowManageUser(true);
        }
    }

    const handleSignout = (event) => {
        event.preventDefault();
        try {
            Auth.signOut().then((data) => {
                setLoggedUser({
                    ...loggedUser,
                    username: '',
                    email: '',
                    phoneno: '',
                    familyName: '',
                    startDate: '',
                    name: '',
                    isAuthenticated: false
                });
                history.push("/signin");
            })
        } catch (error) {
            console.log(error.message);
        }
    }*/

    return (
    <>
    <Helmet>
      <title>Dashboard - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="Dashboard - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="Dashboard - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

        <div className="dash-board">
            {loggedUser.isAuthenticated ?
            <div>
        
        <div className="navbar-bg" > 
        <Sidebar />
        </div>
        <div className="navbar-mobile"> 
        <QuickView />
        </div>
        
           
              {/*  <div className="row " style={{ margin: '0px' }}> 
               
                    <div className="col-3 col-lg-3 con-test">
                        <br />
                        <div className="row " style={{ position: 'flex', justifyContent: 'center' }}>

                            <h4 className="text-title" style={{ color: 'white' }}> <i className="fa fa-user fa-fw" aria-hidden="true"></i>&nbsp; {loggedUser.profile} Dashboard </h4>

                        </div>
                        <br />
                        <div className="list-group">
                            {loggedUser.isAuthenticated ?

                            <Link to="/#" onClick={(event)=>handleShow("Profile", event)} className="list-group-item" ><i className="fa fa-user fa-fw" aria-hidden="true"></i>&nbsp; My Profile</Link>

                            : ""}

                            {loggedUser.isAuthenticated ?

                                <Link to="/#" onClick={(event)=>handleShow("Accounts", event)} className="list-group-item" ><i className="fa fa-user fa-fw" aria-hidden="true"></i>&nbsp; My Accounts</Link>

                                : ""}

                            {loggedUser.isAuthenticated ?

                            <Link to="/#" onClick={(event)=>handleShow("Transaction", event)} className="list-group-item" ><i className="fa fa-user fa-fw" aria-hidden="true"></i>&nbsp; My Transaction</Link>

                            : ""}


                            {loggedUser.isAuthenticated ?

                                <Link to="/#" onClick={(event) => handleSignout(event)} className="list-group-item" ><i className="fa fa-sign-out fa-fw" aria-hidden="true"></i>&nbsp; Sign Out</Link>
                                : ""}

                        </div>
                            </div>*/}


                 {/*   <div className="col-12 col-sm-9 col-lg-9" style={{ marginTop: '50px' }}>
                        {showAccounts ?
                            <div className="row">
                                <MyAccounts handleShow={handleShow}/>
                            </div> : <div></div>}
                        {showCheckout ?
                            <div className="row">
                                <PreCheckOut />
                            </div> : <div></div>}
                        {showProfile ?
                            <div className="row">
                                <MyProfile />
                            </div> : <div></div>}
                        {showTransaction ?
                            <div className="row">
                                <MyTransaction />
                            </div> : <div></div>}
                        {showManage ?
                            <div className="row">
                                <ImageUploader />
                            </div> : <div></div>}

                        {showMyProfile ?
                            <div className="row">

                            </div> : <div></div>}

                        {showManageUser ?
                            <div className="row">

                            </div> : <div></div>}



                        </div>*/}
                        
                        </div>  
                : <div>{handleReRoute()}</div>}
        </div>
    </>
    )

}
export default MyBoard;
