import React, { useContext } from 'react';
//import styled, { keyframes } from 'styled-components';
//import Title from '../Title';
//import EmptyCart from './EmptyCart';
import { ProductContext } from '../../ProductProvider';
import CartList from './CartList';
import CartTotals from './CartTotal';
import empty from '../../images/emptycart.jpg'
//import { bounce } from 'react-animations';
//import { faEdit } from '@fortawesome/free-solid-svg-icons';
//import "font-awesome";
import {Helmet} from 'react-helmet-async';

 
//const bounceAnimation = keyframes`${bounce}`;
export default function Cart() {
    const { cart } = useContext(ProductContext);

    
    return (
    <>
    <Helmet>
      <title>My Cart - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="My Cart - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="My Cart - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

        
            <div className="main-signin" style={{paddingTop:"50px"}}>
            <div className="container-fluid mt-5">
           
        

                <div className="scroll-div" id="style-1">
                    <div className="row">
               
                        <div className="col-12 col-md-12 col-sm-12" >
                    {cart.length>0?
                                <React.Fragment>
                                    <div className="cart-title-dis">
                                
                                  {/*}  <Title name="your" title="cart" />
                                   <p>  Delivery Type: {deliveryType} <i className="fa fa-pencil-square-o" aria-hidden="true" onClick={this.handleUpdate}></i></p>*/}

                                    <div className="cart-value-div" id="style-1">
                                    
                                    <CartList/>
                                    </div>
                                    
                                    <CartTotals/>
                                    </div>
                                </React.Fragment>:<div className="container">
                                    <img className="img-fluid rounded mx-auto d-block" style={{display:"flex"}} src={empty} alt="empty-cart" />
                                    <h2 className="text-title"> Your Cart is Empty</h2>
                                    
                                    </div>}

                    
                </div>
                </div>
                </div>
          
            
           </div>
        </div>
    </>
    )
    
}

/*const CartWrapper = styled.div`

.scroll-div{
    
    width: 100% !important;
    height: 520px !important;
    overflow-y: scroll !important;
    
}
.cart-value-div{
    
    width: 100% !important;
    height: 310px !important;
    overflow-x: scroll !important;
    border-bottom: 2px solid var(--mainMustard);
}

#style-1::-webkit-scrollbar
{
	width: 8px;
	background-color: black;
}

#style-1::-webkit-scrollbar-thumb
{
	border-radius: 12px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--mainMustard);
}
`;*/