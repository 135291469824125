import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import { withRouter, Link, useHistory } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, UncontrolledDropdown,DropdownToggle,DropdownItem,DropdownMenu  } from "reactstrap";
import { Auth } from "aws-amplify";
import logo2 from '../images/idntica.png';
import Preloader from './preloader/Preloader';
//import logo1 from '../images/idnticaicon.png';
import '../css/Styles.css';
import { ProductContext } from '../ProductProvider';
//import HomeHeader from './HomeHeader';
import carticon from '../images/cart-icon.png'


function IdnNavbar() {
    let history = useHistory();

    const { loggedUser, setLoggedUser, cart , clearCart, setAccountsFetched} = useContext(ProductContext);
    //const [open, setOpen] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    const [loaded, setLoaded] = useState(true);


    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

   /* const closeNavbar = () => {
        if (collapsed) {
            toggleNavbar();
        }
    }*/

    const handleLogOut = async(event)=>{
        event.preventDefault();
        try{
            setLoaded(false);
            Auth.signOut().then(() => {
                setLoggedUser({...loggedUser,
                    username: '',
                    isAuthenticated: false,
                    email:'',
                    phoneno:'',
                    address:'',
                    startDate:'',
                    clientID:'',
                    profile:'',
                    orgName:''
                });
                setAccountsFetched(false);
                clearCart();
                setLoaded(true);
                history.push("/");
              })        
            }catch(error){
                setLoaded(true);
            //console.log("error ", error);
        }
    }


    return (
        <div className="navbar-fin">
            {!loaded?<Preloader />:null}
            <HeaderWrapper>
           <div className="navbar-bg">
                <NavWrapper className="navbar navbar-expand-sm navbar-light" >
                    <div className="nav-logo" style={{paddingLeft:"15px"}}>
                        <Link to='/'>
                            <img src={logo2} style={{ width: "8rem", height: "4rem" }} alt="store" className="navbar-brand-ak" />
                        </Link></div>
                   {/* <ul className="navbar-nav align-items-center" >
                        <li className="nav-item">
                            <Link to='/' className="nav-link-title">
                                <center><p style={{fontSize:"20px", color:"#1362b3", padding:"0px", marginTop:"20px", fontFamily:"Oswald"}}>i d n t i c a . c o m</p></center>
                            </Link>
                        </li>
    </ul> */}
                    <header className="top-navbar">
                        <nav className="navbar1 navbar-expand-lg navbar-light">
                            <ul className="navbar-nav ml-auto">
                                
                              
                            <NavItem>
                              
                              <Link className="nav-link navigation__navlinks"
                                  onClick={toggleNavbar}
                                  to="/webhosting">
                                  Hosting
                              </Link>
      
                        </NavItem>
                        <NavItem>
                                <Link
                                    onClick={toggleNavbar}
                                    className="nav-link navigation__navlinks" to="/webdevelopemnt">
                                    Development
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link
                                    onClick={toggleNavbar}
                                    className="nav-link navigation__navlinks" to="/email-services">
                                    Email
                                </Link>
                            </NavItem>
                           
                            {loggedUser.isAuthenticated&&(
                            <UncontrolledDropdown
                                inNavbar
                                nav
                                >
                                <DropdownToggle
                                    caret
                                    nav
                                >
                                Hi {loggedUser.username}!
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem>
                                    <Link to="/dashboard" className="nav-link">My Dashboard</Link>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                    <Link to="#" onClick={(event)=>{handleLogOut(event)}} className="nav-link">Sign Out</Link>
                                    </DropdownItem>
                                </DropdownMenu>
                                </UncontrolledDropdown>)}

                            <NavItem>
                                {!loggedUser.isAuthenticated && (
                                    <Link className="nav-link navigation__navlinks"
                                        onClick={toggleNavbar}
                                        to="/signin">
                                        Sign In
                                    </Link>

                                )}

                             
                            </NavItem>
                            <NavItem>
                                {!loggedUser.isAuthenticated && (
                                    <Link className="nav-link navigation__navlinks"
                                        onClick={toggleNavbar}
                                        to="/signup">
                                        Sign Up
                                    </Link>

                                )}

                             
                            </NavItem>

                            <NavItem>
                                    <Link className="nav-link navigation__navlinks"
                                        onClick={toggleNavbar}
                                        to="/cart">
                                          <span style={{padding:"15px", marginRight:"-45px"}}>{cart.length}</span> 
                                         <img className="img-cart" src={carticon} alt="cart-icon" style={{paddingTop:"7px"}}/>
                                            Cart
                                    </Link>                             
                            </NavItem>
                            
                              
                               </ul>
                        </nav></header>
                </NavWrapper>
            </div>
                         
            <div className="navbar-mobile " style={{paddingRight:"18px"}}>  
           <div className="d-flex">
                <Navbar className="navigation__navbar justify-content-start" style={{width:"35%"}} light expand="md">
                
                
                   
                    <NavbarToggler onClick={toggleNavbar} />
                    <Collapse isOpen={!collapsed} navbar>
                        <Nav className="ml-auto" navbar>
                           
                            <NavItem>
                              
                              <Link className="list-group-item" style={{color:"#1362b3"}}
                                  onClick={toggleNavbar}
                                  to="/webhosting">
                                  Hosting
                              </Link>
      
                        </NavItem>
                        <NavItem>
                                <Link
                                    onClick={toggleNavbar}
                                    className="list-group-item" style={{color:"#1362b3"}} 
                                    to="/webdevelopemnt">
                                    Development
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link
                                    onClick={toggleNavbar}
                                    className="list-group-item" style={{color:"#1362b3"}}                                    
                                    to="/email-services">
                                    Email
                                </Link>
                            </NavItem>
                            

                             {/*loggedUser.isAuthenticated&&(
                            <UncontrolledDropdown 
                              
                                nav
                                >
                              <DropdownToggle
                                     style={{padding:"0px"}}
                                
                                    nav
                                >
                               <Link  className="list-group-item" style={{color:"#1362b3", fontSize:"12px"}}   > Hi {loggedUser.username}! <i className="fa fa-caret-down" ></i>
                               </Link>
                                <DropdownMenu   onClick={toggleNavbar}>
                                    <DropdownItem className="list-group-item" style={{color:"#1362b3", fontSize:"12px"}} >
                                    <Link to="/dashboard" >My Dashboard</Link>
                                    </DropdownItem>
                                    
                                   
                                    <DropdownItem className="list-group-item" style={{color:"#1362b3", fontSize:"12px"}} >
                                    <Link to="#" onClick={(event)=>{handleLogOut(event)}} >Sign Out</Link>
                                    </DropdownItem>
                                </DropdownMenu> </DropdownToggle>
                                </UncontrolledDropdown>)} 
                            <NavItem>
                                {!loggedUser.isAuthenticated && (
                                    <Link className="list-group-item" style={{color:"#1362b3"}} 
                                        onClick={toggleNavbar}
                                        to="/signin">
                                        Sign In
                                    </Link>

                                )}

                              
                            </NavItem>
                            <NavItem>
                            {!loggedUser.isAuthenticated && (
                                <Link className="list-group-item" style={{color:"#1362b3"}} 
                                    onClick={toggleNavbar}
                                    to="/signup">
                                    Sign Up
                                </Link>
                            )}
                            </NavItem>*/}
                            
                        </Nav>
                    </Collapse>
                   

             
                </Navbar>

               
                <div className="justify-content-center brand_name " style={{width:"35%"}}>
                    <Link to='/' >
                           
                        <center>
                        <img src={logo2} style={{width:"120px"}}
                             alt="Idntica Logo" />
                        {/*
                            <p style={{fontSize:"30px", align:"center", color:"#1362b3", 
                            padding:"10px", marginTop:"0px", fontFamily:"Oswald"}}>   
                            <img src={logo1} style={{ width: "3rem", height: "3rem", paddingBottom:"10px" }}
                             alt="Idntica Logo" />www.idntica.com</p>
                      */}
                            </center>                        </Link>

                    </div>
                    <div className="justify-content-end" style={{width:"35%", textAlign:"right"}}>
                    <Link 
                                       
                                        to="/cart">
                                         <span style={{padding:"10px", marginRight:"-28px"}}>
                                             <img className="img-cart" src={carticon} alt="cart-icon" style={{paddingTop:"14px"}}/></span> 
                                         
                                        <span style={{marginLeft:"-14px"}}> {cart.length} </span>
                                    </Link> 
                        </div>
            </div>
        
       
              </div>                  

</HeaderWrapper>

           
      </div>
    );
}
export default withRouter(IdnNavbar);

const NavWrapper = styled.nav`
.nav-link {
  color: #0000FF !important;
  font-size: 2rem;
  text-transform: capitalize;
    
}
 
.top-navbar {
    position: absolute;
    background-color: none;
    top: 15px;
    right: 10px;
  }
  
  .top-navbar .navbar-expand-lg .navbar-nav {
    
  }
  
  .top-navbar .navbar-light .navbar-nav .nav-item {
    position: relative;
  }
  .top-navbar .navbar-light .navbar-nav .nav-link {
    padding: 5px 9px;
    color: #f0f3f5;
    position: relative;
  }
  
  .top-navbar .navbar-light .navbar-nav .nav-item::before {
    position: absolute;
  
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 0%;
    display: block;
    opacity: 0;
    border-radius: 5px;
    
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
  }
  
  .top-navbar .navbar-light .navbar-nav .nav-item.active::before {
    opacity: 1;
    height: 100%;
  }
  
  .top-navbar .navbar-light .navbar-nav .nav-item:hover::before {
    opacity: 1;
    height: 100%;
  }
     
    
    `
    const HeaderWrapper = styled.nav`
position: fixed;
top: 0;
background: #fff !important;
width:100%;
height: 60px;
z-index: 9999;
.navbar{
    padding:0px !important;
}
.navbar-main{
    position: fixed;
    right: 0;
    
    
}
.nav-link {
    
  color: #1362b3 !important;
  font-size: 1.0rem;
  text-transform: capitalize;
  padding: 10px 0px 10px 0px;
  border-radius: 8px;
  font-family: "Oswald";
background: none;
}
.nav-link:focus {
	
    border-bottom: 5px solid #1362b3;
	border-radius: 0px;
}
.nav-link:hover{
	background-color: #1362b3;
	color: white !important;
}
 .headerdiv{
	 padding: 0px 10px 0px 10px;
 }
 .img-cart{
     width:50px;
     margin-top: 0px;
     padding-right: 10px;
 }

 @media only screen and (max-width: 1950px) {
    .navbar-main{  
        right: 20px;

    }
 }
 @media only screen and (min-width: 580px) {
.navbar{
    justify-content: none;
}
 }
    `