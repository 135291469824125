import React, { useState, useContext, useEffect } from 'react';
import { Auth } from "aws-amplify";
import { Link, useHistory } from 'react-router-dom';
import signInImg from '../../images/signin-image.jpg';
import { ProductContext } from '../../ProductProvider';
import {Alert, Button } from "reactstrap";
//import useFullPageLoader from "../hooks/useFullPageLoader";
import Preloader from '../preloader/Preloader';
import {Helmet} from 'react-helmet-async';

export default function SignInMFA() {
    let history = useHistory();
    const { loggedUser, setLoggedUser, alertClose, alertDetails, setAlertDetails } = useContext(ProductContext)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showNewPass, setShowNewPass] = useState(false);
    const [newPassword, setNewPassword]=useState('');
    const [user, setUser] = useState("");
    //const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [loaded, setLoaded] = useState(true);


    useEffect(() => {
      alertClose();
      window.scrollTo(0,0);
    }, [])

    const handleChangePassword =async(event)=>{
      event.preventDefault();
      try{
        setLoaded(false);
        await Auth.completeNewPassword(
          user,        
          newPassword
      ).then(localeuser => {
          // at this time the user is logged in if no MFA required
         // console.log(localeuser);
         setLoggedUser({...loggedUser,
          username: localeuser.challengeParam.userAttributes['name'],
          isAuthenticated: true,
          email:localeuser.challengeParam.userAttributes['email'],
          phoneno:localeuser.challengeParam.userAttributes['phone_number'],
          startDate:localeuser.challengeParam.userAttributes['birthdate'],
          clientID:localeuser.challengeParam.userAttributes['family_name'],
          profile:localeuser.challengeParam.userAttributes['profile'],
          orgName:localeuser.challengeParam.userAttributes['given_name'],
          gstNo:localeuser.challengeParam.userAttributes['gender']
         });
         setLoaded(true);
        history.push("/dashboard");
      }).catch(e => {
        setLoaded(true);
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + e.message
        }); 
      });
    
      }catch(error){
        setLoaded(true);
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
        }); 
      }
    }
    
    const handleSignIn = async(event) =>{
        event.preventDefault();
        try{
          if(email==="" || password===""){
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Enter both Email and password to login!"
          }); 
          }else{
            //showLoader();
            setLoaded(false);
            const username = email;
            await Auth.signIn(username, password)
            .then((user)=>{
              if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setUser(user);
                setShowNewPass(true);
                setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message:"Set up your New password!"
              }); 
              }else{  
              setLoggedUser({...loggedUser,
                username: user.attributes.name,
                isAuthenticated: true,
                email:user.attributes.email,
                phoneno:user.attributes.phone_number,
                startDate:user.attributes.birthdate,
                clientID:user.attributes.family_name,
                profile:user.attributes.profile,
                orgName:user.attributes.given_name,
                gstNo:user.attributes.gender
               });
               //hideLoader();
               setLoaded(true);
              history.push("/dashboard");
              }
          })
          .catch((error) =>{
            //hideLoader();
            setLoaded(true);
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
        })
        }
        }catch(error){
          //hideLoader();
          setLoaded(true);
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        }
    }

return (
  <>
    <Helmet>
      <title>Sign In - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="Sign In - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="Sign In - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

      {!loaded?<Preloader />:null}
      <div className="login" style={{paddingTop:"100px"}}>
      {/*<div style={{textAlign:"center"}}> {loader} </div>*/}
        <section  className="sign-in">
            <div className="container">
                <div className="signin-content">
                    <div className="signin-image">
                        <figure><img src={signInImg} alt="sign up"/></figure>
                        <Link to="/signup" className="signup-image-link">Create an account</Link>
                    </div>
                    <div className="signin-form">
                    <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
                    {alertDetails.message}
                    </Alert>
                        <h2 style={{ fontFamily: "Oswald"}} className="form-title">Sign In</h2>
                      <form className="register-form" id="login-form">
                        <div  style={{ fontFamily: "Oswald"}} className="form-group"> 
                                
                                <div className="form-group">
                                
                                <label id="email"><i className="fa fa-envelope"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="email" name="email" id="email" placeholder="Your Email"
                                value={email} disabled={showNewPass}
                                onChange={(event)=>{setEmail(event.target.value)}}/>
                            </div>
                            
                            <div className="form-group">
                                <label id="pass"><i className="fa fa-key"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="password" name="pass" id="pass" placeholder="Password"
                                value={password} disabled={showNewPass}
                                onChange={(event)=>{setPassword(event.target.value)}}/>
                            </div> {showNewPass?
                                <div className="form-group">
                                <label id="pass"><i className="fa fa-key"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="password" name="pass" id="pass" placeholder="Enter New Password"
                                value={newPassword}
                                onChange={(event)=>{setNewPassword(event.target.value)}}/>
                            </div>:null}

                            <Link to='/forgot-password'><p>Forgot Password ? </p>   </Link>
                                   </div>
                           
                      {!showNewPass?<Button variant="primary" type="submit" className="w-100" onClick={(event)=>{handleSignIn(event)}}>
                        Sign in </Button>:
                      <Button variant="primary" type="submit" className="w-100" onClick={(event)=>{handleChangePassword(event)}}>
                        Change Password</Button>}
                            
                          {/*}  <button style={{ fontFamily: "Oswald", border:"1px solid blue"}} className="btn" onClick={(event)=>{handleSignIn(event)}}>Sign In</button> */}
                        
                        </form>
                      
                    </div>
                </div>
            </div>
        </section>


            </div>
            </>
    )

}