import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { Auth } from "aws-amplify";
import {Col,Row } from 'reactstrap';
//import snap from '../../images/bg.gif'
import useFullPageLoader from "../hooks/useFullPageLoader";
import { ProductContext } from '../../ProductProvider';
import { Link, useHistory } from 'react-router-dom';

//import MyAccounts from './MyAccounts';
//import {temp_client_accounts,tempClients} from '../../packageDetails';
const config = require('../../config.json');

export default function QuickView(props) {
    let history= useHistory();
    const { loggedUser, accountsFetched, setAccountsFetched, setAllClientAccounts, webHostAccounts, setWebHostAccoAunts, emailAccounts, setEmailAccounts, webDevelopAccounts, setWebDevelopAccounts } = useContext(ProductContext);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [dispMsg, setDispMsg] = useState('');
    //const [myAccounts, setMyAccounts] = useState('');

    useEffect(() => {
    //    setBottomValue('MyTransaction');
        if (!accountsFetched){
            fetchUserAccounts();
        }
        window.scrollTo(0,0);
        
       //assignTempValues();

    }, []);

    const handleShow = (value,event) => {
        event.preventDefault();
        if(loggedUser.isAuthenticated){
            if (value==="EmailAcc") {
                    history.push("/my-email-services");
                } else if (value==="WebAccounts") {
                    history.push('/my-web-hosting');
                } else if (value==="WebDev"){
                    history.push('/my-web-developemnt')
                }
            }else{
                history.push("/signin");
            }
    }

    /*const assignTempValues = async() =>{
        const tempHost = temp_client_accounts.filter(plan => plan.packageId.includes('IDN-WH'));
        const tempDevelop = temp_client_accounts.filter(plan => plan.packageId.includes('IDN-WD'));
        const tempEmail = temp_client_accounts.filter(plan => plan.packageId.includes('IDN-EH'));
        setAllClientAccounts(temp_client_accounts);
        setWebDevelopAccounts(tempDevelop);
        setWebHostAccoAunts(tempHost);
        setEmailAccounts(tempEmail);
    }*/

    const handleReRoute=()=>{
        history.push("/signin");
    }

    const fetchUserAccounts = async () => {
        setDispMsg("");
        //setMyAccounts("");
        setAllClientAccounts("");
        setWebDevelopAccounts("");
        setWebHostAccoAunts("");
        setEmailAccounts("");
        showLoader();
        try {
            const session = await Auth.currentSession();
            const access_token = session.idToken.jwtToken;
            const clientid = loggedUser.clientID;
            //console.log("Client ID : ",clientid);
            //const clientid=tempClientid.substring(1, tempClientid.length);
            await axios.get(`${config.api.invokeUrl}/client/accounts/${clientid}`, {
                headers: {
                    Authorization: access_token,
                    'x-api-key': config.api.key
                }
            })
                .then((response) => {
                    //console.log("response: ", response);
                    if (response.data.length>0){
                        setAllClientAccounts(response.data);
                        //setSelectedAccounts(response.data);
                        const tempHost = response.data.filter(plan => plan.packageId.includes('IDN-WH'));
                        const tempDevelop = response.data.filter(plan => plan.packageId.includes('IDN-WD'));
                        const tempEmail = response.data.filter(plan => plan.packageId.includes('IDN-EH'));
                        setWebDevelopAccounts(tempDevelop);
                        setWebHostAccoAunts(tempHost);
                        setEmailAccounts(tempEmail);
                        
                    }
                    setAccountsFetched(true)
                })
                .catch((error) => {
                    setDispMsg(`Error Occured : ${error.message}`);
                })

        } catch (error) {
            setDispMsg(`Error Occured : ${error.message}`);
        }
        hideLoader();
    }

    return (
        <div className="container" style={{background:"none"}}>
            {loggedUser.isAuthenticated ? <div>
            
            <div style={{textAlign:"center"}}> {loader} </div>
                <Row className="navbar-bg">
                  
                    <Col sm={4} >
                    <Link to="#" onClick={(event)=>handleShow("WebAccounts",event)}><div className="card " style={{borderRadius:"40px"}}>
                   <img style={{borderTopLeftRadius:"40px", borderTopRightRadius:"40px"}} className="dash-img" src="https://cdn.dribbble.com/users/1124806/screenshots/4871258/ezgif.com-optimize.gif" alt="Avatar" />
                   
                    <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px"}}><b>Web Hosting</b></h5>
                    
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3", 
                        background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {webHostAccounts.length} 
                        </h1></div> 
                     </div></Link>
                   </Col>
                    <Col sm={4}>
                    <Link to="#" onClick={(event)=>handleShow("WebDev", event)}><div className="card" style={{borderRadius:"40px"}}>
                   <img style={{borderTopLeftRadius:"40px", borderTopRightRadius:"40px"}} className="dash-img" src="https://cdn.dribbble.com/users/1233499/screenshots/3850691/web-development.gif" alt="Avatar" />
                   
                    <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px"}}><b>Web Developments</b></h5>
                    
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3", 
                        background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {webDevelopAccounts.length} 
                        </h1></div> 
                     </div></Link>
                  </Col>
                    <Col sm={4}>
                    <Link to="#" onClick={(event)=>handleShow("EmailAcc", event)}><div className="card" style={{borderRadius:"40px"}}>
                   <img style={{borderTopLeftRadius:"40px", borderTopRightRadius:"40px"}} className="dash-img" src="https://i.pinimg.com/originals/80/b4/c8/80b4c8eca5291255732a9d4e3eeb8826.gif" alt="Avatar" />
                   
                    <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px"}}><b>Email Accounts </b></h5>
                    
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3", background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {emailAccounts.length} 
                        </h1></div> 
                     </div></Link>
                    </Col>
                </Row>
                <Row className="navbar-mobile" style={{paddingTop:"50px"}}>
                  
                 
                  <Link to="#" onClick={(event)=>handleShow("WebAccounts", event)} style={{paddingBottom:"8px"}}><div className="card " style={{borderRadius:"40px"}}>
                 <img style={{borderTopLeftRadius:"40px", borderTopRightRadius:"40px"}} className="dash-img" src="https://cdn.dribbble.com/users/1124806/screenshots/4871258/ezgif.com-optimize.gif" alt="Avatar" />
                 
                  <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px"}}><b>Web Hosting</b></h5>
                  
                  <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                   display:"table",  alignItems:"center"}}>
                      <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3", 
                      background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {webHostAccounts.length} 
                      </h1></div> 
                   </div></Link>
               
                  <Link to="#" onClick={(event)=>handleShow("WebDev", event)} style={{paddingBottom:"8px"}}><div className="card" style={{borderRadius:"40px"}}>
                 <img style={{borderTopLeftRadius:"40px", borderTopRightRadius:"40px"}} className="dash-img" src="https://cdn.dribbble.com/users/1233499/screenshots/3850691/web-development.gif" alt="Avatar" />
                 
                  <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px"}}><b>Web Developments</b></h5>
                  
                  <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                   display:"table",  alignItems:"center"}}>
                      <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3", 
                      background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {webDevelopAccounts.length} 
                      </h1></div> 
                   </div></Link>
               
                  <Link to="#" onClick={(event)=>handleShow("EmailAcc", event)} style={{paddingBottom:"60px"}}><div className="card" style={{borderRadius:"40px"}}>
                 <img style={{borderTopLeftRadius:"40px", borderTopRightRadius:"40px"}} className="dash-img" src="https://i.pinimg.com/originals/80/b4/c8/80b4c8eca5291255732a9d4e3eeb8826.gif" alt="Avatar" />
                 
                  <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px"}}><b>Email Accounts </b></h5>
                  
                  <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                   display:"table",  alignItems:"center"}}>
                      <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3", background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {emailAccounts.length} 
                      </h1></div> 
                   </div></Link>
                
              </Row>
              {dispMsg}
              </div>:<div>{handleReRoute()}</div>}
          
      
                </div>
    )
}
