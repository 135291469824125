import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProductProvider } from './ProductProvider';
import config from './config';
import Amplify from 'aws-amplify';
import {HelmetProvider} from 'react-helmet-async';

//cognito configure for phone-otp
/*Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    authenticationFlowType: config.cognito.AUTH_FLOW
  }
});*/


Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: config.cognito1.REGION,
    userPoolId: config.cognito1.USER_POOL_ID,
    userPoolWebClientId: config.cognito1.APP_CLIENT_ID
  }
});


ReactDOM.render(
  <ProductProvider>
    <Router>
    <HelmetProvider>
      <App />
      </HelmetProvider>
    </Router>
  </ProductProvider>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
