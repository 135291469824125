import React, { useState, useContext } from "react";
import { ProductContext } from '../../ProductProvider';
import {Col,Row, Container, Table} from 'reactstrap';
import { Auth } from "aws-amplify";
import axios from "axios";
import Preloader from "../preloader/Preloader";
//import {Link} from "react-router-dom";
//import PayPalButton from "../payment/PayPalButton";

//import { useHistory } from "react-router-dom";
//import Login from '../LoginOtp';
//import LoginEmail from '../testing/LoginEmail';
//import CartCheckout from './CartCheckout';
const config = require('../../config.json');

export default function CartTotalCheckOut(props) {
  const {cartSubTotal, cartTax, cartTotal, processDiscount, discountAmount, loggedUser, createSignature, alertDetails, setAlertDetails } = useContext(ProductContext);
  // const history = useHistory();
    // const {loggeduser} = name;
    const{setLoaded}=props;

const [promoCode, setPromoCode] = useState(null);
const [promoButton, setPromoButton] = useState(false);
const [promoMsg, setPromoMsg] = useState(null);

const handlePromo=async(e)=> {
  e.preventDefault();
   // console.log("Promo validation", promoCode);
    setPromoMsg(null);
    if(promoCode==="" || promoCode===null || promoCode==="NONE" || promoCode==="NA")
    {
        //setPromoApplied(0);
        setPromoMsg("Incorrect Promotion Code!");
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Incorrect Promotion Code!"
      });  

        
        setPromoButton(false);
    }else {
       // console.log("Promo Success");
      //  console.log("Discount ", discountNo);
        
        //setPromoApplied(discountNo);
        await validatePromoCode();
        
     //   addTotals();

    }
}

const validatePromoCode=async()=>{
  try {
    setLoaded(false);
    const session = await Auth.currentSession();
    const clientId=session.idToken.payload.family_name;
    //const clientId=username.substring(1, username.length);
    const access_token=session.idToken.jwtToken;
    
    await axios.get(`${config.api.invokeUrl}/transaction/promo_validation?clientId=${clientId}&promoCode=${promoCode}`, {
       headers: {
         Authorization: access_token,
         'x-api-key':config.api.key
       }
     }).then(async(response) => {
      if(response.status===200){
        const newTotal = await processDiscount(response.data);
        setPromoMsg("Congratulations! You got "+response.data+"% Discount");
        setAlertDetails({
          ...alertDetails,
          style:"success",
          open:true,
          message:"Congratulations! You got "+response.data+"% Discount"
      });  

        setPromoButton(true);
        const temp=loggedUser.phoneno.substring(3, loggedUser.phoneno.length);
       // console.log("New Total :", newTotal);      
        await createSignature(temp, newTotal); 
        //console.log("promo validated");
       

      }else{
        setPromoMsg("Incorrect Promotion Code!");
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Incorrect Promotion Code!"
      });  

        setPromoButton(false);

      }
      setLoaded(true);

     }, (error) => {
      setLoaded(true);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });  

     });
    
     } catch (err) {
      setLoaded(true);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + err.message
    });  

//       console.log(`An error has occurred: ${err}`);
   }
}



function handleChange(e){
   // console.log("Input received", e);
    setPromoCode(e.target.value);
    //console.log("Promo Code :", promoCode);

}
    

    return (
      <>
      <Container>
      <Row>
        <Col>
          <Table hover responsive>
               <thead>
                 <tr>
                  
                 
                 </tr>
               </thead> 
               <tbody>
                 <tr>
               <th > Sub Total </th> 
               <td style={{textAlign:"right"}}>
               <i className="fa fa-rupee"></i> {cartSubTotal}
               </td> 
               </tr>
               <tr>

                    
                        <th>    
                              
                        <input 
                                
                                type="text"
                                id="phoneno"
                                aria-describedby="phonenoHelp"
                                placeholder="Enter coupon" 
                                onChange={e=>handleChange(e)}
    
                                />      
                                </th>              
                                
    <td style={{textAlign:"right"}}>
      <button className="btn" disabled={promoButton} onClick={(e)=>handlePromo(e)}>Apply</button>
    </td>
                          
                     
    </tr>
    <tr>
               <th>
                   Discount  
                   </th>
                   <th style={{textAlign:"right"}}>
                   <i className="fa fa-rupee"></i>  {discountAmount}
                   </th>
               </tr>
               <tr style={{textAlign:"right"}}> 
                <p>{promoMsg}</p>
              </tr> 
               <tr>
               <th>
                   GST - 18% 
                   </th>
                   <th style={{textAlign:"right"}}>
                   <i className="fa fa-rupee"></i>  {cartTax}
                   </th>
               </tr>
                 <tr>
                   <th>
                   Total
                     </th>
               <th style={{textAlign:"right"}}>
               <i className="fa fa-rupee"></i> {cartTotal}
               </th>
               </tr>
              
                 </tbody> 
                
                 </Table>
                
         
        
        
{/*
        <React.Fragment>
           
            <div className="cart-tot-dis">
        <div className="container-fluid">
            <div className="row">
           <div className="col-md-6 col-sm-6 ">
           
            <div style={{Top: '30px'}} className = "error-display help is-danger pull-center">
                         {validationMsg}
               </div>
                </div>
                <div className="col-md-12 col-sm-12 ">
                <div className="clear-cart-btn">                    
                    <Link to="/menu">
                        <button className="btn btn-outline-danger text-uppercase mb-3 px-5" type="button" onClick={()=>clearCart()}>
                            clear cart
                        </button>
                    </Link>
                    </div> 
                    <div className="sub-tot-val"> 
                    <h5>
                        
                          subtotal :  <strong style={{color:'black'}}> £ {cartSubTotal}</strong>
                    </h5>
                    </div>
                    <div className="tax-val"> 
                    <h5>
                        
                            GST 16% :   
                        <strong style={{color:'black'}}>   £  {cartTax}</strong>
                    </h5>
                    </div>
                    <div className="tot-val"> 
                    <p>
                        
                    <input style={{borderRadius:"2em", width:"70%", background:"none", border:"2px solid var(--mainMustard)"}}
                            className="input" 
                            type="text"
                            id="phoneno"
                            aria-describedby="phonenoHelp"
                            placeholder="Enter coupon code" 
                            onChange={e=>handleChange(e)}

                            />                    
                            

                      <span>        </span>
                    <button className="btn-submit" disabled={promoButton} onClick={(e)=>handlePromo(e)}>Apply</button>
</p> 
<p style={{fontSize: "12px", fontStyle: "italic"}}>{promoMsg===null?"":<div> {promoMsg}</div>}</p>
                    </div>
                    <div className="tot-val"> 
                    <h5>
                        
                            Total :   
                        <strong style={{color:'black'}}> £ {cartTotal} </strong>
                    </h5>

                    </div>
                    <div className="tot-val"> 
                    <h5>
                        
                            Discount :   
                        <strong style={{color:'black'}}> £ {"discountAmount"} </strong>
                    </h5>

                    </div>
                   
                    <div className="tot-val"> 
                    <h5>
                        
                            Amount Payable :   
                        <strong style={{color:'black'}}> £ {"amountPayable"} </strong>
                    </h5>

                    </div>
                   
                    </div>
                    
                    <div className="col-md-12 col-sm-12 ">
                    <div className="check-btn-center"> 
                 
                      
                <button  className="btn btn-outline-danger text-uppercase mb-3 px-5" onClick={()=>handleValidation(deliveryAddress, postCode, deliveryType, props, history, loggedUser, cart, loggedEmail)}>
                  Proceed to Pay....
                </button>
             <PayPalButton
                  totalAmount={amountPayable}
                  discountAmount={discountAmount}
                  totalBeforeDiscount={cartTotal}
                  loggedUser={loggedUser}
                  loggedphone={deliveryPhone}
                  loggedemail={loggedEmail}
                  postCode={postCode}
                  deliveryAddress={deliveryAddress}
                  deliveryType={deliveryType}
                  cartItems={cart}
                  history={history}
                  pass={props}
                  userPhone={loggedPhone}
                  clearCart={clearCart}

                />*/}
              

                    {/*}
                  <Link to={{pathname: '/settings', state:{loggedname:name}}} className="btn btn-outline-danger text-uppercase mb-3 px-5"> 
                    
                   Proceed to Pay....
                   
                    </Link>
    

                     
                </div>
                
                </div>
               </div>
            </div>
            
        </div>
       
</React.Fragment> */}
</Col>
            </Row>
        </Container>
        </>
    )
}
