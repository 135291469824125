import React , {useContext, useEffect} from 'react';
import { Table } from 'reactstrap';
import Footer from '../Footer';
import styled from 'styled-components';
import emailHost from '../../images/email.jpg';
import emailPer from '../../images/mail_success.gif';
import buzEmail from '../../images/buzEmail.svg';
//import webhosting from '../../images/web.png';
import { ProductContext } from '../../ProductProvider';
//import WebHost from './WebHost';
import {Helmet} from 'react-helmet-async';
import EmailPlans from './emailhost/EmailPlans';

export default function EmailHosting() {
    const { packages, addToCart, setBottomValue } = useContext(ProductContext);

    useEffect(() => {
     setBottomValue('');
     window.scrollTo(0,0);
     
}, [])

    const handleAddToCart =(event, id) =>{
        event.preventDefault();
        addToCart(id,"New");  
    }

    return (
     <>
     <Helmet>
      <title>Email Hosting Plans - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="Email Hosting Plans - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="Email Hosting Plans - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

        <div className="services-bn" style={{background:"#fff", padding:"10px"}}>
                 <div style={{display:"table", height:"100%", width:"100%", lineHeight:"normal", textAlign:"center"}}>
               <div className="services-info" style={{display:"table-cell", verticalAlign:"middle", width:"100%"}}>
                <img src={emailHost} alt="Email Hosting"/>
                </div>
                 </div>
            <services id="" data-stellar-background-ratio="0.5">
   <div> 
   {/*plans and prices*/}
   <div className="section-title">
                            <h3 style={{textAlign:"center", fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Check out our best in class plans that suits your needs - No hidden cost</h3>
                       </div>
     <EmailPlans />

   {/*<div className="" style={{paddingLeft:"5px", paddingRight:"5px"}}>
   
   {packages.length>0?<div>

<Table hover responsive bordered>

<thead>
<tr><th>Plan ID</th>
<th>Plan Name</th>
<th>Plan Details</th>
<th>Cost/year</th>
 </tr> 
</thead>    
<tbody>
{packages.filter(plan => plan.packageId.includes('IDN-EH') ).map((searchresult,index) =>(
<tr key={index}>
<th scope="row" key={searchresult.packageId}>{searchresult.packageId}</th>
<td>{searchresult.packageName}</td>
<td>{(searchresult.packageDetails).map((plan, index)=>(<WebDevWrapper><ul key="{plan}"><li key="{plan}">{plan}</li> </ul></WebDevWrapper>))} </td>
<td><i className="fa fa-rupee"></i> {searchresult.packagePrice.toFixed(2)}<div>{searchresult.inCart?<balloon aria-label="In Cart" data-balloon-pos="up"><div style={{fontSize:"30px"}}><i className="fa fa-check-circle"></i></div></balloon>:<balloon aria-label="Add to Cart" data-balloon-pos="up"><div style={{fontSize:"30px"}}><i className="fa fa-cart-plus" onClick={(event)=>handleAddToCart(event, searchresult.packageId)}></i></div></balloon>}</div></td>
</tr>))}

</tbody></Table>


</div>:<div className="tile notification is-warning"></div>}  
     </div>*/}

 {/*email personal*/}



  {/*Business Email*/}

       <div className="row navbar-bg card-service">

       <div className="col-md-6 col-sm-6 col-xs-12" style={{display:"table",  lineHeight:"normal"}}>
     <div className="services-info" >
         {/* <div className="section-title">
               <h3 style={{textAlign:"center", fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Email</h3>
          </div>
          */}
          <img src={emailPer}  style={{display:"table-cell", verticalAlign:"middle"}} alt="store"  />
          <p className="wow fadeInUp" data-wow-delay="0.4s">
               {/*<p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}></p>*/}
          </p>
     </div>
</div>
<div className="services-main col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
          <div className="section-title">
               <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Personal Email</h2>
          </div>

          <address className="wow fadeInUp" data-wow-delay="0.4s" style={{textAlign:"justify"}}>
          {/*<p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Guaranteed 99.9% Uptime</p>*/}
          Personal Email package comes with 10GB storage on your account. Secured, quickly accessible, device independent - Accessible on all devices and platforms.
                        What are you waiting for ? <p>Come - Grab your personalized email account now!</p>
                                                 
                     {/*} <p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Superior Speed Performance</p>
               Take full advantage of the speed and power. No technical knowledge needed!
               < p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Powerful Control Panel </p>
Enjoy the great speed and power with a simple control panel optimised for everyone        */}
          </address>
          
     </div>
</div>  

<div className="services-main   col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
          <div className="section-title">
               <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Business Email</h2>
          </div>
 
          <address className="wow fadeInUp" data-wow-delay="0.4s" style={{textAlign:"justify"}}>
         
          Our Business Email account comes with a storage capacity of 30GB for each account. Dedicated Hosting - 99.99% availablity. Comptabile on all devices and platforms. 
                        Sign Up today!                    
          </address>
         
     </div>
</div>

<div className="services-main col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
      
          <img src={buzEmail}  alt="store" />
          <p className="wow fadeInUp" data-wow-delay="0.4s">
             
          </p>
     </div>
</div>



</div>

<div className="navbar-mobile card-service">


<div className="col-md-6 col-sm-6 col-xs-12" style={{display:"table",  lineHeight:"normal"}}>
     <div className="services-info" >
         {/* <div className="section-title">
               <h3 style={{textAlign:"center", fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Email</h3>
          </div>
          */}
          <img src={emailPer}  style={{display:"table-cell", verticalAlign:"middle"}} alt="store"  />
          <p className="wow fadeInUp" data-wow-delay="0.4s">
               {/*<p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}></p>*/}
          </p>
     </div>
</div>
<div className="services-main col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
          <div className="section-title">
               <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Personal Email</h2>
          </div>

          <address className="wow fadeInUp" data-wow-delay="0.4s" style={{textAlign:"justify"}}>
          {/*<p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Guaranteed 99.9% Uptime</p>*/}
          Personal Email package comes with 10GB storage on your account. Secured, Quickly accessible, device independent - Accessible on all devices and platforms.
                        What are you waiting for ? Come - Grab your personalized email account now!
                                                 
                     {/*} <p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Superior Speed Performance</p>
               Take full advantage of the speed and power. No technical knowledge needed!
               < p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Powerful Control Panel </p>
Enjoy the great speed and power with a simple control panel optimised for everyone        */}
          </address>
          
     </div>
</div>  
<div className="services col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
       <img src={buzEmail}  alt="store" />
          <p className="wow fadeInUp" data-wow-delay="0.4s">
           
          </p>
     </div>
</div>

<div className="services-main   col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
          <div className="section-title">
               <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Business Email</h2>
          </div>
 
          <address className="wow fadeInUp" data-wow-delay="0.4s" style={{textAlign:"justify"}}>
       
          Our Business Email account comes with a storage capacity of 30GB for each account. Dedicated Hosting - 99.99% availablity. Compatible on all devices and platforms. 
                        Sign Up today!                 
          </address>
          

     </div>
</div>
     </div>

    </div>
    </services>
    <Footer />
    </div>
    </>
    )
}
const WebDevWrapper = styled.nav`

ul {
     list-style: none;
   }
   
   ul li:before {
     content: '✓';
     padding: 5px;
   }
 
    
    `