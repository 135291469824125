import React, { useEffect } from 'react';

export default function PrivacyPolicy() {
    useEffect(() => {
        
        window.scrollTo(0,0);
        
   }, [])


    return (
        <div className="container" style={{paddingTop:"65px"}}>
                                      <h2 style={{fontFamily: "Oswald", color:"#1362b3", textAlign: "center", fontSize:"22px"}}> Privacy Policy - Idntica.com</h2>


<p style={{fontFamily: "Oswald", textAlign: "center"}}>Last Revised: 05-08-2021</p>

<p style={{fontFamily:"Cookie", fontSize:"1.5rem", color: "black", textAlign:"justify"}}><b>Idntica.com</b> cares about your privacy. For this reason, we collect and use personal information only as needed to deliver our products, services, websites and mobile applications, and to communicate with you about the same, or as you have requested (collectively, our “Services”). Your personal information includes information such as:
<br />Name
<br />Address
<br />Telephone number
<br />Email address
<br />Candidate information (for job applicants)
<br />Other data collected that could directly or indirectly identify you.
<br /><br />Our Privacy Policy not only explains how and why we use your personal information that we collect, but also how you can access, update or otherwise take control of your personal information. We’ve also created a FAQ section that offers answers to your most common questions, quick links to access your Account Settings, instructions on how to exercise certain rights that might be available to you, and definitions to key terms and concepts noted in this Privacy Policy.
If at any time you have questions about our practices or any of your rights described below, you may reach our dedicated team that supports this office by contacting us at admin@idntica.com. This inbox is actively monitored and managed so that we can deliver an experience that you can confidently trust.
What information we collect, how we collect it, and why
Much of what you likely consider personal information is collected directly from you when you:
create an account or purchase any of our Services (ex: billing information, including name, address, phone number);
request assistance from our award-winning customer support team (ex: phone number);
complete contact forms or request newsletters or other information from us (ex: email, phone number); or
participate in contests and surveys, apply for a job, or otherwise participate in activities we promote that might require information about you.
However, we also collect additional information when delivering our Services to you to ensure necessary and optimal performance. These methods of collection may not be as obvious to you, so we thought we’d highlight and explain a bit more about what these might be (as they vary from time to time):
Cookies and similar technologies on our websites and mobile applications allow us to track your browsing behavior, such as links last visited, and items purchased. Specifically, the data collected relates to your interactions with our site features .These technologies also automatically collect data to measure website performance and improve our systems, including metadata, log files, page load time, server response time to optimize DNS resolution, network routing and server configurations. For additional information, and to learn how to manage the technologies we utilize, please visit our Cookie Policy.
How we utilize information.<br /><br />
We strongly believe in both minimizing the data we collect and limiting its use and purpose to only that (1) for which we have been given permission, (2) as necessary to deliver the Services you purchase or interact with, or (3) as we might be required or permitted for legal compliance or other lawful purposes:
Delivering, improving, updating and enhancing our Services. We collect various information relating to your purchase, use and/or interactions with our Services. We utilize this information to:
Improve and optimize the operation and performance of our Services (again, including our websites and mobile applications)
Diagnose problems with and identify any security and compliance risks, errors, or needed enhancements to the Services
Detect and prevent fraud and abuse of our Services and systems
Much of the data collected is aggregated or statistical data about how individuals use our Services, and is not linked to any personal information.
Sharing with trusted third parties. We may share your personal information with affiliated companies within our corporate family, with third parties with which we have partnered to allow you to integrate their services into our own Services, and with trusted third party service providers as necessary for them to perform services on our behalf, such as:
Processing credit card payments
Communicating with you, such as by way email or survey delivery
Customer relationship management
Security, risk management and compliance
Recruiting support and related services.
These third parties (and any subcontractors they may be permitted to use) have agreed not to share, use or retain your personal information for any purpose other than as necessary for the provision of Services.
Communicating with you. We may contact you directly or through a third party service provider regarding products or services you have signed up or purchased from us, such as necessary to deliver transactional or service related communications. We may also contact you with offers for additional services we think you’ll find valuable if you give us consent, or where allowed to contact you based on legitimate interests. You don’t need to provide consent as a condition to purchase our goods or services. These contacts may include:
Email
Text (SMS) messages
Telephone calls
Messenger applications (e.g. WhatsApp, etc.)
Automated phone calls or text messages.
You may also update your subscription preferences with respect to receiving communications from us and/or our partners by signing into your account and visiting your “Account Settings” page.
Transfer of personal information abroad. If you utilize our Services from a country other than the country where our servers are located, your personal information may be transferred across international borders, which will only be done when necessary for the performance of our contract with you, when we have your consent to do so, or subject to the appropriate standard contractual clauses. Also, when you call us or initiate a chat, we may provide you with support from one of our global locations outside your country of origin.
Compliance with legal, regulatory and law enforcement requests. We cooperate with government and law enforcement officials and private parties to enforce and comply with the law. We may disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process (such as subpoena requests), to protect our property and rights or the property and rights of a third party, to protect the safety of the public or any person, or to prevent or stop activity we consider to be illegal or unethical.
To the extent we are legally permitted to do so, we may take reasonable steps to notify you if we are required to provide your personal information to third parties as part of legal process. We will also share your information to the extent necessary to comply with any ICANN, registry or ccTLD rules, regulations and policies when you register a domain name with us. For reasons critical to maintaining the security, stability and resiliency of the Internet, this includes the transfer of domain name registration information to the underlying domain registry operator and escrow provider, and publication of that information as required by ICANN in the public WHOIS database or with other third parties that demonstrate a legitimate legal interest to such information.
How we secure, store and retain your data.
We follow generally accepted standards to store and protect the personal information we collect, both during transmission and once received and stored, including utilization of encryption where appropriate.
We retain personal information only for as long as necessary to provide the Services you have requested and thereafter for a variety of legitimate legal or business purposes. These might include retention periods:
mandated by law, contract or similar obligations applicable to our business operations;
for preserving, resolving, defending or enforcing our legal/contractual rights; or
needed to maintain adequate and accurate business and financial records.
If you have any questions about the security or retention of your personal information, you can contact us at admin@idntica.com.
How you can access, update or delete your data.
To easily access, view, update, delete or port your personal information, or to update your subscription preferences, please sign into your Account and visit “Account Settings.” Please visit our FAQ section for additional information and guidance for accessing, updating or deleting data.
If you make a request to delete your personal information and that data is necessary for the products or services you have purchased, the request will be honored only to the extent it is no longer necessary for any Services purchased or required for our legitimate business purposes or legal or contractual record keeping requirements.
If you are unable for any reason to access your Account Settings or our Trust Center, you may also contact us by one of the methods described in the “Contact Us” section below.
Age restrictions.
Our Services are available for purchase only for those over the age of 18. Our Services are not targeted to, intended to be consumed by or designed to entice individuals under the age of 18. If you know of or have reason to believe anyone under the age of 18 has provided us with any personal information, please contact us per the instructions below.
Non-Discrimination.
We will not discriminate against you for exercising any of your privacy rights. Unless permitted under applicable laws, we will not:
Deny you goods or services.
Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.
Provide you a different level or quality of goods or services.
Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.
Changes to this policy.
We reserve the right to modify this Privacy Policy at any time. If we decide to change our Privacy Policy, we will post those changes to this Privacy Policy and any other places we deem appropriate, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. If we make material changes to this Privacy Policy, we will notify you here, by email, or by means of a notice on our home page, at least thirty (30) days prior to the implementation of the changes.
Contact us.
<br />
If you have any questions, concerns, or complaints about our Privacy Policy, or how we handle your personal data, you may contact our Office of the Data Protection Officer by email at admin@idntica.com. In the alternative, you may contact us by either of the following means:
<br />By eMail: admin@indtica.com
<br />By Phone: +91-9789099422
<br />We will respond to all requests, inquiries or concerns within thirty (30) days.

</p>
            
        </div>
    )
}
