import Options from "./Options";
import '../ChatStyle.css';

const GeneralOptions = (props) => {
  const options = [
    {
      name: "Show Hosting Plans",
      handler: props.actionProvider.handleHostingPlans,
      id: 1
    },
    {
      name: "Show Development Plans",
      handler: props.actionProvider.handleDevelopmentPlans,
      id: 2
    },
    {
      name: "Show Email Plans",
      handler: props.actionProvider.handleEmailPlans,
      id: 3
    },
    {
      name: "Contact",
      handler: props.actionProvider.handleContact,
      id: 4
    },
  ];
  return <Options options={options} title="Options" {...props} />;
};

export default GeneralOptions;
