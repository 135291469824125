import React, { useState, useEffect, useContext } from 'react';
//import { Card, CardBody, Col, Form, FormGroup, Row } from 'reactstrap';
import axios from "axios";
import { Auth } from "aws-amplify";
import InvoiceDownloader from './InvoiceDownloader';
//import { Link, useHistory } from 'react-router-dom';
//import Cookies from 'js-cookie';
import useFullPageLoader from "../hooks/useFullPageLoader";
import { Table } from 'reactstrap';
import { ProductContext } from '../../ProductProvider';
import Preloader from '../preloader/Preloader';
import {Helmet} from 'react-helmet-async';

//import TransactionView from './TransactionView';
const config = require('../../config.json');


export default function MyTransaction(props) {
    //let history = useHistory();
    const { loggedUser , setShowPage} = useContext(ProductContext);
    const [myTransactions, setMyTransactions] = useState('');
    const [dispMsg, setDispMsg] = useState('');
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [loaded, setLoaded]=useState(true);

    useEffect(() => {
      //  setBottomValue('MyTransaction');
        setShowPage(false);
        fetchUserTransaction();

    }, []);

    const fetchUserTransaction = async () => {
        setLoaded(false);
        setDispMsg("");
        setMyTransactions("");
        //showLoader();
        try {
            const session = await Auth.currentSession();
            const access_token = session.idToken.jwtToken;
            //console.log("Access token : ", access_token);
            const clientid = loggedUser.clientID;
            //const clientid=tempClientid.substring(1, tempClientid.length);
            await axios.get(`${config.api.invokeUrl}/client/accounts/transactions/mytransaction/${clientid}`, {
                headers: {
                    Authorization: access_token,
                    'x-api-key': config.api.key
                }
            })
                .then((response) => {
                    setMyTransactions(response.data);
                    if(response.data.length<1){
                        setDispMsg("No Previous Transactions Found");
                    }
                    setLoaded(true);
                })
                .catch((error) => {
                    setLoaded(true);
                    setDispMsg(`Error Occured : ${error.message}`);
                })

        } catch (error) {
            setLoaded(true);
            setDispMsg(`Error Occured : ${error.message}`);
        }
        //hideLoader();
    }

    return (
    <>
    <Helmet>
      <title>My Transactions - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="My Transactions - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="My Transactions - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

    {!loaded?<Preloader />:null}
        <div style={{paddingTop:"65px", paddingBottom:"60px"}}>
            
            <div className="container"> {loggedUser.isAuthenticated ?  <div>
                <h4 className="text-title"><i className="fa fa-credit-card fa-fw" aria-hidden="true"></i>&nbsp; My Transactions</h4>
              {myTransactions.length > 0 ?  
               
              <div>
                            <Table hover responsive bordered>
                            <thead>
                            <tr><th style={{textAlign:"center"}}>Order ID</th>
                            <th style={{textAlign:"center"}}>Order Date</th>
                            <th style={{textAlign:"center"}}>Status</th>
                            <th style={{textAlign:"center"}}>Amount</th>
                            <th style={{textAlign:"center"}}>Action</th>
                            </tr> 
                            </thead>    
                            <tbody>                           
                                    {myTransactions && myTransactions.length > 0 ? myTransactions.map((searchuser,index) => 
                                    (
                                        <tr key={index}>
                                <th scope="row" >{searchuser.orderId}</th>
                            <td>{searchuser.transactionDate}</td>

                            <td>{searchuser.txStatus}</td>
                            <td><i className="fa fa-rupee"></i> {searchuser.orderAmount}</td>
                            <td>{searchuser.txStatus==="SUCCESS"?<InvoiceDownloader propsFile={searchuser} />:<div></div>}</td>
                                    </tr>              )       )                          
                            
                            :            <h5 style={{textAlign:"center", padding:"20px"}} className="tile notification is-warning"><b>No Transactions Found</b></h5>
                        }                                                 
                                                                                            
                </tbody> 

        </Table>
       
    </div>

 : <h5 style={{textAlign:"center", padding:"20px"}} className="tile notification is-warning"><b>{dispMsg}</b></h5> }
     </div>           
                    :  <h5 style={{textAlign:"center", padding:"20px"}} className="tile notification is-warning"><b>{dispMsg}</b></h5>}

            </div>


        </div>
    </>
    )
}
