import React, { useState } from 'react';
//import Footer from './Footer';
import axios from "axios";
const config = require('../../config.json');

export default function ContactForm() {
    const [email, setEmail] = useState('');
    const [name,setName] = useState('');
    const [desc, setDesc]=useState('');
    const [phone, setPhone] = useState('');
    const [dispMsg, setDispMsg] = useState('');

    const handleSubmit = async event => {
     event.preventDefault();  
     if(name==="" || email===""||phone===""||desc===""){
          setDispMsg("Fill out all the form details");

     }else{
     try {
          
           const params = {
             "name":name,
             "email":email,
             "phone":phone,
             "desc":desc
           }
           //console.log("Params : ",params);
           //coping the response data to dynamodb
           await axios.post(`${config.api.invokeUrl}/contactus`, params,{
            headers: {
              'x-api-key':config.api.key
            }
          }).then(() => {
              //console.log("Response : ", response);
                setDispMsg("Thanks for submitting your Query! Our Admin team will be in touch with you shortly!");
                setDesc('');
                setEmail('');
                setPhone('');
                setName('');
          }, (error) => {
           // console.log("Error1 : ", error);

               setDispMsg(error.message);
          });
          // this.props.auth.setOrderResponse(response);
          } catch (error) {
           //onError(e); 
           setDispMsg(error.message);
           //console.log("Error 2 : ", error);

         }
     }

     }

  return (
    <form className="wow  fadeInUp" id="contact-form" data-wow-delay="0.8s">
    <div className="row ">
         <div className="col-md-6 col-sm-6">
              <input className="form-control" type="text" id="name" name="name" placeholder="Full name" 
              value={name}
              onChange={(event)=>{setName(event.target.value)}}
            />
         </div>

         <div className="col-md-6 col-sm-6">
              <input type="email" className="form-control" id="email" name="email" placeholder="Email address" 
              value={email}
              onChange={(event)=>{setEmail(event.target.value)}}
/>
         </div>

    </div>
    <div className="row ">
         <div className="col-md-12 col-sm-12">
              <input type="text" className="form-control" id="phone" name="phone" placeholder="Phone No"    
              value={phone}
              onChange={(event)=>{setPhone(event.target.value)}}
/>

              <textarea className="form-control" rows="6" id="desc" name="desc" placeholder="Post your queries..."
              value={desc}
              onChange={(event)=>{setDesc(event.target.value)}}
            ></textarea>
<div className = "text-success">
{dispMsg}
      </div>

      
            
         </div>
        

</div>  

<button 
className="btn" style={{border: "1px solid #1362b3", display:"block", padding:"12px", cursor:"pointer", margin:"auto", justifyContent:"center", width:"30%"}} 
type="submit" 
id="cf-submit" onClick={handleSubmit} >Send</button>

  
    </form>
      )
}
