import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import logo from '../../images/idnticalogo.jpg';


export default function WhatsAppFloat() {
  return (
    <div>
      <FloatingWhatsApp phoneNumber="919789099422" accountName="Idntica"
      allowClickAway allowEsc avatar={logo}/>
    </div>
  )
}
