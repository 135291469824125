//import { faBorderNone } from '@fortawesome/free-solid-svg-icons';
import React, {useContext} from 'react';
//import styled from 'styled-components';
import { ProductContext } from '../../ProductProvider';



export default function CartItem({item}) {
    const { increment, decrement, removeItem } = useContext(ProductContext);
    const {packageId, packageName, total, years, category, accId, validTo} =item;
    const totaltemp= parseFloat(total).toFixed(2)
    
    return (
  
       
       
            <tr>
              <th scope="row"> ({category}) {packageName} {accId}  {packageId.startsWith('IDN-WD')? <div></div>:<div>(Valid Till {validTo})</div> }</th> 
              <td style={{display:"flex", justifyContent:"space-around", flexWrap:"wrap", textAlign:"center", height:"60px"}}>
            {packageId.startsWith('IDN-WD')? <div></div>:<button style={{width:"25%", margin:"auto", fontSize:"18px", background:"#1362b3", color:"white", borderRadius:"8px"}} onClick={()=>decrement(packageId,category, accId)}>-</button>}
            <button style={{width:"50%", margin:"auto", fontSize:"18px", border: "none" }} >{years}</button>       
            {packageId.startsWith('IDN-WD')? <div></div>:<button style={{width:"25%", margin:"auto", fontSize:"18px", background:"#1362b3", color:"white", borderRadius:"8px"}} onClick={()=>increment(packageId,category, accId)}>+</button>}
            </td>
            <td style={{textAlign:"center"}}>
              <i className="fa fa-trash" onClick={()=>removeItem(packageId, accId, category)}></i>
            </td>
            <td style={{textAlign:"right"}}>
            <i className="fa fa-rupee"></i> {totaltemp}
            </td>
            
            </tr>
           
         
          
     
    
    )
}
/*const CartWrapper = styled.div`
.sub-cat-menu{
    font-size: 10px !important;
    color: white !important;
    text-align: left;
  }

.nopadding {
    padding: 0 !important;
   margin: 0 !important;
 }
.paddingLeft{
    padding-left: 0 !important;
}
}

`;*/