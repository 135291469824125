import React, {useContext} from 'react';
import CartItem from './CartItem';
import { ProductContext } from '../../ProductProvider';
import {Col,Row, Container, Table} from 'reactstrap';

export default function CartList() {
    const { cart } = useContext(ProductContext);

    return (
        <Container>
            <Row>
              <Col>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>
                      Plan
                    </th>
                    <th style={{textAlign:"center"}}>
                      Quantity
                    </th>
                    <th style={{textAlign:"center"}}>
                        Remove
                    </th>
                    <th style={{textAlign:"right"}}>
                      Price
                    </th>
                    
                  </tr>
                </thead> 
                <tbody>
           {cart.map(item =>{
               return (
               
                <CartItem key={item.cartno} item={item} />
               
                )
               
           })}
            </tbody>
            </Table>
              </Col>
            </Row>
        </Container>
            
       
    )
}
