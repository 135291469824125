import React from "react";
//import Spinner from "../../../images/spinner.gif";
import logo from '../../../images/idnticaicon.png';

const FullPageLoader = () => {
    return (
        <div className="fp-container">
           {/*} <img src={Spinner} className="fp-loader" alt="loading" />*/}
           <section className="preloaderidntica">
		<div className="spinner">

			 
			 
		</div>
		<span><img src={logo} alt="" style={{width: "100px", height:"100px"}}/></span>
   </section> 
   
        </div>
    );
};

export default FullPageLoader;