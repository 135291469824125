import React from 'react';
import { Link } from 'react-router-dom';
import serverError from '../../images/svg/500-server-error.svg';
import {Helmet} from 'react-helmet-async';

export default function ServerError() {

return (
    <div className="login">
    <Helmet>
      <title>Server Error - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

            <section  className="sign-in">
            <div className="container">
            <h2><Link to="/" className="signup-image-link">Go back to Home Page</Link></h2>
            <figure><img src={serverError} alt="sign up"/></figure>
            <h2><Link to="/" className="signup-image-link">Go back to Home Page</Link></h2>
            </div>
        </section>
    </div>
    )

}