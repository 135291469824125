import React, {useContext} from 'react';
import {useHistory} from "react-router-dom";
import styled from 'styled-components';
import { ProductContext } from '../../ProductProvider';
import {Col,Row, Container, Table} from 'reactstrap';
import Cookies from 'js-cookie';

export default function CartTotals() {
  let history = useHistory();

    const {cartSubTotal, cartTax, cartTotal, loggedUser, setOrderId, setOrderRefId, setTransactionDate}= useContext(ProductContext);

    const handleCheckout = async(event)=>{
      event.preventDefault();
      if(loggedUser.isAuthenticated){
        await generateOrderID();

        history.push("/checkout");

        /*setBillingDetails({...billingDetails,
          clientId:clientId,
          domainName:domainName,
          clientPlan:clientPlan,
          planPrice:planPrice,
          pocName:pocName,
          pocEmail:pocEmail,
          pocPhone:pocPhone,
          pocAddress:pocAddress,
          lastRenewalDate:lastRenewalDate,
          clientPlanDetails:clientPlanDetails,
          renewYears:renewYears,
          totalAmount:totalAmount,
          gstAmount:gstAmount,
          renewalUpto:renewalUpto,
          transactionAmount:transactionAmount,
          planCharges:planCharges,
          payableAmount:totalAmount
        });*/
      }else{
        //console.log("Not Authenticated");
        history.push("/signin");

      }
      
    }

    const handleContinueShopping = async()=>{
      history.push("/webhosting");

    }

    const generateOrderID = async () => {
      const user = loggedUser.clientID;
      //console.log("Client ID : ", user);
      const current_datetime = new Date();
      const current_local_datetime = new Date().toLocaleString('en-US', {timeZone: 'Asia/Kolkata'});
      const orderNoTemp = "";
      const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
      var date_month = (current_datetime.getMonth() + 1).toString();
      var month_value="";
      date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
      var date_date = (current_datetime.getDate().toString());
      var date_value="";
      date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
      var date_hours = (current_datetime.getHours().toString());
      var hours_value="";
      date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
      var date_minutes = (current_datetime.getMinutes().toString());
      var minutes_value="";
      date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
      const order_no = orderNoTemp.concat(date_year,month_value, date_value, hours_value, minutes_value);
      const orderRef_id = orderNoTemp.concat(user, date_year,month_value, date_value, hours_value, minutes_value);
    //const tempTransactionDate="";
      //const orderDate = tempTransactionDate.concat(current_datetime.getFullYear(),"-",month_value,"-",date_value);
      setOrderId(order_no);
      setOrderRefId(orderRef_id);

     // console.log("Order No : ", order_no);
     // console.log("Ref ID : ", orderRef_id);
      setTransactionDate(current_local_datetime);
      await Cookies.set('orderid', order_no, { expires: 7 });

  }

   
  return (
        <CartWrapper>
            <div className="cart-tot-dis">
       <Container>
         <Row>
           <Col sm={6}>

           </Col>
           <Col sm={6}>
           <Table hover responsive>
                <thead>
                  <tr>
                    <th>
                    GST - 18% 
                    </th>
                    <th style={{textAlign:"right"}}>
                    <i className="fa fa-rupee"></i>  {cartTax}
                    </th>
                  
                  </tr>
                </thead> 
                <tbody>
                  <tr>
                <th scope="row"> Sub Total </th> 
                <td style={{textAlign:"right"}}>
                <i className="fa fa-rupee"></i> {cartSubTotal}
                </td>
                </tr>
                  <tr>
                    <th scope="row" style={{fontSize:"20px"}}>
                    Total
                      </th>
                <td style={{textAlign:"right", fontSize:"20px"}}>
                <i className="fa fa-rupee"></i> {cartTotal}
                </td>
                </tr>
               
                  </tbody> 
                 
                  </Table>
                  <Table hover responsive>
                    <tbody>
                  <tr >
                 <td style={{textAlign:"left", fontSize:"14px"}}>
                   <button className="btn" style={{border:"1px solid blue", fontWeight:"400"}} onClick={(event)=>{handleContinueShopping(event)}}>Back</button>
                 
                   </td>
                   <td style={{textAlign:"right", fontSize:"20px"}}>
                     <button className="btn" style={{border:"1px solid #006400", background:"#006400", color: "white", fontWeight:"700"}} onClick={(event)=>{handleCheckout(event)}}>Checkout</button>
                 
                   </td>

                 
               </tr>
               </tbody>
                    </Table>
           </Col>
         </Row>
         </Container>
            <div className="row">
            <div className="col-6">
              </div>
              <div className="col-6">
               
                  {/*  <div className="clear-cart-btn ">                    
                    <Link to="/menu">
                        <button className="btn btn-outline-danger text-uppercase mb-3 px-5" type="button" onClick={()=>clearCart()}>
                            clear cart
                        </button>
                    </Link>
                    </div>
                    <div className="sub-tot-val col-xs-6"> 
                    <h5>
                        <strong> subtotal : £ {cartSubTotal}</strong>
                    </h5>
                    </div>
                    <div className="tax-val col-xs-6"> 
                      
                   <h5>
                        
                            Delivery :   
                        <strong>   £ {cartTax} </strong>
                  </h5> 
  </div> */}
                  
                {/*  
                    <div className="tot-val col-md-8 col-sm-8 col-xs-8  nopadding"> 
                    <div className="col-md-8 col-xs-8 "><p>
                                 </p>
                    </div>
                     <div className="col-md-4 col-xs-4 cartTotal-right">
                     <strong >   £  {cartTax}</strong>
                    </div>
                 
                  <div className="col-md-8 col-xs-8  ">
                  <p>Sub Total</p>   
                    </div>
                   <div className="col-md-4 col-xs-4 cartTotal-right">
                    <strong > £ {cartSubTotal}</strong>
                    </div>
                 
                    <div className="col-md-8 col-xs-8 ">
                 <h4>  Total   </h4>
                    </div>
                    <div className="col-md-4 col-xs-4 cartTotal-right-Tot nopadding">
                    <h4>   <strong > £ {cartTotal} </strong> </h4>
                    </div>
                   
                    </div>
                    <div className="check-btn col-md-4 col-sm-4 col-xs-4 "> 
                  <h5 >
                     {/*<Link className="btn btn-outline-danger text-uppercase" 
                     to='/checkout' >
                    Checkout
</Link>
                  <button onClick={(event)=>{handleCheckout(event)}}>Checkout</button>
                  </h5>  */}  
                </div>        
            </div>        
        </div>
       
        </CartWrapper>
    )
}
const CartWrapper = styled.div`
.nopadding{
    padding: 0 !important;
    margin: 0 !important;
}

.cartTotal-right{
  
  font-size: 14px;
  color: black;
  text-align: right;
  padding: 0px;
}
.cartTotal-right-Tot h4{
    color: var(--mainMustard);
  text-align: right !important;
  word-spacing: 5px;
  
}
.check-btn{
    
    padding-top: 15px;
    padding-right: 10px;
}
.tot-val p{
font-size: 12px;
}

@media screen and (max-width: 640px) {
  .check-btn .btn{
    
    padding-top: 5px;
    padding-bottom: 5px;
}
}

`;