import React, {useEffect} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
//import logo from '../images/idnticaicon.png'
import Footer from './Footer';
import ContactUs from './ContactUs';
//import HomeHeader from './HomeHeader';
import HomeSlider from './HomeSlider';
import OurServices from './OurServices';
import {Helmet} from 'react-helmet-async';

export default function Home() {

    useEffect(() => {
        
        window.scrollTo(0,0);
        
   }, [])

    return (
    <>
    <Helmet>
      <title>Home - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="Home - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="Home - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

        <div className="home" >
            
            <div className="slider">
                <HomeSlider />
            </div>
            <div >
                <OurServices />
            </div>
            
            <ContactUs />
            <Footer />
        </div>
        </>
    )
}

