import React, {useContext, useState} from 'react';
import { Button, Col } from 'reactstrap';
import axios from "axios";
import { Auth } from "aws-amplify";
import phonepeLogo from '../../../images/logos/phonepeLogo.svg';
import { ProductContext } from '../../../ProductProvider';
const config = require('../../../config.json');

export default function InitiatePhonePe() {
    const { orderId, orderRefId, transactionDate, cart, cartTotal, loggedUser, cartTax, cartSubTotal, 
        discountAmount, discountValue, discountCode, alertDetails, setAlertDetails,alertClose, 
        } = useContext(ProductContext);
    const [phonePeRes, setPhonePeRes] = useState();
    const [loaded, setLoaded]=useState(false);

    const handleInititeOrder=async()=>{
        setLoaded(false);
        if(!cartTotal || !loggedUser.clientID || !loggedUser.email || !loggedUser.phoneno) {
           setAlertDetails({
             ...alertDetails,
             style:"warning",
             open:true,
             message:"Payment cannot be processed! Try Again!!"
         });  
         setLoaded(true);
         } else {

        try{
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            const orderid=orderId;
            const params = {
                "clientId": loggedUser.clientID,
                "orgName": loggedUser.orgName,
                "pocName": loggedUser.username,
                "pocEmail": loggedUser.email,    
                "pocPhone": loggedUser.phoneno,
                "pocAddress":loggedUser.address,
                "orderId": orderid,
                "orderRefId": orderRefId,
                "orderAmount": cartTotal,
                "referenceId":"NA",
                "txStatus": "NA",
                "paymentMode": "NA",
                "txMsg": "NA",
                "txTime": "NA",
                "transactionDate" : transactionDate,
                "gstCharges": cartTax,
                "discountAmount":discountAmount,
                "discountValue":discountValue,
                "discountCode":discountCode,
                "subTotal":cartSubTotal,
                "cart":cart
            };

            console.log("initiated");
            await axios.post(`${config.api.invokeUrl}/transaction/phonepe/create-order`, params,{
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }})
                .then((res)=>{
                   // console.log("Success : ", res);
                    setPhonePeRes(res.data);
                    setLoaded(true);
                    window.location.href=res.data.data.instrumentResponse.redirectInfo.url;
   
                })
                .catch((error)=>{
                   console.log("Error : ", error);
                   setAlertDetails({
                       ...alertDetails,
                       style:"warning",
                       open:true,
                       message:"Payment cannot be processed! Try Again!!"
                   });        
                   setLoaded(true);
                   // props.setDispMsg(error.message);
                })
   
        }catch(error){
            console.log("Error : ", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Payment cannot be processed! Try Again!!"
            });    
            setLoaded(true);
        }
    }
    }

  return (
    <>
    <div>
    <Col sm={{ size: 12 }}>
            <Button type="submit" className="btn pull-center" 
            style={{backgroundColor:"transparent", border:"none", borderRadius:"10px"}}
            onClick={handleInititeOrder}>
                <img src={phonepeLogo} height="30" width="100" style={{borderRadius:"10px", height:"70px"}}/></Button>
        </Col>

    </div>
    </>
  )
}
