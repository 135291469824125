import React, { useEffect } from 'react'

export default function RefundPoilcy() {
    useEffect(() => {
        
        window.scrollTo(0,0);
        
   }, [])

    return (
        <div className="container"  style={{paddingTop:"65px"}}>


<h2 style={{fontFamily: "Oswald", color:"#1362b3", textAlign: "center", fontSize:"22px"}}>REFUND POLICY - Idntica.com</h2>


<p style={{textAlign: "center"}}>Last Revised: 08-06-2021</p>

<p style={{fontFamily:"Cookie", fontSize:"1.5rem", color: "black", textAlign:"justify"}}>Products purchased from Idntica.com, may be refunded only if cancelled within the refund period specified below in this policy. Some products have different policies or requirements for a refund associated with them, including some products that are not eligible for a refund under any circumstance. Please see below for refund terms applicable to such products.
<br /><br />
<b>“Date of the transaction”</b>, for the purpose of this Refund Policy, means the date of purchase of any product or service, which includes the date any renewal is processed by Idntica.com, in accordance with the terms and conditions of the applicable product or service agreement.
<br /><br /><b>"Refund Period"</b>, you may cancel a product at any time, but a refund will only be issued if you request a refund with Idntica.com customer service within the refund time frame specified for the applicable product, if available at all.
<br /><br />
No Refund After Account Closure. If eligible for a refund, it is necessary for you to request a refund prior to account closure. You may elect to close your account with us at any time, but upon account closure you will no longer be eligible for a refund as otherwise permitted under this Refund Policy.
<br /><br />
<b>Standard Refund Terms</b><br />
<b>Annual Plans</b> - Within 7 days of the date of the transaction.
<br /><b>Domain Name Registrations/Renewals</b>- No refund applicable on Domain name registration / renewals.
<br /><br />
<b>Idntica IT Services</b><br />
If IT Service has already been performed, then it is non-refundable (if not yet performed, eligible for a refund within 7 days of the date of the transaction).
<b>Annual Subscriptions: Non-refundable</b>
<br /><br />
<b>Hosting Services</b><br />
If a Hosting Service has already been performed, then it is non-refundable (if not yet performed, eligible for a refund within 7 days of the date of the transaction).
<br /><br />
<b>Website Design Services</b><br />
<b>Web Services</b>: ‪30%‬ cancellation fee when in progress, ‪70%‬ cancellation fee when site design is delivered for review, non-refundable once first revision is completed. If not yet performed, eligible for a refund within 15 days of the date of the transaction. Once performance has begun, web service design is considered completed 30 days from the date of the transaction and will not be eligible for a refund thereafter.
<b>Logo Design</b>: ‪40%‬ cancellation fee when in progress, non-refundable once artwork delivered. If not yet performed, eligible for a refund within 7 days of the date of the transaction. Once performance has begun, logo design is considered delivered 30 days from the date of the transaction and will not be eligible for a refund thereafter.
<b>Website Makeover Services</b>: ‪30%‬ cancellation fee when in progress, ‪70%‬ cancellation fee when site design is delivered for review, non-refundable once complete.
<br /><br />
<b>Products Not Eligible for Refunds</b>
<ul>
<li>Cloud Servers</li>
<li>SSL Certificate</li>
<li>Domain: New and Renewal</li>
<li>Hosting Connection paid Apps (not refundable once set up)</li>
<li>Premium Domain Names</li>
<li>Transfers (if successful)</li>
</ul>
</p>
        </div>
    )
}
