import React from "react";
import '../ChatStyle.css';

import UrlIcon from "../icons/call.svg";
import emailIcon from '../icons/email.svg';
import ContactForm from "../../contact/ContactForm";

const ContactLink = () => {
  return (
    <div >
    <a href="tel:1999" className="tel-link">
      <img className="url-icon" alt="CallIcon" src={emailIcon} />
      <h6 className="tel-header">admin@idntica.com</h6>
    </a>
    <a href="tel:1999" className="tel-link option-item">
      <img className="url-icon" alt="CallIcon" src={UrlIcon} />
      <h6 className="tel-header">+91-9789099422 </h6>
    </a>
    <ContactForm />
    <div className="tel-link option-item options-container">
    Looking for more options - type 'help' 
  </div>

    </div>
  );
};

export default ContactLink;
