import React, { useContext, useEffect, useState } from 'react';
import './sidebar.css';
import logo1 from '../../images/idnticaicon.png';
import { Link, useHistory } from "react-router-dom";
//import profile from '../../images/profile-icon.png';
import { Auth } from "aws-amplify";
//import MyAccounts from '../dashboard/MyAccounts';
import { ProductContext } from '../../ProductProvider';
import MyProfile from '../dashboard/MyProfile';
import MyTransaction from '../dashboard/MyTransaction';
import QuickView from '../dashboard/QuickView';
import ChangePassword from '../auth/ChangePassword';
import MyWebHostingAcc from '../dashboard/MyWebHostingAcc';
import MyWebDevAcc from '../dashboard/MyWebDevAcc';
import MyEmailAcc from '../dashboard/MyEmailAcc';

export default function Sidebar() {
    //const {handleShow} = props;

    let history = useHistory();
    const { loggedUser, setLoggedUser } = useContext(ProductContext);
    const [showWebHostAcc, setShowWebHostAcc] = useState(false);
    const [showWebDev, setShowWebDev] = useState(false);
    const [showEmailAcc, setShowEmailAcc] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [showTransaction, setShowTransaction] = useState(false);
    const [showChangePwd, setShowChangePwd] = useState(false);
    //const [showMyProfile, setShowMyProfile] = useState(false);
    //const [showManageUser, setShowManageUser] = useState(false);
    const [showQuickView, setShowQuickView] = useState(true);

    useEffect(() => {
        const arrow = document.querySelectorAll(".arrow");
        for(var i=0; i < arrow.length; i++){
            arrow[i].addEventListener("click", (e)=>{
           
            let arrowParent = e.target.parentElement.parentElement; 
            
            arrowParent.classList.toggle("showMenu");
            });
        }      
        let sidebar=document.querySelector(".sidebar");
        let sidebarBtn=document.querySelector(".fa-home");
       
        function handleResize() {
            
            if (window.innerWidth > 580) {
                sidebar.classList.toggle("close");
            } 
          }
          handleResize();
     
        sidebarBtn.addEventListener("click", ()=>{
            sidebar.classList.toggle("close");
        });
        
    }, [])

    const handleShow = (value, event) => {
        event.preventDefault();
       // console.log("Value received : ", value);
        setShowWebHostAcc(false);
        setShowProfile(false);
        setShowTransaction(false);
        setShowChangePwd(false);
        //setShowMyProfile(false);
        //setShowManageUser(false);
        setShowQuickView(false);
        setShowWebDev(false);
        setShowEmailAcc(false);
        if (value === "WebAccounts") {
            setShowWebHostAcc(true);
        } else if (value==="WebDev"){
            setShowWebDev(true);
        } else if (value==="EmailAcc"){
            setShowEmailAcc(true);
        } else if (value === "Profile") {
            setShowProfile(true);
        } else if (value === "Transaction") {
            setShowTransaction(true);
        } else if (value === "ChangePwd") {
            setShowChangePwd(true);
        } else if (value==="QuickView") {
            setShowQuickView(true);
        }
    }

    const handleSignOut = async(event)=>{
        event.preventDefault();
        try{
            Auth.signOut().then(() => {
                setLoggedUser({...loggedUser,
                    username: '',
                    isAuthenticated: false,
                    email:'',
                    phoneno:'',
                    address:'',
                    startDate:'',
                    clientID:'',
                    profile:'',
                    orgName:''
                });
                history.push("/");
              })        
            }catch(error){
            console.log("error ", error);
        }
    }

   /* componentDidMount () {
        const arrow = document.querySelectorAll(".arrow");
    
       
        for(var i=0; i < arrow.length; i++){
            arrow[i].addEventListener("click", (e)=>{
           
            let arrowParent = e.target.parentElement.parentElement; 
            
            arrowParent.classList.toggle("showMenu");
            });
        }
        let sidebar=document.querySelector(".sidebar");
        let sidebarBtn=document.querySelector(".fa-bars");
        
        sidebarBtn.addEventListener("click", ()=>{
            sidebar.classList.toggle("close");
        });
    }*/
    
        return (
            <div>
            <div className="sidebar">
               <div className="logo-details">
               
                       
                            <img src={logo1} style={{ width: "5rem", height: "4rem" }} alt="store" className="navbar-brand-ak" />
                     <Link to='/'> 
                    <span className="logo_name">Idntica</span>
                    </Link>
        </div> 
                <ul className="nav-links">
                   {/*} <li>
                        <Link to="#" onClick={(event)=>handleShow("QuickView", event)}>
                            <i className="fa fa-home"></i>
                            <span className="link_name">Dashboard</span>
                        </Link>
                        <ul className="sub-menu blank">
                            <li><Link className="link_name" to="#" onClick={(event)=>handleShow("QuickView", event)}>Dashboard</Link></li>
                        </ul> 
        </li>*/}
                    <li>
                    <Link to="#" onClick={(event)=>handleShow("QuickView", event)}>
                            <i className="fa fa-home"></i>
                            <span className="link_name">Dashboard</span>
                        </Link>
                        <ul className="sub-menu blank">
                            <Link to="#" className="link_name" onClick={(event)=>handleShow("QuickView", event)}>Dashboard</Link>

                        </ul> 
                    </li>
                    <li>
                        <div className="icon-link">
                        
                            <Link to="#">
                                <i className="fa fa-cloud"></i>
                            <span className="link_name">Cloud Hosting</span>
                           
                        </Link> 
                        <i className="fa fa-angle-down arrow"></i>
                        </div>
                        <ul className="sub-menu">
                            <li><Link className="link_name" to="#">Hosting</Link></li>
                            <Link to="#" onClick={(event)=>handleShow("WebAccounts", event)}>My Web</Link>
                           {/*} <Link to="/#" onClick={(event)=>handleShow("Transaction", event)}>My Transaction</Link>*/}
                            {/*
                            <li><a href="#">My Accounts</a></li>
                            <li><a href="#">Manage Accounts</a></li>
                            <li><a href="#">Edit Book</a></li>
                            <li><a href="#">Issue Book</a></li>
                            <li><a href="#">Return Book</a></li>*/}
                        </ul>
                        
                    </li>

                    <li>
                        <div className="icon-link">
                        
                            <Link to="#">
                                <i className="fa fa-code"></i>
                            <span className="link_name">Development</span>
                           
                        </Link> 
                        <i className="fa fa-angle-down arrow"></i>
                        </div>
                        <ul className="sub-menu">
                            <li><Link className="link_name" to="#">Development</Link></li>
                            <Link to="#" onClick={(event)=>handleShow("WebDev", event)}>My Sites</Link>
                            {/*<Link to="/#" onClick={(event)=>handleShow("Transaction", event)}>My Transaction</Link>*/}
                            {/*
                            <li><a href="#">My Accounts</a></li>
                            <li><a href="#">Manage Accounts</a></li>
                            <li><a href="#">Edit Book</a></li>
                            <li><a href="#">Issue Book</a></li>
                            <li><a href="#">Return Book</a></li>*/}
                        </ul>
                        
                    </li>

                    <li>
                        <div className="icon-link">
                        
                            <Link to="#">
                                <i className="fa fa-at"></i>
                            <span className="link_name">Email</span>
                           
                        </Link> 
                        <i className="fa fa-angle-down arrow"></i>
                        </div>
                        <ul className="sub-menu">
                            <li><Link className="link_name" to="#">Email</Link></li>
                            <Link to="#" onClick={(event)=>handleShow("EmailAcc", event)}>My Emails</Link>
                            {/*
                            <li><a href="#">My Accounts</a></li>
                            <li><a href="#">Manage Accounts</a></li>
                            <li><a href="#">Edit Book</a></li>
                            <li><a href="#">Issue Book</a></li>
                            <li><a href="#">Return Book</a></li>*/}
                        </ul>
                        
                    </li>


                    <li>
                    <Link to="#" onClick={(event)=>handleShow("Transaction", event)}>
                            <i className="fa fa-inr"></i>
                            <span className="link_name">My Payments</span>
                        </Link>
                        <ul className="sub-menu blank">
                            <Link to="#" onClick={(event)=>handleShow("Transaction", event)}>My Payments</Link>

                        </ul> 
                    </li>
                    

                    <li>
                        <div className="icon-link">
                        
                            <Link to="#">
                                <i className="fa fa-user"></i>
                            <span className="link_name">Account</span>
                            
                        </Link>
                        <i className="fa fa-angle-down arrow"></i>
                        </div>
                        <ul className="sub-menu">
                            <li><Link className="link_name" to="#">Account</Link></li>
                            <li><Link to="#" onClick={(event)=>handleShow("Profile", event)}>My Profile</Link></li>
                            <li><Link to="#" onClick={(event)=>handleShow("ChangePwd", event)}>Change Password</Link></li>
                            <li><Link to="#" onClick={(event)=>{handleSignOut(event)}}> Sign Out</Link></li>
                            {/*<li><a href="#">Pack</a></li>*/}
                        </ul>
                        
                    </li>
                    {/*<li>
                        <a href="#">
                            <i className="fa fa-pie-chart"></i>
                            <span className="link_name">Analytics</span>
                        </a>
                        <ul className="sub-menu blank">
                            <li><a className="link_name" href="#">Analytics</a></li>
                        </ul> 
                    </li>
                    <li>
                        <a href="#">
                            <i className="fa fa-line-chart"></i>
                            <span className="link_name">Chart</span>
                        </a>
                        <ul className="sub-menu blank">
                            <li><a className="link_name" href="#">Chart</a></li>
                        </ul> 
                    </li>*/}
                    {/*<li>
                        <div className="icon-link">
                        
                            <a href="#">
                                <i className="fa fa-plug"></i>
                            <span className="link_name">Plugins</span>
                            
                        </a>
                        <i className="fa fa-angle-down arrow"></i>
                        </div>
                        <ul className="sub-menu">
                            <li><a className="link_name" href="#">Plugins</a></li>
                            <li><a href="#">Test</a></li>
                            <li><a href="#">UI</a></li>
                            <li><a href="#">Padding</a></li>
                        </ul>
                        
                    </li>*/}
                    {/*<li>
                        <a href="#">
                            <i className="fa fa-compass"></i>
                            <span className="link_name">Explore</span>
                        </a>
                        <ul className="sub-menu blank">
                            <li><a className="link_name" href="#">Explore</a></li>
                        </ul> 
                    </li>*/}
                    {/*<li>
                        <a href="#">
                            <i className="fa fa-history"></i>
                            <span className="link_name">History</span>
                        </a>
                        <ul className="sub-menu blank">
                            <li><a className="link_name" href="#">History</a></li>
                        </ul> 
                    </li>*/}
                    {/*<li>
                        <a href="#">
                            <i className="fa fa-cog"></i>
                            <span className="link_name">Settings</span>
                        </a>
                        <ul className="sub-menu blank">
                            <li><a className="link_name" href="#">Settings</a></li>
                        </ul> 
                    </li>*/}
                <li>
                <div className="profile-details">
                    <div className="profile-content">
                      {/*  <img src={profile} alt="profile" /> */}
                    </div>
               
                    
                    <div className="name-job">
                        <div className="profile-name" style={{fontWeight:"500", fontFamily:"Cookie", fontSize:"28px"}}>
                            {loggedUser.username}
                        </div>
                     {/*   <div className="job">
                            {loggedUser.clientID}
                        </div>
                */}
                    </div> 
                    <i className="fa fa-sign-out" onClick={(event)=>{handleSignOut(event)}}></i> 
                     
                </div>
               </li> 
               </ul>
               
            </div>
            {/*Main View*/}
            <section className="home-section">
            <div className="home-content" style={{paddingTop:"100px"}}>
             {/*}   <i className="fa fa-bars"></i>
                <span className="text">Dashboard Main</span> */}
               
            {showQuickView ?
                            <div className="row">
                                <QuickView handleShow={handleShow}/>
                            </div> : <div></div>}
            {showWebHostAcc ?
                            <div className="row">
                                <MyWebHostingAcc handleShow={handleShow}/>
                            </div> : <div></div>}
            {showWebDev?<div className="row">
                                <MyWebDevAcc handleShow={handleShow}/>
                            </div> : <div></div>}
            {showEmailAcc?<div className="row">
                                <MyEmailAcc handleShow={handleShow}/>
                            </div> : <div></div>}
            {showProfile ?
                            <div className="row">
                                <MyProfile />
                            </div> : <div></div>}
            {showTransaction ?
                            <div className="row">
                                <MyTransaction />
                            </div> : <div></div>}
            {showChangePwd ?
                            <div className="row">
                                <ChangePassword />
                            </div> : <div></div>}
                            </div>
                        </section>
            </div>
        )
    
}
