import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from "aws-amplify";
import signInImg from '../../images/signin-image.jpg';
import Preloader from '../preloader/Preloader';
import { Alert } from 'reactstrap';
import { ProductContext } from '../../ProductProvider';
import {Helmet} from 'react-helmet-async';

export default function ForgotPassword() {
    //const [dispMsg, setDispMsg] = useState('');
    const { alertClose, alertDetails, setAlertDetails } = useContext(ProductContext)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');
    const [codeSent, setCodeSent] = useState(false);
    const [loaded, setLoaded] = useState(true);

    const handleForgotPassword = async(event) =>{
        event.preventDefault();
        try{
            if(email===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Enter Registered Email Address!"
                }); 
      
                //setDispMsg("Enter Registered Email Address!");
            }else{
                setLoaded(false);
            await Auth.forgotPassword(email)
            .then(()=>{
                setCodeSent(true);
                //setDispMsg("OTP has been sent to your registered Email address");
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message:"OTP has been sent to your registered Email address"
                }); 
                setLoaded(true);
            })
            .catch((error)=>{
                setLoaded(true);
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                }); 
                //setDispMsg(`Error Occured : ${error.message}`);
            });
        }
        }catch(error){
            setLoaded(true);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 

           // setDispMsg(`Error Occured : ${error.message}`);
        }
    }

    const handleVerifyForgotPassword = async(event) =>{
        event.preventDefault();
        try{
            if(email==="" || code==="" || password===""){
                //setDispMsg("Enter all the details!");
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Enter all the Details!"
                }); 

            }else{
            const username=email;
            const new_password=password;
            await Auth.forgotPasswordSubmit(username, code, new_password)
            .then(() => {
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message:"Password Changed Successfully"
                }); 
                //setDispMsg("Password Changed Successfully")
            })
            .catch(err => {
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + err.message
                }); 
                //setDispMsg(`Error Occured : ${err.message}`)
            });
        }
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 

            //setDispMsg(`Error Occured : ${error.message}`);
        }
    }

    return (
    <>
    <Helmet>
      <title>Forgot Password - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="Forgot Password - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="Forgot Password - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

        <div className="login">
            {!loaded?<Preloader />:null}
            <section  className="sign-in">
            <div className="container">
                <div className="signin-content">
                    <div className="signin-image">
                        <figure><img src={signInImg} alt="sign up"/></figure>
                        <Link to="/signup" className="signup-image-link">Create an account</Link>
                    </div>

                    <div className="signin-form">
                    <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
                    {alertDetails.message}
                    </Alert>

                        <h2 style={{ fontFamily: "Oswald"}} className="form-title">Forgot Password</h2>
                        <form className="register-form" id="login-form">
                        <div  style={{ fontFamily: "Oswald"}} className="form-group"> 
                            <div className="form-group">    
                                <label id="email"><i className="fa fa-envelope"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="email" name="email" id="email" placeholder="Your Email"
                                value={email}
                                onChange={(event)=>{setEmail(event.target.value)}}/>
                            </div>

                            {codeSent?<div>
                            <div className="form-group">
                                
                                <label id="code"><i className="fa fa-key"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="text" name="code" id="code" placeholder="Enter Code"
                                value={code}
                                onChange={(event)=>{setCode(event.target.value)}}/>
                            </div>
                            <div className="form-group">
                                
                                <label id="password"><i className="fa fa-key"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="password" name="password" id="password" placeholder="New Password"
                                value={password}
                                onChange={(event)=>{setPassword(event.target.value)}}/>
                            </div>
                            </div>:<div></div>}
                            

                            <Link to="/signin"><p> Click here to Sign In</p></Link>
                                   </div>
                           
                            {!codeSent?<button style={{ fontFamily: "Oswald"}} className="btn" onClick={(event)=>{handleForgotPassword(event)}}>Verify Email</button> 
                           
                            :<button style={{ fontFamily: "Oswald"}} className="btn" onClick={(event)=>{handleVerifyForgotPassword(event)}}>Reset Password</button> }  
                        </form>
                       </div>
                </div>
            </div>
        </section>
    </div>
    </>
    )

}