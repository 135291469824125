import React, { useContext } from "react";
import { ProductContext } from '../../ProductProvider';
import {Col,Row, Container, Table} from 'reactstrap';
//import { Auth } from "aws-amplify";
//import axios from "axios";
import 'balloon-css';

//import {Link} from "react-router-dom";
//import PayPalButton from "../payment/PayPalButton";

import { useHistory } from "react-router-dom";
//import Login from '../LoginOtp';
//import LoginEmail from '../testing/LoginEmail';
//import CartCheckout from './CartCheckout';
//const config = require('../../config.json');

export default function CartBillingDetails() {
    const { loggedUser } = useContext(ProductContext);
   const history = useHistory();
    // const {loggeduser} = name;
   
const handleReRoute=()=>{
history.push("/my-profile");
}

    return (
      <Container>
      <Row>
        <Col>
  <h6 className="text-title" style={{color:"black"}}> Billing and Payment <i className="fa fa-pencil" onClick={()=>{handleReRoute()}}></i></h6>
          <Table hover responsive>
               <thead>
                 <tr>
                 
                 </tr>
               </thead> 
               <tbody>
                 <tr>
               <th scope="row"> Org Name </th> 
               <td style={{textAlign:"right"}}>
                {loggedUser.orgName}
               </td> 
               </tr>
               <tr>
               <th scope="row"> Name </th> 
              
    <td style={{textAlign:"right"}}> {loggedUser.username}
    </td>
                          
                     
    </tr>
    <tr>
               <th>
                   Email:  
                   </th>
                   <td style={{textAlign:"right"}}>
                     {loggedUser.email}
                   </td>
               </tr>
               
               <tr>
               <th>
                   Phone: 
                   </th>
                   <td style={{textAlign:"right"}}>
                    {loggedUser.phoneno}
                   </td>
               </tr>
                 <tr>
                   <th>
                   Address:
                     </th>
               <td style={{textAlign:"right"}}>
               {loggedUser.address}
               </td>
               </tr>
               
                 </tbody> 
                
                 </Table>                
         

                 </Col>
            </Row>
        </Container>
    )
}
