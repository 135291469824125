import React, { useEffect, useState } from 'react';
//import Cookies from 'js-cookie';
import { Auth } from "aws-amplify";
import { useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';
import success from '../../images/success.gif';
import failure from '../../images/failure.gif';
import processing from '../../images/liquid-loading.gif';
import processingInvoice from '../../images/ProcessingInvoice.gif';
import axios from "axios";
import CartItemCheckout from '../checkout/CartItemCheckOut';
import InvoiceDownloader from './InvoiceDownloader';
import {Helmet} from 'react-helmet-async';

//import { faVihara } from '@fortawesome/free-solid-svg-icons';
//import ProcessingPopup from '../transaction/ProcessingPopup';
//import SuccessPopup from '../transaction/SuccessPopup';
//import FailurePopup from '../transaction/FailurePopup';
const config = require('../../config.json');

export default function OrderConfirmation() {
    let history = useHistory();

    //const {orderId} = useContext(ProductContext);
    const [newOrder, setNewOrder] = useState({clientId:'',clientPlan:'',domainId:'',orderAmount:'',orderId:'',paymentMode:'',pocEmail:'',pocName:'',pocPhone:'',referenceId:'',renewYears:'',transactionDate:'',txStatus:'', cart:[],invoiceCreated:'',emailSent:''});
    //const [orderId, setOrderId] = useState('');
    const [ispdfGenerated, setIspdfGenerated] = useState(false);
   

     useEffect(()=>{
        //const cookieOrderid = Cookies.get('orderid');
        var currentSearchString = window.location.search;
        var tempOrder = currentSearchString.substring(currentSearchString.search("=")+1,currentSearchString.length);
        //console.log("order id = ", tempOrder);
        //tempOrder="NA2110212317";
       // setOrderId(tempOrder);
        //fetchOrderDetails(cookieOrderid);
        //checkCFOrderStatus(tempOrder);
        fetchOrderDetails(tempOrder);
        
    },[])

   const generateInvoice = async(tempData) =>{
      //event.preventDefault();
      //console.log("generating pdf");
      try{
         //const pdfurl="https://appbhwubyc.execute-api.ap-south-1.amazonaws.com/v1/transaction/invoice";
         const session = await Auth.currentSession();
         //const username=session.idToken.payload.phone_number;
         //const clientId=username.substring(1, username.length);
         const access_token=session.idToken.jwtToken;

         const params = {
            "clientId": tempData.clientId,
            "orgName": tempData.orgName,
            "pocName": tempData.pocName,
            "pocAddress":tempData.pocAddress,
            "pocEmail": tempData.pocEmail,    
            "pocPhone": tempData.pocPhone,
            "orderId": tempData.orderId,
            "orderAmount": tempData.orderAmount,
            "paymentMode": tempData.paymentMode,
            "transactionDate" : tempData.transactionDate,
            "subTotal":tempData.subTotal,
            "gstCharges": tempData.gstCharges,
            "discountAmount":tempData.discountAmount,
            "cart":tempData.cart
          };
        //console.log("Params : ", params);
      await axios.post(`${config.api.invokeUrl}/transaction/invoice`, params, {
         headers: {
           Authorization: access_token,
           'x-api-key':config.api.key
         }
       }).then((response) => {
           //console.log("response : ", response);
           if(response.status===200 && response.headers['content-length']>10000){
              setIspdfGenerated(true);
              triggerEmailInvoice(tempData.clientId,tempData.orderId,tempData.pocName,tempData.pocEmail);
           }else{
            generateInvoice(tempData);
           }
        
       },(error) => {
          //setDispMsg("Error Occured : ", error.message);
          //console.log("Error Occured : ", error);
          generateInvoice(tempData);

       });
      
       } catch (err) {
        //setDispMsg("Error Occured : ", err.message);
        //console.log("Errors Occured : ", err);

     }  
   
   } 

   const triggerEmailInvoice =async(tempClientid, tempOrderid, pocName, pocEmail)=>{
      //setEmailMsg('');

      try {
         const session = await Auth.currentSession();
         //const clientId=session.idToken.payload.family_name;
         //const clientId=username.substring(1, username.length);
         const access_token=session.idToken.jwtToken;
        // console.log("POC Email : ", pocEmail);
        const params={
          clientid:tempClientid,
          orderid:tempOrderid,
          pocName:pocName,
          pocEmail:pocEmail
        }

         //await axios.get(`${config.api.invokeUrl}/transaction/email_invoice?orderId=${tempOrderid}&clientId=${tempClientid}&pocName=${pocName}&pocEmail=${pocEmail}`, {
          await axios.post(`${config.api.invokeUrl}/transaction/email_invoice`,params, { 
         headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          }).then(async(res) => {
          // console.log("response : ", res);
           const session = await Auth.currentSession();
           const access_token=session.idToken.jwtToken;
           const params={
            clientId: newOrder.clientId,
            orderno:newOrder.orderId,
            invoiceCreated:ispdfGenerated,
            emailSent:true
           }
           await axios.patch(`${config.api.invokeUrl}/transaction/cfresponse`, params, {
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          }).then(()=>{
          }).catch((err)=>{
          //  console.log("Update error : ", err);
          });
          }, (error) => {
           // console.log("email error : " , error);
          });
         
          } catch (err) {
        // console.log(`An error has occurred: ${err}`);
        }  
   }

   const checkCFOrderStatus = async(cookieOrderid)=>{
    try{
    const session = await Auth.currentSession();
    const clientid=session.idToken.payload.family_name;
    //console.log("client id : ", clientId);
//    console.log("Client id : ", clientId);
   // const username = cookieOrderid.substring(1,10);
    //const clientId=cookieOrderid.substring(0, 10);

    const access_token=session.idToken.jwtToken;
    const orderid = cookieOrderid.substring(clientid.length, cookieOrderid.length);
    await axios.get(`${config.api.invokeUrl}/transaction/cashfreev3/order-status/?orderid=${orderid}&clientid=${clientid}`, {
//    await axios.get(`https://sandbox.cashfree.com/pg/orders/${orderId}`, {
      headers: {
        Authorization: access_token,
        'x-api-key': config.api.key
    }
      }).then(async(response) => {
     //console.log("response : ", response);
     const params=response.data;
        await axios.post(`${config.api.invokeUrl}/transaction/cashfreev3/order-update`, params,{
          headers: {
            Authorization: access_token,
            'x-api-key':config.api.key
          }})
          .then((res)=>{
              //console.log("Success :", res);
              if(res.data.length>0)
              {
                 //console.log("inside new order");
                 //console.log("Cart : ", res.data[0].clientId);
                  setNewOrder({
                      ...newOrder,
                      clientId:res.data[0].clientId,
                      orderAmount:res.data[0].orderAmount,
                      orderId:res.data[0].orderId,
                      paymentMode:res.data[0].paymentMode,
                      pocEmail:res.data[0].pocEmail,
                      pocName:res.data[0].pocName,
                      pocPhone:res.data[0].pocPhone,
                      referenceId:res.data[0].referenceId,
                      transactionDate:res.data[0].transactionDate,
                      txStatus:res.data[0].txStatus,
                      cart:res.data[0].cart,
                      invoiceCreated:res.data[0].invoiceCreated
                      
                  });
                //  console.log("invoice creaed : ", response.data[0].invoiceCreated);
                  if(res.data[0].txStatus==="SUCCESS"&&res.data[0].invoiceCreated===undefined){
                  // console.log("generating invoice");    
                    generateInvoice(res.data[0]);
                  }
           }else{
              history.push("/");
           }
          })
          .catch((error)=>{
            history.push("/");
          })
    })
    .catch((error)=>{
      history.push("/");

    })

    }catch(error){
      history.push("/");
    }

   }


   const checkOrderStatus = async(cookieOrderid)=>{
    try{
    const session = await Auth.currentSession();
    const clientId=session.idToken.payload.family_name;
    //console.log("client id : ", clientId);
//    console.log("Client id : ", clientId);
   // const username = cookieOrderid.substring(1,10);
    //const clientId=cookieOrderid.substring(0, 10);

    const access_token=session.idToken.jwtToken;
    const orderId = cookieOrderid.substring(clientId.length, cookieOrderid.length);
    const app_id=config.CashFreeConfig.appID;
    const secrect_key=config.CashFreeConfig.secrectKey;
    await axios.get(`https://sandbox.cashfree.com/pg/orders/${orderId}`, {
      headers: {
        accept: 'application/json',
        'x-api-version': '2022-09-01',
        'x-client-id': app_id,
        'x-client-secret': secrect_key
    }
      }).then(async(response) => {
     console.log("response : ", response);
    })
    .catch((error)=>{
      console.log("Error1 : ", error);

    })

    }catch(error){
      console.log("Error : ", error);
    }

   }

    const fetchOrderDetails=async(cookieOrderid)=>{
            try {
             const session = await Auth.currentSession();
             const clientId=session.idToken.payload.family_name;
             //console.log("client id : ", clientId);
         //    console.log("Client id : ", clientId);
            // const username = cookieOrderid.substring(1,10);
             //const clientId=cookieOrderid.substring(0, 10);
             const access_token=session.idToken.jwtToken;
             const orderId = cookieOrderid.substring(clientId.length, cookieOrderid.length);
            //console.log("order Id : ", orderId);
            //console.log("Client Id : ", clientId);
             await axios.get(`${config.api.invokeUrl}/client/accounts/transactions?orderId=${orderId}&clientId=${clientId}`, {
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }
              }).then(async(response) => {
               console.log("response : ", response);
                if(response.data.length>0)
                {
               //    console.log("inside new order");
               if(response.data[0].txStatus==="Payment Pending"){
                setTimeout(() => { fetchOrderDetails(cookieOrderid); }, 3000);
                //fetchOrderDetails(cookieOrderid);
               }else{
                   console.log("Cart : ", response.data[0].cart);
                    setNewOrder({
                        ...newOrder,
                        clientId:response.data[0].clientId,
                        orderAmount:response.data[0].orderAmount,
                        orderId:response.data[0].orderId,
                        paymentMode:response.data[0].paymentMode,
                        pocEmail:response.data[0].pocEmail,
                        pocName:response.data[0].pocName,
                        pocPhone:response.data[0].pocPhone,
                        referenceId:response.data[0].referenceId,
                        transactionDate:response.data[0].transactionDate,
                        txStatus:response.data[0].txStatus,
                        cart:response.data[0].cart,
                        invoiceCreated:response.data[0].invoiceCreated
                        
                    });

                  //  console.log("invoice creaed : ", response.data[0].invoiceCreated);
                    if(response.data[0].txStatus==="SUCCESS"&&response.data[0].invoiceCreated===undefined){
                    //  console.log("generating invoice");    
                      generateInvoice(response.data[0]);
                    }
                  }
             }else{
                history.push("/");
             }
              }).catch((error)=>{
                console.log("Error:", error);
                history.push("/");
              });
            
              } catch (err) {
                console.log("Error1:", err);
                history.push("/");
            }  
          

    }


    return (
      <>
      <Helmet>
      <title>Order Confirmation - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="Order Confirmation - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="Order Confirmation - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

        <div className="container" style={{marginTop:"120px", padding:"20px"}}>
           <h1 style={{textAlign:"center"}}> ORDER {newOrder.txStatus}</h1>
              <div className="text-center">
                 {newOrder.txStatus==="SUCCESS" ? 
               <img  className="rounded img-fluid" src={success} alt="success" style={{textAlign:"center", width:"160px"}}/>
           :newOrder.txStatus==="CANCELLED"||newOrder.txStatus==="FAILED"?
           <img  className="rounded img-fluid" src={failure}  alt="failed" style={{textAlign:"center", width:"160px"}}/>:
           <img  className="rounded img-fluid" src={processing} alt="processing" style={{textAlign:"center", width:"160px"}}/>}
           </div>
    
             <Table hover responsive bordered>
              <thead>
                <tr>
                <th>Order Id: {newOrder.orderId}</th>
                <th>Payment Mode: {newOrder.paymentMode} </th>
                <th>  {ispdfGenerated && newOrder.txStatus==="SUCCESS" ? <InvoiceDownloader propsFile={newOrder} />  
                : newOrder.txStatus==="CANCELLED"?<div>Order Cancelled</div>:newOrder.txStatus==="FAILED"?<div>Order Cancelled</div>:<div>Generating Invoice<img  className="rounded img-fluid" src={processingInvoice}  alt="processing" style={{textAlign:"center", width:"80px"}}/></div>}   </th>
                </tr>
                <tr>
                  <th>Order Amount: {newOrder.orderAmount}</th>
                  <th>Transaction Status: {newOrder.txStatus} </th>
                  <th>Date: {newOrder.transactionDate}</th>
                  </tr>
                  <tr>
                  <th>Phone: {newOrder.pocPhone}</th>
                  <th>Email: {newOrder.pocEmail}</th>
                  <th>Transaction Id: {newOrder.referenceId}</th>
                  
                </tr>
              </thead>      
           </Table>

       
        {newOrder.cart===undefined?<div>No Cart Items Found</div>:newOrder.cart.length>0?
        <Table hover responsive>
                    <thead>
                      <tr>
                        <th >
                          Account Id
                        </th>
                        <th >
                          Package Name
                        </th>
                        <th >
                            Quantity
                        </th>
                        <th style={{textAlign:"right"}}>
                          Total
                        </th>
                        
                      </tr>
                    </thead> 
                    <tbody>
               {newOrder.cart.map(item =>{
                   return (
                   
                    <CartItemCheckout key={item.cartno} item={item} />
                   
                    )
                   
               })}
               
                </tbody>
            </Table>:<div>No Cart Items</div>}
        </div>
      </>
    )
}
