import React, { useContext, useState } from 'react';
import './bottombar.css';
import { ProductContext } from '../../ProductProvider';

import { Link, useHistory } from "react-router-dom";

export default function Bottombar() {
   
    let history = useHistory();
    const { loggedUser} = useContext(ProductContext);
    const [activeTab, setActiveTab] = useState('');
    
    

    const handleShow = (value) => {
        if(loggedUser.isAuthenticated){
            if (value === "Transaction") {
                    setActiveTab('Transaction');
                    history.push('/my-transaction');
                } else if (value==="QuickView") {
                    setActiveTab('QuickView');
                    history.push('/dashboard');
                } else if (value==="QuickProfile") {
                    setActiveTab('QuickProfile');
                    history.push('/profile-view');
                }
            }else{
                history.push("/signin");
            }
    }
 
   
   {/*} const [activeTabs, setActiveTabs] = useState('home');
    useEffect(() => {
        console.log("Active tab value : ", activeTabs);
        switch (activeTabs) {
            case 'home':
                history.push('/dashboard')
                break;
            case 'search':
                history.push('/my-transaction')
                break;
            case 'favourites':
                history.push('/favourites')
                break;
            case 'account':
                history.push('/my-transaction')
                break;
            default:
                history.push('/dashboard')
                break;
        }
    }, [activeTabs, history])*/}
    
        return (
        <div>

        {/*<section >
            <div  style={{paddingTop:"50px"}}>
          
               
            {bottomValue==="QuickView"  ?
                            <div >
                              
                                <QuickView />
                            </div> : bottomValue==="QuickProfile" ?
                            <div >
                                <QuickProfile />
                            </div> : bottomValue==="Transaction" ?
                            <div >
                                <MyTransaction />
                            </div> : showChangePwd ?
                            <div className="row">
                                <ChangePassword />
            </div> : <div></div>}
                            </div>
        </section>*/}
     {/*}                  <div className='bottom-nav'>
            <div className='bn-tab'>
                {activeTabs === 'home' ?
                    <RiHomeSmile2Fill
                        size='35'
                        color='#000'
                        onClick={() => {setActiveTabs('home');history.push("/dashboard")}}
                    /> :
                    <RiHomeSmile2Line
                        size='35'
                        color='#000'
                        onClick={() => setActiveTabs('home')}
                    />}
            </div>
            <div className='bn-tab'>
                {activeTabs === 'search' ?
                    <RiSearchEyeFill
                        size='35'
                        color='#000'
                        onClick={() => setActiveTabs('search')}
                    /> :
                    <BiSearchAlt
                        size='35'
                        color='#000'
                        onClick={() => setActiveTabs('search')}
                    />}
            </div>
            <div className='bn-tab'>
                {activeTabs === 'favourites' ?
                    <AiFillHeart
                        size='35'
                        color='#000'
                        onClick={() => setActiveTabs('favourites')}
                    /> :
                    <AiOutlineHeart
                        size='35'
                        color='#000'
                        onClick={() => setActiveTabs('favourites')}
                    />}
            </div>
            <div className='bn-tab'>
                {activeTabs === 'account' ?
                    <RiUser5Fill
                        size='35'
                        color='#000'
                        onClick={() => setActiveTabs('account')}
                    /> :
                    <RiUser5Line
                        size='35'
                        color='#000'
                        onClick={() => setActiveTabs('account')}
                    />}
            </div>
            </div>  */}
         <div className="fixed-bottom">
                <div className="content-view d-flex" style={{bottom: "0", backgroundColor:"white", height:"55px", padding:"8px", fontSize:"24px"}}>
                   
                    <div className="justify-content-center" style={{width:"35%", textAlign:"center"}}>
                    <Link to="#"  onClick={()=>{handleShow("QuickProfile")}}>
                               {activeTab==="QuickProfile"? <i style={{color:"green", fontSize:"30px"}} className="fa fa-user" ></i>
                               :<i className="fa fa-user" ></i>}
                            <span className="link_name"></span>
                            
                        </Link>
                   
                    
                    </div>
                    <div className="justify-content-center" style={{width:"35%", textAlign:"center"}}>
                    <Link to="#"  onClick={()=>handleShow("QuickView")}>
                           {activeTab==="QuickView"? <i style={{color:"green", fontSize:"30px"}} className="fa fa-home"></i>
                           :<i className="fa fa-home"></i>}
                            <span className="link_name"></span>
                        </Link>
                       
                        </div>
                    <div className="justify-content-center" style={{width:"35%", textAlign:"center"}}>
                    <Link to="#" onClick={()=>handleShow("Transaction")}>
                            {activeTab==="Transaction"?<i style={{color:"green", fontSize:"30px"}} className="fa fa-inr"></i>
                            :<i className="fa fa-inr"></i>}
                            <span className="link_name"></span>
                        </Link>
                        </div>
                </div>
              
            
                
            </div>
            </div>
        )
    
}
