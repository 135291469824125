import React, { useState, useEffect, useContext } from 'react';
//import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import { Auth } from "aws-amplify";
import PaymentCF from '../payment/PaymentCF';
import {Alert, Container, Row, Col, Table} from 'reactstrap';
//import CartListCheckOut from '../checkout/CartListCheckOut';
import CartItemCheckout from '../checkout/CartItemCheckOut';
//import CartTotalCheckOut from '../checkout/CartTotalCheckOut';
import { useHistory } from 'react-router-dom';
//import CartBillingDetails from '../checkout/CartBillingDetails';
//import PayTM from '../payment/PayTM';
//import PayTMJS from '../payment/paytm/PayTMJS';
import Preloader from '../preloader/Preloader';
//import { PaytmButton } from '../payment/PayTMButton';
//import PayTMElements from '../payment/paytm/PayTMElements';
//import { PayTMTesting } from '../payment/paytm/PayTMTesting';
import pciDssImage from '../../images/payment/pci-dss.jpeg';
import payOptionImage from '../../images/payment/payOptions.png';
import {Helmet} from 'react-helmet-async';
import Cashfree from '../payment/paymentcf/Cashfree';
import InitiatePhonePe from '../payment/phonepe/InitiatePhonePe';

const config = require("../../config");
const https = require("https");
//const qs = require("querystring");
var localMID="";
//var localKey="";
var localWebsite="";
//var localActionURL="";
var localHostname="";
var amountAfterDiscount="";
//var returnURL="";
const returnURL=process.env.REACT_APP_CF_RETURN_URL;

export default function OrderPreview() {
    let history=useHistory();
    const { orderId, orderRefId, transactionDate, cart, cartTotal, loggedUser, cartTax, cartSubTotal, 
        discountAmount, discountValue, discountCode, alertDetails, setAlertDetails,alertClose, 
        } = useContext(ProductContext);
    const [loaded, setLoaded]=useState(false);
    const [sessionId, setSessionId] = useState('');

    
    useEffect(() => {
        if(cart.length<1){
            history.push('/cart');
        }else{
            setLoaded(false);
            amountAfterDiscount=(parseFloat(cartSubTotal)-parseFloat(discountAmount)).toFixed(2);
            //console.log("Amoutn : ", amountAfterDiscount);
            if(process.env.NODE_ENV==="development"||process.env.NODE_ENV==="test"){
                //console.log("Dev Environment");
                localMID=config.PaytmConfig.mid;
                //localKey=config.PaytmConfig.key;
                //localActionURL=config.PaytmConfig.actionURL;
                localWebsite=config.PaytmConfig.website;
                localHostname="securegw-stage.paytm.in";
                //returnURL=config.PaytmConfig.returnURL;

            }else{
                //console.log("Prod Environment");
                localMID=config.PaytmProdConfig.mid;
                //localKey=config.PaytmProdConfig.key;
                //localActionURL=config.PaytmProdConfig.actionURL;
                localWebsite=config.PaytmProdConfig.website;
                localHostname="securegw.paytm.in";
                //returnURL=config.PaytmProdConfig.returnURL;

            }
            setLoaded(true);
            getCashFreeSessionID();
           //paytmInitialize();  
        }
    }, [])

    const getCashFreeSessionID = async () => {
        // props.setDispMsg('');
        //e.preventDefault();
        setLoaded(false);
         //console.log("add to database");
         if(!cartTotal || !loggedUser.clientID || !loggedUser.email || !loggedUser.phoneno) {
            //  console.log('Payment failed');
            //props.setDispMsg('Payment cannot be processed!');
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Payment cannot be processed! Try Again!!"
          });  
          setLoaded(true);

          } else {
  
         try {
                 const session = await Auth.currentSession();
                 const access_token=session.idToken.jwtToken;
                // await handleAddNewTransaction();
                 const orderid=orderId;
                 const params = {
                     "clientId": loggedUser.clientID,
                     "orgName": loggedUser.orgName,
                     "pocName": loggedUser.username,
                     "pocEmail": loggedUser.email,    
                     "pocPhone": loggedUser.phoneno,
                     "pocAddress":loggedUser.address,
                     "orderId": orderid,
                     "orderRefId": orderRefId,
                     "orderAmount": cartTotal,
                     "referenceId":"NA",
                     "txStatus": "NA",
                     "paymentMode": "NA",
                     "txMsg": "NA",
                     "txTime": "NA",
                     "transactionDate" : transactionDate,
                     "gstCharges": cartTax,
                     "discountAmount":discountAmount,
                     "discountValue":discountValue,
                     "discountCode":discountCode,
                     "subTotal":cartSubTotal,
                     "cart":cart
                 };
                 //console.log("DB Values : ", params);
             await axios.post(`${config.api.invokeUrl}/transaction/cashfreev3/create-order`, params,{
             headers: {
               Authorization: access_token,
               'x-api-key':config.api.key
             }})
             .then((res)=>{
                 //console.log("Success : ", res);
                 setSessionId(res.data.payment_session_id);
                 setLoaded(true);

             })
             .catch((error)=>{
                //console.log("Error : ", error);
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Payment cannot be processed! Try Again!!"
                });        
                setLoaded(true);
                // props.setDispMsg(error.message);
             })
         
         }catch (err) {
            //console.log("Error1 : ", err);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Payment cannot be processed! Try Again!!"
            });    
            setLoaded(true);
          // props.setDispMsg(err.message);
         }
         setLoaded(true);
       }
    }
 

    /*const paytmInitialize=async()=>{
        setLoaded(false);
        if(!cartTotal || !loggedUser.clientID || !loggedUser.email || !loggedUser.phoneno) {
          //  console.log('Payment failed');
          //props.setDispMsg('Payment cannot be processed!');
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Payment cannot be processed! Try Again!!"
        });  
        } else {
            try{
            await handleAddNewTransaction();
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;    
            const tempAmount=cartTotal.toString();
            var paytmParams = {};
            paytmParams.body = {
                "requestType"   : "Payment",
                "mid"           : localMID,
                "websiteName"   : localWebsite,
                "orderId"       : orderRefId,
                "callbackUrl"   : returnURL,
                "txnAmount"     : {
                    "value"     : tempAmount,
                    "currency"  : "INR",
                },
                "userInfo"      : {
                    "custId"    : loggedUser.clientID,
                    "mobile"    : loggedUser.phoneno,
                    "email"     : loggedUser.email,
                },
            };
            console.log("Params :", paytmParams);
            //generateSignature(params, localKey).then(function(checksum){
            await axios.post(`${config.api.invokeUrl}/transaction/paytm/initiate`, paytmParams.body,{
                headers: {
                    Authorization: access_token,
                    'x-api-key':config.api.key
                }})
                .then(async(res)=>{
                    const checksum=res.data;
                    //console.log("checksum :", checksum);
                    //PaytmChecksum.generateSignature(JSON.stringify(paytmParams.body), "YOUR_MERCHANT_KEY").then(function(checksum){
                    paytmParams.head = {
                        "signature"    : checksum
                    };
                    var post_data = JSON.stringify(paytmParams);
                    var options = {
                    // for Staging 
                    //hostname: 'securegw-stage.paytm.in',
                    // for Production 
                     hostname: localHostname,
                    port: 443,
                    path: `/theia/api/v1/initiateTransaction?mid=${localMID}&orderId=${orderRefId}`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Content-Length': post_data.length
                        }
                    };
            
                    console.log("Options : ", options);
                var response = "";
                var post_req = https.request(options, function(post_res) {
                    post_res.on('data', function (chunk) {
                        response += chunk;
                    });
            
                    post_res.on('end', async function (){
                        console.log('Response: ', response);
                        const {body}=JSON.parse(response);
                        console.log('Response: ', body.txnToken);
                       // console.log("Token : ", response['body']['txnToken']);
                       setPayTMTokenRes(body.txnToken);                   
                       setLoaded(true);
                       //setPayTMLoaded(true);
                    });
                });
            
                post_req.write(post_data);
                await post_req.end();
                
                //console.log("loaded");

            });
            //setLoaded(true);
        }catch(error){
            setLoaded(true);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            });      
        }
    }
    }*/

    const handleAddNewTransaction = async () => {
        //props.setDispMsg('');
       // console.log("add to database");
        // add call to AWS API Gateway add product endpoint here
        try {
                const session = await Auth.currentSession();
                const access_token=session.idToken.jwtToken;
                const orderid=orderId;
                const params = {
                    "clientId": loggedUser.clientID,
                    "orgName": loggedUser.orgName,
                    "pocName": loggedUser.username,
                    "pocEmail": loggedUser.email,    
                    "pocPhone": loggedUser.phoneno,
                    "pocAddress":loggedUser.address,
                    "orderId": orderid,
                    "orderRefId": orderRefId,
                    "orderAmount": cartTotal,
                    "referenceId":"NA",
                    "txStatus": "NA",
                    "paymentMode": "NA",
                    "txMsg": "NA",
                    "txTime": "NA",
                    "transactionDate" : transactionDate,
                    "gstCharges": cartTax,
                    "discountAmount":discountAmount,
                    "discountValue":discountValue,
                    "discountCode":discountCode,
                    "subTotal":cartSubTotal,
                    "cart":cart
                };
                //console.log("DB Values : ", params);
            await axios.post(`${config.api.invokeUrl}/client/accounts/transactions/${orderid}`, params,{
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }})
            .then(()=>{
              //  console.log("Success");
            })
            .catch((error)=>{
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                });                  
            })
        }catch (err) {
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + err.message
            });     
        }
    }

    return (
    <>
    <Helmet>
      <title>Order Preview - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="Order Preview - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="Order Preview - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

    {!loaded?<Preloader /> : null}
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose}>
        {alertDetails.message}
        </Alert>

        <div className="main-signin" style={{paddingTop:"50px"}}>
     {/*   <div style={{paddingBottom:"75px"}}>
            
  <h2 className="text-title" style={{color:"black", paddingTop:"120px"}}> Finalize your Order</h2>

        <div className="navbar-bg" >
            <div style={{margin:"auto",display:"flex",  justifyContent:"center"}}>
                 <Col sm={8}>
             

           <CartListCheckOut />
           
           </Col>
           
                        <Col sm={4}>  
          
           <CartTotalCheckOut />
           <PaymentCF setDispMsg={setDispMsg}/>
           
            </Col> 
         
            </div>
          
           </div>
           <div className="navbar-mobile" >
           <Col sm={8}>
             

           <CartListCheckOut />
           
           </Col>
           
                        <Col sm={4}>  
        <CartBillingDetails />
           <CartTotalCheckOut />
           <PaymentCF setDispMsg={setDispMsg}/>
           
            </Col> 
         
           </div>
            </div>
     */}
       
            <div className="container-fluid" style={{paddingBottom:'50px'}}>
                <div className="scroll-div" id="style-1">
                <div className="row">
                <div className="col-12 col-md-12 col-sm-12" >
                <React.Fragment>
                <div className="cart-title-dis">
                <div className="cart-value-div" id="style-1">
                </div>
                <Container>
                <h2 className="text-title" style={{color:"black", paddingTop:"40px"}}>Order Preview</h2>
               {/*} <h6 className="text-title" style={{color:"black"}}> Billing and Payment </h6>*/}
                    <div>
                    <Row xs="2">
                        <Container>
                    <Col>
                    <Row>
                        <Col>
                        <Table hover responsive>
                            <thead>
                                <tr>
                                
                                </tr>
                            </thead> 
                            <tbody>
                                <tr>
                            <th scope="row"> Org Name </th> 
                            <td style={{textAlign:"right"}}>
                                {loggedUser.orgName}
                            </td> 
                            </tr>
                            <tr>
                            <th scope="row"> Name </th> 
                            
                            <td style={{textAlign:"right"}}> {loggedUser.username}
                            </td>
                                                
                                            
                            </tr>
                            <tr>
                            <th>
                                Email:  
                                </th>
                                <td style={{textAlign:"right"}}>
                                    {loggedUser.email}
                                </td>
                            </tr>
                            
                            <tr>
                            <th>
                                Phone: 
                                </th>
                                <td style={{textAlign:"right"}}>
                                    {loggedUser.phoneno}
                                </td>
                            </tr>
                                <tr>
                                <th>
                                Address:
                                    </th>
                            <td style={{textAlign:"right"}}>
                            {loggedUser.address}
                            </td>
                            </tr>
                            
                                </tbody> 
                                
                                </Table>                
                        

                                </Col>
                    </Row>
                    <Row>
                    <Col>
                    <Table hover responsive>
                        <thead>
                        <tr>
                            <th >
                            Account Id
                            </th>
                            <th >
                            Package Name
                            </th>
                            <th >
                                Quantity
                            </th>
                            <th style={{textAlign:"right"}}>
                            Total
                            </th>
                            
                        </tr>
                        </thead> 
                        <tbody>
                {cart.map(item =>{
                    return (
                    
                        <CartItemCheckout key={item.cartno} item={item} />
                    
                        )
                    
                })}
                
                    </tbody>
                    </Table>
        
    </Col>
                    </Row>
                    <Row>
                    <Col>
                    <Table hover responsive>
                        <thead>
                            <tr>
                            
                            
                            </tr>
                        </thead> 
                        <tbody>
                            <tr>
                        <th > Sub Total </th> 
                        <td style={{textAlign:"right"}}>
                        <i className="fa fa-rupee"></i> {cartSubTotal}
                        </td> 
                        </tr>
                        <tr>
                        <th>
                            Discount  
                            </th>
                            <th style={{textAlign:"right"}}>
                            <i className="fa fa-rupee"></i>  {discountAmount}
                            </th>
                        </tr>
                        <tr>
                        <th > Sub Total After Discount</th> 
                        <td style={{textAlign:"right"}}>
                        <i className="fa fa-rupee"></i> {amountAfterDiscount}
                        </td> 
                        </tr>

                        <tr>
                        <th>
                            GST - 18% 
                            </th>
                            <th style={{textAlign:"right"}}>
                            <i className="fa fa-rupee"></i>  {cartTax}
                            </th>
                        </tr>
                            <tr>
                            <th>
                            Amount Payable
                                </th>
                        <th style={{textAlign:"right"}}>
                        <i className="fa fa-rupee"></i> {cartTotal}
                        </th>
                        </tr>
                            </tbody> 
                    </Table>
                    </Col>
                    </Row>
                    </Col>
                    </Container>
                    <Container>
                    <Col>
                    <div><h2 className="text-title" style={{color:"black", paddingTop:"40px"}}> Choose your Preferred Payment Partner</h2>
            </div>

                   {/*} <>
                    {payTMTokenRes?<PayTMTesting />:<>Initializing</>}
                        <div id="card"></div>
                        <div id="nb"></div>
            </>*/}
                    {loaded?<div style={{textAlign:"center"}}>  
                   {/*} <div style={{marginBottom:"20px"}}><PaymentCF/></div>*/}
                    <div style={{marginBottom:"20px"}}><Cashfree sessionId={sessionId} /></div>
                    <div style={{marginBottom:"20px"}}><InitiatePhonePe /></div>

                    {/*<div style={{marginBottom:"20px"}}> <PayTMJS/></div>*/}
                        </div>:null}
                        <div className="p-2" style={{marginTop:"68px"}}><img src={payOptionImage} /></div>
                        <div className="p-2" style={{marginTop:"8px"}}><img src={pciDssImage} /></div>        
                    </Col>
                    </Container>
                    </Row>
                    </div>
            <div className="d-flex justify-content-center">  {/*<PaymentCF setDispMsg={setDispMsg}/>*/} 
            {/*loaded?<PayTMJS />:null*/}
            </div>
            </Container>
           {/*} <PaytmButton />*/}
        </div>
    </React.Fragment>                
    </div>
    </div>
    </div>
    </div>
    </div>   
    </>
    )
}
