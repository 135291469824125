import React from "react";
import axios from 'axios';
//import Header from "components/Header";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { Auth } from "aws-amplify";

import useFileDownloader from "./useFileDownloader";
//import ExternalInfo from "components/ExternalInfo";
const config = require('../../config.json');

/*const files = [
  {
    name: "Photo 1",
    file:
      "https://images.unsplash.com/photo-1604263439201-171fb8c0fddc?rnd=" +
      Math.random(),
    filename: "photo-1.jpg",
  }
];*/

const InvoiceDownloader = (props) => {
    const {propsFile} = props;
  const [downloadFile, downloaderComponentUI] = useFileDownloader();

  const download = async() => {
      try{
        const fetchFile=propsFile.orderId+".pdf";
        const bucketName=`idnticauseraccountspdfinvoicesourcebucket/pdfs/invoice/${propsFile.clientId}`;
        /*const params={
            fileName:fetchFile,
            bucketName:`idnticauseraccountspdfinvoicesourcebucket/pdfs/invoice/${propsFile.clientId}/${propsFile.domainId}`
        };*/
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        await axios.get(`${config.api.invokeUrl}/presigned/downloader?fileName=${fetchFile}&bucketName=${bucketName}`,{
          headers: {
            Authorization: access_token,
            'x-api-key': config.api.key
          }}, 
       ).then(async(response)=>{
            //console.log("Response : ", response);
            const file ={
                name:propsFile.orderId,
                file:response.data.downloadURL,
                filename:fetchFile
            };
            //console.log("url file : ", file);
            downloadFile(file);
        }).catch((error)=>{
            //console.log("Error : ", error);
            //setDispMsg("Error Occured - ", error.message);
        })        
      }catch(error){
          //console.log("Error : ", error);
      }
  }

  return (
    <>
      
        <div className="col text-center">
               Invoice  <span>  </span><span>  </span>
                    <a
                      className="btn btn-primary cursor-pointer text-white"
                      onClick={() => {download()}}
                    >
                       <i className="fa fa-download"></i>
                    </a>
                
        </div>
        
        {downloaderComponentUI}
    </>
  );
};

export default InvoiceDownloader;