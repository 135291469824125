import React, { useState, useContext, useEffect } from 'react';
import { Button, Col } from 'reactstrap';
import axios from "axios";
import { Auth } from "aws-amplify";
import cashfreelogo from '../../images/logos/cashfree.png';
import { ProductContext } from '../../ProductProvider';
const config = require('../../config.json');
require('dotenv').config();


//const testAppId = '843003ed2ae7b7e010d36325800348';
//const testSecretKey = '1f434d123efd3fd407c2e98d251a48c35673a865';
var ksort = require('json-ksort')
var crypto = require('crypto');
var appId="";
var sKey="";
var actionURL="";
//const returnURL = "https://appbhwubyc.execute-api.ap-south-1.amazonaws.com/v1/transaction/cfresponse";



export default function PaymentCF(props) {
    //const { amount, pocName, pocEmail, pocPhone, clientId, domainName, clientPlan, renewYears, gstCharges, planCharges, transactionCharges, renewalTo, renewalFrom, clientPlanDetails, pocAddress } = props;
    const { orderId, orderRefId, transactionDate, cart, cartTotal, loggedUser, cartTax, cartSubTotal, discountAmount, discountValue, discountCode, generatedSignature, createSignature} = useContext(ProductContext);
   // const [generatedSignature, setGeneratedSignature] = useState('');
    const [phoneNo, setPhoneNo] = useState(0);
    
    const returnURL=process.env.REACT_APP_CF_RETURN_URL;

    useEffect(() => {
        //console.log("Process ENV: ", process.env);
        if(process.env.NODE_ENV==="development"||process.env.NODE_ENV==="test"){
            appId=process.env.REACT_APP_CF_TEST_APPID;
            sKey=process.env.REACT_APP_CF_TEST_SKEY;
            actionURL="https://test.cashfree.com/billpay/checkout/post/submit";
        }else{
            appId=process.env.REACT_APP_CF_APPID;
            sKey=process.env.REACT_APP_CF_SKEY;
            actionURL="https://www.cashfree.com/checkout/post/submit";
        }
        const temp=loggedUser.phoneno.substring(3, loggedUser.phoneno.length);
        setPhoneNo(parseFloat(temp));      
        createSignature(temp, cartTotal); 
        //console.log("id and key : ", appId, sKey);
    }, [])

   /* const processRedirect = () => {
        console.log("form");
        var form = document.createElement('form');
        document.body.appendChild(form);
        form.method = 'post';
        //form.action = "https://test.cashfree.com/billpay/checkout/post/submit";
        form.action={actionURL};
        form.id = 'myForm';
        form.submit();
    }*/

    /*const createSignature = async (tPhone) => {
       // console.log("creating signature");
        var postData = {
            'appId': appId,
            'orderId': orderRefId,
            'orderAmount': parseFloat(cartTotal),
            'customerName': loggedUser.username,
            'customerEmail': loggedUser.email,
            'customerPhone': tPhone,
            'orderCurrency': "INR",
            'orderNote': loggedUser.clientID,
            'returnUrl': returnURL
        }
       // console.log("Signature data : ", postData);
        var sortedKeys = await ksort(postData);
        var signatureData = "";
        for (let key in sortedKeys) {
            signatureData += key + postData[key];
        }
        let token = crypto.createHmac("sha256", sKey).update(signatureData).digest().toString('base64');
        setGeneratedSignature(token);
    }   */
   
    const handleAddNewTransaction = async () => {
       // props.setDispMsg('');
       // console.log("add to database");
        // add call to AWS API Gateway add product endpoint here
        try {
                const session = await Auth.currentSession();
                const access_token=session.idToken.jwtToken;
                const orderid=orderId;
                const params = {
                    "clientId": loggedUser.clientID,
                    "orgName": loggedUser.orgName,
                    "pocName": loggedUser.username,
                    "pocEmail": loggedUser.email,    
                    "pocPhone": loggedUser.phoneno,
                    "pocAddress":loggedUser.address,
                    "orderId": orderid,
                    "orderRefId": orderRefId,
                    "orderAmount": cartTotal,
                    "referenceId":"NA",
                    "txStatus": "NA",
                    "paymentMode": "NA",
                    "txMsg": "NA",
                    "txTime": "NA",
                    "transactionDate" : transactionDate,
                    "gstCharges": cartTax,
                    "discountAmount":discountAmount,
                    "discountValue":discountValue,
                    "discountCode":discountCode,
                    "subTotal":cartSubTotal,
                    "cart":cart
                };
              //  console.log("DB Values : ", params);
            await axios.post(`${config.api.invokeUrl}/client/accounts/transactions/${orderid}`, params,{
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }})
            .then(()=>{
              //  console.log("Success");
            })
            .catch((error)=>{
               // props.setDispMsg(error.message);
            })
        
        }catch (err) {
         // props.setDispMsg(err.message);

        }
      }

    const handlePay = async () => {
        await handleAddNewTransaction();    
    }
    return (
        <div>
        <form id="myForm" method="post" action={actionURL}>
            {/*<form id="myForm">*/}
                <input type="hidden" name="appId" value={appId} />
                <input type="hidden" name="orderId" value={orderRefId} />
                <input type="hidden" name="orderAmount" value={parseFloat(cartTotal)} />
                <input type="hidden" name="orderCurrency" value="INR" />
                <input type="hidden" name="orderNote" value={loggedUser.clientID}/>
                <input type="hidden" name="customerName" value={loggedUser.username} />
                <input type="hidden" name="customerEmail" value={loggedUser.email} />
                <input type="hidden" name="customerPhone" value={phoneNo} />
                <input type="hidden" name="returnUrl" value={returnURL} />
                <input type="hidden" name="signature" value={generatedSignature} />
                    <Col sm={{ size: 12 }}>
                        <Button type="submit" className="btn pull-center" style={{backgroundColor:"transparent", border:"none", borderRadius:"10px"}} onClick={handlePay}>
                            <img src={cashfreelogo} height="30" width="100" style={{borderRadius:"10px", height:"70px"}}/></Button>
                    </Col>
        </form>
        </div>
            

    )
}
