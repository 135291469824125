import { createChatBotMessage } from "react-chatbot-kit";
import Overview from "./widgets/Overview";
import GlobalStatistics from "./widgets/GlobalStatistics";
import LocalStatistics from "./widgets/LocalStatistics";
import Contact from "./widgets/Contact";
import MedicineDelivery from "./widgets/MedicineDelivery";
import CoBotAvatar from "./CoBotAvatar";
import './ChatStyle.css';
import HostingPlans from "./widgets/HostingPlans";
import DevelopmentPlans from "./widgets/DevelopmentPlans";
import EmailPlans from "./widgets/EmailPlans";

const ChatbotConfig = {
  lang: "no",
  botName: "JoeLee",
  customStyles: {
    botMessageBox: {
      backgroundColor: "#04668a"
    },
    chatButton: {
      backgroundColor: "#0f5faf"
    }
  },
  initialMessages: [
    createChatBotMessage(
      `Hi! My name is JoeLee, Welcome to Idntica. I can help you with your queries!`
    ),
    createChatBotMessage(
      "Here's a quick overview of what I can help you with. You can also type in.",
      {
        withAvatar: false,
        delay: 400,
        widget: "overview"
      }
    )
  ],
  state: {},
  customComponents: { botAvatar: (props) => <CoBotAvatar {...props} /> },
  widgets: [
    {
      widgetName: "overview",
      widgetFunc: (props) => <Overview {...props} />,
      mapStateToProps: ["messages"]
    },
    {
      widgetName: "hostingPlans",
      widgetFunc: (props) => <HostingPlans />
    },
    {
      widgetName: "developmentPlans",
      widgetFunc: (props) => <DevelopmentPlans />
    },
    {
      widgetName: "emailPlans",
      widgetFunc: (props) => <EmailPlans />
    },
    {
      widgetName: "globalStatistics",
      widgetFunc: (props) => <GlobalStatistics />
    },
    {
      widgetName: "localStatistics",
      widgetFunc: (props) => <LocalStatistics />
    },
    {
      widgetName: "emergencyContact",
      widgetFunc: (props) => <Contact />
    },
    {
      widgetName: "medicineDelivery",
      widgetFunc: (props) => <MedicineDelivery />
    }
  ]
};

export default ChatbotConfig;
