import React, { useEffect, useState, useContext } from 'react';
//import axios from 'axios';
//import {cashfree} from './util';
import {load} from '@cashfreepayments/cashfree-js';
import { Button, Col } from 'reactstrap';
import axios from "axios";

import cashfreelogo from '../../../images/logos/cashfree.png';
//import cashfreeimg from '../../../images/logos/cashfree.png';
//import { useParams } from 'react-router-dom';
import { ProductContext } from '../../../ProductProvider';
const config = require('../../../config.json');
//const returnURL=process.env.REACT_APP_CFv3_RETURN_URL;
//const returnURL=config.CashFreeConfig.returnURL;

const Cashfree = (props) => {
    //const params = useParams()
    //const isSessionId = params.sessionid
    const {sessionId} = props;
    //const [loading, setLoading] = useState('');
    //const [sessionId, setSessionId] = useState('');
    //let version = cashfree.version();
    const { orderRefId, alertDetails, setAlertDetails,alertClose } = useContext(ProductContext);

    
   /* const getSessionId = (e)=>{
        e.preventDefault();
        setLoading(true);
        axios.post('api/payment', {version})
        .then((res)=>{
            setLoading(false);
            setSessionId(res.data);
            // window.open(res.data, '_blank');
        })
        .catch((err)=>{
            setLoading(false);
            console.log(err);
        })
    }*/    

    const handlePayment = async()=>{
        const cashfree = await load({
            mode: "production" //or production sandbox
        });
       // test -> returnUrl:`http://localhost:3001/orderconfirmation?orderId=${orderRefId}`


        let checkoutOptions = {
            paymentSessionId: sessionId,
            returnUrl: `${config.CashFreeConfig.returnURL}=${orderRefId}`,
        }   
        cashfree.checkout(checkoutOptions).then(async function (result){
            const params={
                transactionId:orderRefId,
                result:result
            };

            await axios.post(`${config.api.invokeUrl}/transaction/cfresponse`, params,{
                headers: {
                  //Authorization: access_token,
                  'x-api-key':config.api.key
                }})
                .then((res)=>{
                    console.log("Success : ", res);
                    //setPhonePeRes(res.data);
                    //setLoaded(true);
                    //window.location.href=res.data.data.instrumentResponse.redirectInfo.url;
   
                })
                .catch((error)=>{
                   console.log("Error : ", error);
                   setAlertDetails({
                       ...alertDetails,
                       style:"warning",
                       open:true,
                       message:"Payment cannot be processed! Try Again!!"
                   });        
                   //setLoaded(true);
                   // props.setDispMsg(error.message);
                })

            //alert(result);
            if(result.error){
               // alert(result.error.message);
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Payment cannot be processed!" + result.error.message
                });        
            }
            if(result.redirect){
                console.log("Redirection")
               // console.log(result);
            }
        });
    }

    useEffect(async()=>{
        //console.log("Session ID : ", `${config.CashFreeConfig.returnURL}=${orderRefId}`);
        //console.log("Return URL : ", `${config.CashFreeConfig.returnURL}=${orderId}`);
        alertClose();
        //setSessionId(isSessionId);
        //console.log("session id : ", isSessionId);
        //let version = cashfree.version();
        //getSessionID();
    }, [sessionId])

    /*const getSessionID = async (e) => {
        // props.setDispMsg('');
        e.preventDefault();
         console.log("add to database");
         // add call to AWS API Gateway add product endpoint here
         try {
                 //const session = await Auth.currentSession();
                 //const access_token=session.idToken.jwtToken;
                 const orderid=orderId;
                 const params = {
                     "clientId": loggedUser.clientID,
                     "orgName": loggedUser.orgName,
                     "pocName": loggedUser.username,
                     "pocEmail": loggedUser.email,    
                     "pocPhone": loggedUser.phoneno,
                     //"pocAddress":loggedUser.address,
                     "orderId": orderid,
                     "orderRefId": orderRefId,
                     "orderAmount": cartTotal,
                     "referenceId":"NA",
                     "txStatus": "NA",
                     "paymentMode": "NA",
                     "txMsg": "NA",
                     "txTime": "NA",
                     "transactionDate" : transactionDate,
                     "gstCharges": cartTax,
                     "discountAmount":discountAmount,
                     "discountValue":discountValue,
                     "discountCode":discountCode,
                     "subTotal":cartSubTotal,
                     "cart":cart
                 };
                 console.log("DB Values : ", params);
             await axios.post(`${config.api.invokeUrl}/transaction/cashfreev3/create-order`, params,{
             headers: {
               //Authorization: access_token,
               'x-api-key':config.api.key
             }})
             .then((res)=>{
                 console.log("Success : ", res);
                 setSessionId(res.data.payment_session_id);
             })
             .catch((error)=>{
                console.log("Error : ", error);
                // props.setDispMsg(error.message);
             })
         
         }catch (err) {
            console.log("Error1 : ", err);

          // props.setDispMsg(err.message);
 
         }
       }*/
 

  return (
    <>
    <div>
    <Col sm={{ size: 12 }}>
            <Button type="submit" className="btn pull-center" style={{backgroundColor:"transparent", border:"none", borderRadius:"10px"}} onClick={handlePayment}>
                <img src={cashfreelogo} height="30" width="100" style={{borderRadius:"10px", height:"70px"}}/></Button>
        </Col>

    </div>
    </>
  )
}

export default Cashfree