import React, { useContext, useState,useEffect } from 'react';
import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import axios from "axios";
import { Auth } from "aws-amplify";
import { ProductContext } from '../../ProductProvider';
import { useHistory } from 'react-router-dom';
//import Tooltip from '../../index';
import 'balloon-css';
import { phoneCode } from '../../packageDetails';
import useFullPageLoader from "../hooks/useFullPageLoader";
import ProfileImage from '../profile/ProfileImage';
import ProgressBarStatus from '../profile/ProgressBarStatus';
import {Helmet} from 'react-helmet-async';

const config = require('../../config.json');

export default function MyProfile() {
    let history=useHistory();
    const {loggedUser, setLoggedUser} =useContext(ProductContext);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [editEnabled, setEditEnabled]=useState(false);
    const [editAddress, setEditAddress] = useState(true);
    const [address, setAddress] = useState(loggedUser.address);
    const [editOrg, setEditOrg] = useState(true);
    const [editGst, setEditGst] = useState(true);
    const [editName, setEditName]=useState(true);
    const [name, setName] = useState(loggedUser.username);
    const [editPhone, setEditPhone]=useState(true);
    const [phoneNo, setPhoneNo] = useState(loggedUser.phoneno)
    const [countryCode, setCountryCode]=useState("+91");
    const [orgName, setOrgName] = useState(loggedUser.orgName);
    //const [gstNo, setGstNo] = useState(loggedUser.gstNo)
    const [email, setEmail] = useState(loggedUser.email);
    const [code, setCode] =useState('');
    const [otpsent, setotpSent] = useState(false);
    const [editEmail, setEditEmail] = useState(true);
    const [dispMsg, setDispMsg] = useState('');
    const [dispOtpMsg, setDispOtpMsg] = useState('');
    const [bannerMsg, setBannerMsg] = useState('');
    const [profileImage, setProfileImage] = useState('defaultAvatar.jpg');
    const [progressValue, setProgressValue]=useState(50);

    useEffect(() => {
       // validateEmailVerified();
        window.scrollTo(0,0);
        const tImage=loggedUser.clientID+".jpeg";
        setProfileImage(tImage);      
        processProgressBarUpdate();
        setEditEnabled(false);
        
    }, []);

    const processProgressBarUpdate = ()=>{
        var arr=[];
            arr=loggedUser;
            //console.log("array : ", arr);
            //let result = !Object.values(arr).every(o => o === "NA");
            //let res1= Object.keys(arr).filter(key => (arr[key] === "NA"||arr[key]===""));
            var pro=0;
            for (var key in arr) {
               // console.log("key : ", arr[address]);
              if ((key==="username"||key==="phoneno"||key==="email"||key==="address"||key==="orgName")&&(arr[key] === "NA" || arr[key] === ""||arr[key]===null||arr[key]===undefined))
                  pro++;
          }
          const tpvalue=(((6-pro)/6)*100).toFixed();
        //console.log("pvalue", tpvalue);
          setProgressValue(tpvalue);
          
      }

    const handleReRoute=()=>{
        history.push("/signin");
    }

   /* const validateEmailVerified = async()=>{
        setDispMsg('');
        try{
            const user=await Auth.currentAuthenticatedUser();
            if(!user.attributes.email_verified){
                setBannerMsg("Email address Verification is Pending!");
            }

        }catch(error){
            //console.log("Error : ", error);
            setDispMsg("Error Fetching!", error );
        }
    }*/

    const handleAddressEdit =async(event)=>{
        event.preventDefault();
        if(editEnabled){
            setDispMsg("Save the Opened Item First");
        }else{
            setEditEnabled(true);
            setEditAddress(false);
            setDispMsg('');
        }
    }

    const handleAddressUpdate =async(event)=>{
        event.preventDefault();
        setDispMsg('');
        showLoader();
        try{
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, { address: address })
        .then(async(res)=>{
            const params={
                address:address,
                clientID:loggedUser.clientID,
                category:"Address"
            };
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;          
            await axios.patch(`${config.api.invokeUrl}/client/accounts/profile`, params, {
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }
              }).then((res)=>{
                setLoggedUser({
                    ...loggedUser,
                    address:address
                  });
                setEditAddress(true);
                setEditEnabled(false);
                setDispMsg("Address Updated Successfully!");
            
              }).catch((err)=>{
                setDispMsg(`Error Occured : ${err.message}`);
              })
            })
        .catch((err)=>{
            setDispMsg(`Error Occured : ${err.message}`);
        })
       
        }catch(error){
            setDispMsg(`Error Occured : ${error.message}`);
        }
        hideLoader();
    }

    const handlePhoneEdit =async(event)=>{
        event.preventDefault();
        if(editEnabled){
            setDispMsg("Save the Opened Item First");
        }else{
            setEditEnabled(true);
            setEditPhone(false);
            setDispMsg('');
        }
    }

    const handlePhoneUpdate =async(event)=>{
        event.preventDefault();
        setDispMsg('');
        showLoader();
        try{
            const tempPhone = countryCode.toString()+phoneNo.toString();
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, { phone_number: tempPhone })
        .then(async(res)=>{
            const params={
                phone:tempPhone,
                clientID:loggedUser.clientID,
                category:"Phone"
            };
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;          
            await axios.patch(`${config.api.invokeUrl}/client/accounts/profile`, params, {
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }
              }).then((res)=>{
                  const temppno=tempPhone.substring(0,tempPhone.length-1);
                setLoggedUser({
                    ...loggedUser,
                    phoneno:temppno
                  });
                  setPhoneNo(temppno);
                  setEditEnabled(false);
                setEditPhone(true);
                setDispMsg("Phone No Updated Successfully!");    
            
              }).catch((err)=>{
                setDispMsg(`Error Occured : ${err.message}`);
              })
            })
        .catch((err)=>{
            setDispMsg(`Error Occured : ${err.message}`);
        })
        }catch(error){
            setDispMsg(`Error Occured : ${error.message}`);
        }
        hideLoader();
    }

    const handleNameEdit =async(event)=>{
        event.preventDefault();
        if(editEnabled){
            setDispMsg("Save the Opened Item First");
        }else{
            setEditName(false);
            setEditEnabled(true);
            setDispMsg('');
        }
    }

    const handleNameUpdate =async(event)=>{
        event.preventDefault();
        setDispMsg('');
        showLoader();
        try{
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, { name: name })
        .then(async(res)=>{
            const params={
                name:name,
                clientID:loggedUser.clientID,
                category:"Name"
            };
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;          
            await axios.patch(`${config.api.invokeUrl}/client/accounts/profile`, params, {
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }
              }).then((res)=>{
                setLoggedUser({
                    ...loggedUser,
                    username:name
                  });
                setEditName(true);
                setEditEnabled(false);
                setDispMsg("Name Updated Successfully!");    
            
              }).catch((err)=>{
                setDispMsg(`Error Occured : ${err.message}`);
              })
            })
        .catch((err)=>{
            setDispMsg(`Error Occured : ${err.message}`);
        })
        }catch(error){
            setDispMsg(`Error Occured : ${error.message}`);
        }
        hideLoader();
    }


    const handleOrgEdit =async(event)=>{
        event.preventDefault();
        if(editEnabled){
            setDispMsg("Save the Opened Item First");
        }else{
            setEditEnabled(true);
            setEditOrg(false);
            setDispMsg('');
        }
    }

    const handleOrgUpdate =async(event)=>{
        event.preventDefault();
        setDispMsg('');
        showLoader();
        try{
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, { given_name: orgName })
        .then(async(res)=>{
            const params={
                orgName:orgName,
                clientID:loggedUser.clientID,
                category:"OrgName"
            };
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;          
            await axios.patch(`${config.api.invokeUrl}/client/accounts/profile`, params, {
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }
              }).then((res)=>{
                setLoggedUser({
                    ...loggedUser,
                    orgName:orgName
                  });
                setEditOrg(true);
                setEditEnabled(false);
                setDispMsg("Organisation Name Updated Successfully!");    
            
              }).catch((err)=>{
                setDispMsg(`Error Occured : ${err.message}`);
              })
            })
        .catch((err)=>{
            setDispMsg(`Error Occured : ${err.message}`);
        })
        }catch(error){
            setDispMsg(`Error Occured : ${error.message}`);
        }
        hideLoader();
    }

    const handleGstEdit =async(event)=>{
        event.preventDefault();
        if(editEnabled){
            setDispMsg("Save the Opened Item First");
        }else{
            setEditEnabled(true);
            setEditGst(false);
            setDispMsg('');
        }
    }

    const handleGstUpdate =async(event)=>{
        event.preventDefault();
        setDispMsg('');
        showLoader();
        try{
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, { gender: loggedUser.gstNo })
        .then(async(res)=>{
            const params={
                gstNo:loggedUser.gstNo,
                clientID:loggedUser.clientID,
                category:"GST"
            };
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;          
            await axios.patch(`${config.api.invokeUrl}/client/accounts/profile`, params, {
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }
              }).then((res)=>{
                setEditGst(true);
                setEditEnabled(false);
                setDispMsg("GST No Updated Successfully!");    
            
              }).catch((err)=>{
                setDispMsg(`Error Occured : ${err.message}`);
              })
            })
        .catch((err)=>{
            setDispMsg(`Error Occured : ${err.message}`);
        })
        }catch(error){
            setDispMsg(`Error Occured : ${error.message}`);
        }
        hideLoader();
    }

    const cancelUpdate = (event)=>{
        event.preventDefault();
        setEditAddress(true);
        setEditEmail(true);
        setEditGst(true);
        setEditName(true);
        setEditOrg(true);
        setEditPhone(true);
        setEditEnabled(false);
        setDispMsg('');
        
    }

    const handleEmailEdit =async(event)=>{
        event.preventDefault(); 
        if(editEnabled){
            setDispMsg("Save the Opened Item First");
        }else{
            setEditEnabled(true);
            setEditEmail(false);
            setDispMsg('');
            setDispOtpMsg('');
        }
    }

    const handleEmailUpdate =async(event)=>{
        event.preventDefault();
        setDispMsg('');
        setDispOtpMsg('');
        showLoader();
        try{
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, { email: email })
        .then((res)=>{
            setDispOtpMsg("Enter the OTP sent to New Email address!");
            setEditEmail(true);
            setotpSent(true);    

        }).catch((err)=>{
            setDispMsg(`Error Occured : ${err.message}`);
        })
        }catch(error){
        }
        hideLoader();
    }

    const handleOTPValidation = async(event) =>{
        event.preventDefault();
        setDispOtpMsg('');
        setDispMsg('');
        showLoader();
        try{
            await Auth.verifyCurrentUserAttributeSubmit("email", code)
            .then(async(res)=>{
                const params={
                    email:email,
                    clientID:loggedUser.clientID,
                    category:"Email"
                };
                const session = await Auth.currentSession();
                const access_token=session.idToken.jwtToken;          
                await axios.patch(`${config.api.invokeUrl}/client/accounts/profile`, params, {
                    headers: {
                      Authorization: access_token,
                      'x-api-key':config.api.key
                    }
                  }).then((res)=>{
                    setLoggedUser({
                        ...loggedUser,
                        email:email
                      });
                      setotpSent(false);
                      setEditEnabled(false);
                      setDispMsg("Email address successfully Verified and Updated!");
                      setBannerMsg('');
      
                    }).catch((err)=>{
                        setDispMsg(`Error Occured : ${err.message}`);
                    })
                    }).catch((err)=>{
                        setDispMsg(`Error Occured : ${err.message}`);

            });
            
        }catch(error){
            setDispMsg(`Error Occured : ${error.message}`);
        }
        hideLoader();
    }

    const handleResendOTP = async(event)=>{
        event.preventDefault();
       // console.log("resending");
        setDispMsg('');
        try{
        await Auth.verifyCurrentUserAttribute("email");
        setDispOtpMsg("OTP Re-sent to New Email address!");
        }catch(error){
            setDispMsg(`Error Occured : ${error.message}`);
        }
    }


    /*const handleAttributesUpdate = async(event)=>{
        event.preventDefault();
        setDispMsg('');
        showLoader();
        try{
            if(editOrg && editAddress){
            const session = await Auth.currentSession();
            const access_token = session.idToken.jwtToken;
            //console.log("Access token : ", access_token);
            const clientid = loggedUser.clientID;
            //const clientid=tempClientid.substring(1, tempClientid.length);
            const params = {
                "address":address,
                "orgName":orgName,
                "loginid":loggedUser.email
            }
            await axios.post(`${config.api.invokeUrl}/client/accounts/profile`, params,{
                headers: {
                    Authorization: access_token,
                    'x-api-key': config.api.key
                }
            })
                .then((response) => {
                    if(response.status===202){
                        setLoggedUser({
                            ...loggedUser,
                            address:address,
                            orgName:orgName
                          });
                        setDispMsg("Profile Successfully Updated");
                    }
                })
                .catch((error) => {
                    setDispMsg("Error Occured - " + error.message);
                })
            }


        }catch(error){
            setDispMsg("Error Occured : ", error.message);
        }
        hideLoader();

    }*/

    return (
    <>
        <Helmet>
        <title>My Profile - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
        <meta name="description" content="My Profile - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
        <meta name="keywords" content="My Profile - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
      </Helmet>

            <div className="container" style={{paddingTop:"60px"}}>
                {loggedUser.isAuthenticated?
            <div>
                        <ProfileImage fileName={loggedUser.clientID} setProfileImage={setProfileImage} profileImage={profileImage} />
                        <div className=' flex-fill'>  <ProgressBarStatus  progressValue={progressValue} dispValue="Profile"/> </div>

                {/*<h4 className="text-title"><i className="fa fa-user fa-fw" aria-hidden="true"></i>&nbsp; My Profile </h4>*/}
                <div style={{textAlign:"center"}}> {loader} </div>
                <h4 style={{textAlign:"center", color:"red"}}>{bannerMsg}</h4>
                <div className="navbar-bg">
                    <Row>
                    <Col xl={12} lg={12} md={12}>
                        <Card>
                            <CardBody>
                                <form>

                                <div className="row">
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Label for="accessionNoL">Name </Label>
                                                </div>
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Input
                                                        type="text"
                                                        name="accessionNo"
                                                        value={name}
                                                        onChange={(event)=>{setName(event.target.value)}}
                                                        disabled = {editName}
                                                    />
                                                </div>{editName?<balloon aria-label="Edit Name" data-balloon-pos="up"><i className="fa fa-pencil" onClick={(event)=>{handleNameEdit(event)}}></i></balloon>:
                                                <div><balloon aria-label="Save Name!" data-balloon-pos="up"><i className="fa fa-check" onClick={(event)=>{handleNameUpdate(event)}}></i></balloon>
                                                <balloon aria-label="Cancel" data-balloon-pos="up"><i className="fa fa-close" onClick={(event)=>{cancelUpdate(event)}}></i></balloon></div>}

                                        </div>

                                            <div className="row">
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Label for="accessionNoL">Organisation Name </Label>
                                                </div>
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Input
                                                        type="text"
                                                        name="accessionNo"
                                                        value={orgName}
                                                        onChange={(event)=>{setOrgName(event.target.value)}}
                                                        disabled = {editOrg}
                                                    />
                                                </div>{editOrg?<balloon aria-label="Edit Organisation Name" data-balloon-pos="up"><i className="fa fa-pencil" onClick={(event)=>{handleOrgEdit(event)}}></i></balloon>:
                                                <div><balloon aria-label="Save Organisation Name!" data-balloon-pos="up"><i className="fa fa-check" onClick={(event)=>{handleOrgUpdate(event)}}></i></balloon>
                                                <balloon aria-label="Cancel" data-balloon-pos="up"><i className="fa fa-close" onClick={(event)=>{cancelUpdate(event)}}></i></balloon></div>}

                                            </div>

                                            <div className="row">
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Label for="accessionNoL">GST No </Label>
                                                </div>
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Input
                                                        type="text"
                                                        name="gstNo"
                                                        value={loggedUser.gstNo}
                                                        onChange={(event)=>{setLoggedUser({...loggedUser,
                                                            gstNo:event.target.value})}}
                                                        disabled = {editGst}
                                                    />
                                                </div>{editGst?<balloon aria-label="Edit GST No" data-balloon-pos="up"><i className="fa fa-pencil" onClick={(event)=>{handleGstEdit(event)}}></i></balloon>:
                                                <div><balloon aria-label="Save GST No!" data-balloon-pos="up"><i className="fa fa-check" onClick={(event)=>{handleGstUpdate(event)}}></i></balloon>
                                                <balloon aria-label="Cancel" data-balloon-pos="up"><i className="fa fa-close" onClick={(event)=>{cancelUpdate(event)}}></i></balloon></div>}
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Label for="accessionNoL">Phone No</Label>
                                                </div>
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                   {editPhone? <Input
                                                        type="text"
                                                        name="accessionNo"
                                                        value={phoneNo}
                                                        disabled={editPhone}
                                                    />: <div className="form-group"> 
                                
                                                    {/*   <label for="name"><i className="zmdi zmdi-account material-icons-name"></i>Phone number will be your login ID</label>   */}
                                              
                                                       <span className="input-group-text" style={{background: "none", fontFamily: "Oswald", border: "none", padding: "0 0 0 0"}}>
                                                       
                                                       
                                                       <label id="email"><i className="fa fa-phone"></i>
                                                       <select  style={{fontFamily: "Oswald", border:"none", padding: "0px",  appearance:"none",
                                                       cursor: "pointer"}} value={countryCode} onChange={(event)=>{setCountryCode(event.target.value)}}>
                                                        
                                                          
                                                           {phoneCode.map(item => {
                                                               return (<option key={item.Country} value={item.Code}>{item.Code}</option>);
                                                           })}
                                                             </select>
                                                             
                                                       </label>
                       
                                                             
                                                       <input style={{ fontFamily: "Oswald"}} type="number" aria-describedby="phoneHelp" className="name" id="phoneno" placeholder="Enter Phone No" value={phoneNo}
                                                       onChange={(event)=>{setPhoneNo(event.target.value)}}/></span>
                                                       </div>}
                                                </div>{editPhone?<balloon aria-label="Edit Phone No" data-balloon-pos="up"><i className="fa fa-pencil" onClick={(event)=>{handlePhoneEdit(event)}}></i></balloon>:
                                                <div><balloon aria-label="Save Phone No!" data-balloon-pos="up"><i className="fa fa-check" onClick={(event)=>{handlePhoneUpdate(event)}}></i></balloon>
                                                <balloon aria-label="Cancel" data-balloon-pos="up"><i className="fa fa-close" onClick={(event)=>{cancelUpdate(event)}}></i></balloon></div>}

                                            
                                            </div>

                                            <div className="row">


                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Label for="bookTitleL">Email Address</Label>
                                                </div>
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Input
                                                        type="text"
                                                        name="booktitle"
                                                        value={email}
                                                        disabled = {editEmail}
                                                        onChange={(event)=>{setEmail(event.target.value)}}

                                                    />
                                                </div>{editEmail?<balloon aria-label="Edit Email Address!" data-balloon-pos="up">
                                                <i className="fa fa-pencil" onClick={(event)=>{handleEmailEdit(event)}}></i></balloon>:
                <balloon aria-label="Save Email Address!" data-balloon-pos="up"><i className="fa fa-check" onClick={(event)=>{handleEmailUpdate(event)}}></i></balloon>}
                                               </div>
                                       <div style={{textAlign:"center"}}> {dispOtpMsg} </div>
                                               
                                            {otpsent?
                                            <div className="row">
                                            <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Label for="bookTitleL">Enter OTP</Label>
                                                </div>
                                                <div className="col-md-4 col-sm-4  col-xs-4">

                                                <Input
                                                        type="text"
                                                        name="code"
                                                        value={code}
                                                        disabled = {!otpsent}
                                                        onChange={(event)=>{setCode(event.target.value)}}
                                                        placeholder="Enter OTP"
                                                    /></div>
                                                    <balloon aria-label="Validate OTP!" data-balloon-pos="up"><i className="fa fa-check" onClick={(event)=>{handleOTPValidation(event)}}></i></balloon>
                                                    <span></span>
                                                    <balloon aria-label="Re-Send OTP!" data-balloon-pos="up"><i className="fa fa-repeat" onClick={(event)=>{handleResendOTP(event)}}></i></balloon>
                                                    </div>:<div></div>}
                                           
                                            <div className="row">
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Label for="bookAuthor">Account Created On</Label>
                                                </div>
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Input
                                                        type="text"
                                                        name="AuthorFN"
                                                        value={loggedUser.startDate}
                                                        disabled
                                                    />

                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Label for="accessionNoL">Address </Label>
                                                </div>
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Input
                                                        type="text"
                                                        name="address"
                                                        value={address}
                                                        onChange={(event)=>{setAddress(event.target.value)}}
                                                        disabled = {editAddress}
                                                    />  

                                                </div>{editAddress?<balloon aria-label="Edit Address!" data-balloon-pos="up"><i className="fa fa-pencil" onClick={(event)=>{handleAddressEdit(event)}}></i></balloon>:
                                                <div><balloon aria-label="Save Address!" data-balloon-pos="up"><i className="fa fa-check" onClick={(event)=>{handleAddressUpdate(event)}}></i></balloon>
                                                <balloon aria-label="Cancel" data-balloon-pos="up"><i className="fa fa-close" onClick={(event)=>{cancelUpdate(event)}}></i></balloon></div>}

                                            </div>
                                       <div style={{textAlign:"center"}}> {dispMsg} </div>

                                       {/*} <div className="container">
                                            <div style={{textAlign:"center"}}>

                                    <button onClick={(event)=>{handleAttributesUpdate(event)}}>Update</button>
                                    </div>
    </div>*/}
                                       
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row> 
                </div>
                <div className="navbar-mobile container">
               
                                <form>

                                <div >
                                          
                                                
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Input
                                                        type="text"
                                                        name="accessionNo"
                                                        value={name}
                                                        disabled={editName}
                                                        placeholder="Edit Name"
                                                        onChange={(event)=>{setName(event.target.value)}}

                                                    /> <label className="errspan"> 
                                                    {editName?<balloon aria-label="Edit Name" data-balloon-pos="up"><i className="fa fa-pencil" onClick={(event)=>{handleNameEdit(event)}}></i></balloon>:
                                                    <div><balloon aria-label="Save Name!" data-balloon-pos="up"><i className="fa fa-check" onClick={(event)=>{handleNameUpdate(event)}}></i></balloon>
                                                    <balloon aria-label="Cancel" data-balloon-pos="up"><i className="fa fa-close" onClick={(event)=>{cancelUpdate(event)}}></i></balloon></div>}

                                                    </label>

                                                </div>
                                            
                                        </div>

                                        <div >
                                            
                                               
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Input
                                                        type="text"
                                                        name="accessionNo"
                                                        value={orgName}
                                                        onChange={(event)=>{setOrgName(event.target.value)}}
                                                        disabled = {editOrg}
                                                        placeholder="Edit Organisation Name"
                                                    /> 
                                                    <label className="errspan"> 
                                                {editOrg?<balloon aria-label="Edit Organisation Name" data-balloon-pos="up"><i className="fa fa-pencil" onClick={(event)=>{handleOrgEdit(event)}}></i></balloon>:
                                                <div><balloon aria-label="Save Organisation Name!" data-balloon-pos="up"><i className="fa fa-check" onClick={(event)=>{handleOrgUpdate(event)}}></i></balloon>
                                                <balloon aria-label="Cancel" data-balloon-pos="up"><i className="fa fa-close" onClick={(event)=>{cancelUpdate(event)}}></i></balloon></div>}

                                                    </label>
                                                </div>
                                               
                                            
                                            
                                        </div>



                                        <div >
                                            <div >
                                               
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                {editPhone? <Input
                                                        type="text"
                                                        name="accessionNo"
                                                        value={phoneNo}
                                                        disabled={editPhone}
                                                    />: <div className="form-group"> 
                                
                                              
                                                       <span className="input-group-text" style={{background: "none", fontFamily: "Oswald", border: "none", padding: "0 0 0 0"}}>
                                                       
                                                       
                                                       <label id="email">
                                                       <select  style={{fontFamily: "Oswald", border:"none", padding: "0px",  appearance:"none",
                                                       cursor: "pointer"}} value={countryCode} onChange={(event)=>{setCountryCode(event.target.value)}}>
                                                        
                                                          
                                                           {phoneCode.map(item => {
                                                               return (<option key={item.Country} value={item.Code}>{item.Code}</option>);
                                                           })}
                                                             </select>
                                                             
                                                       </label>
                       
                                                             
                                                       <input style={{ fontFamily: "Oswald"}} type="number" aria-describedby="phoneHelp" className="name" id="phoneno" placeholder="Enter Phone No" value={phoneNo}
                                                       onChange={(event)=>{setPhoneNo(event.target.value)}}/></span>
                                                       <label className="errspan"> {editPhone?<balloon aria-label="Edit Phone No" data-balloon-pos="up"><i className="fa fa-pencil" onClick={(event)=>{handlePhoneEdit(event)}}></i></balloon>:
                                                <div><balloon aria-label="Save Phone No!" data-balloon-pos="up"><i className="fa fa-check" onClick={(event)=>{handlePhoneUpdate(event)}}></i></balloon>
                                                <balloon aria-label="Cancel" data-balloon-pos="up"><i className="fa fa-close" onClick={(event)=>{cancelUpdate(event)}}></i></balloon></div>}

                                                </label>
                                              
                                                       </div>}
                                                  </div>
                                            </div>
                                        </div>

                                        <div >
                                          


                                               
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    
                                                     <Input
                                                        type="text"
                                                        name="booktitle"
                                                        value={email}
                                                        disabled = {editEmail}
                                                        onChange={(event)=>{setEmail(event.target.value)}}

                                                    /> <label className="errspan"> 
                                                    {editEmail?<balloon aria-label="Edit Email Address!" data-balloon-pos="up">
                                                <i className="fa fa-pencil" onClick={(event)=>{handleEmailEdit(event)}}></i>
                                                </balloon>
                                                :
                                                <div><balloon aria-label="Save Email Address!" data-balloon-pos="up">
                                                    <i className="fa fa-check" onClick={(event)=>{handleEmailUpdate(event)}}></i></balloon>  
                                                    <balloon aria-label="Cancel" data-balloon-pos="up"><i className="fa fa-close" onClick={(event)=>{cancelUpdate(event)}}></i></balloon></div>}

                                                    
                                                    </label> 
                                               </div>
                                              <span>  
                                                    </span>
                                                  
                                                
                                               
                                        </div>
                                       <div style={{textAlign:"center"}}> {dispOtpMsg} </div>
                                               
                                            {otpsent?
                                            <div >
                                           
                                          
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                      <Input
                                                        type="text"
                                                        name="code"
                                                        value={code}
                                                        disabled = {!otpsent}
                                                        onChange={(event)=>{setCode(event.target.value)}}
                                                        placeholder="Enter OTP"
                                                    />
                                                    <label className="errspan"> 
                                                    <balloon aria-label="Validate OTP!" data-balloon-pos="up"><i className="fa fa-check" onClick={(event)=>{handleOTPValidation(event)}}></i></balloon>
                                                    
                                                    <balloon aria-label="Re-Send OTP!" data-balloon-pos="up"><i className="fa fa-repeat" onClick={(event)=>{handleResendOTP(event)}}></i></balloon>
                                                    </label>
                                              
                                                    </div>
                                                  
                                                    
                                                    </div>:<div></div>}
                                           
                                        <div >
                                           
                                                
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Input
                                                        type="text"
                                                        name="AuthorFN"
                                                        value={loggedUser.startDate}
                                                        disabled
                                                    />

                                                </div>

                                           
                                        </div>

                                        <div >
                                            <div >
                                               
                                                <div className="col-md-4 col-sm-4  col-xs-4">
                                                    <Input
                                                        type="text"
                                                        name="address"
                                                        value={address}
                                                        onChange={(event)=>{setAddress(event.target.value)}}
                                                        disabled = {editAddress}
                                                        placeholder="Edit Address"
                                                    />  
<label className="errspan"> 
                                                {editAddress?<balloon aria-label="Edit Address!" data-balloon-pos="up"><i className="fa fa-pencil" onClick={(event)=>{handleAddressEdit(event)}}></i></balloon>:
                                                <div><balloon aria-label="Save Address!" data-balloon-pos="up"><i className="fa fa-check" onClick={(event)=>{handleAddressUpdate(event)}}></i></balloon>
                                                                                                <balloon aria-label="Cancel" data-balloon-pos="up"><i className="fa fa-close" onClick={(event)=>{cancelUpdate(event)}}></i></balloon></div>}

                                          </label>
                                                </div>
                                                
                                            </div>
                                        </div>
                                       <div style={{textAlign:"center"}}> {dispMsg} </div>

                      
                                       
                                </form>
                          
                </div>
               
            </div>:<div>{handleReRoute()}</div>}

        </div>
        </>
            
    )
}
