export const allPackages = [{
    packageId:"IDN-WH-01",
    packageName:"Bronze Package - Web Hosting",
    packageDetails:[
        "Static package",
        "SSL Certificate",
        "API Routing"
    ],
    packagePrice:4999.00,
    inCart: false,
    count: 0,
    total: 0,

},{
    packageId:"IDN-WH-02",
    packageName:"Silver Package - Web Hosting",
    packageDetails:[
        "Single Domain",
        "Single Sub-domain",
        "1GB Storage",
        "1GB database",
        "API Routing",
        "SSL Certificate",
        "3L API transactions",
        "Authentication Service"
    ],
    packagePrice:7999.00,
    inCart: false,
    count: 0,
    total: 0,

},{
    packageId:"IDN-WH-03",
    packageName:"Gold Package - Web Hosting",
    packageDetails:[
        "5GB Storage",
        "Single Domain",
        "3 Sub-domains",
        "6L API transactions",
        "SSL Certificate",
        "Authentication Service",
        "5GB database"
    ],
    packagePrice:9999.00,
    inCart: false,
    count: 0,
    total: 0,

},{
    packageId:"IDN-WH-04",
    packageName:"Platinum Package - Web Hosting",
    packageDetails:[
        "10GB Storage",
        "Single Domain",
        "5 Sub-domains",
        "Unlimited API transactions",
        "SSL Certificate",
        "Authentication Service",
        "10GB database",
        "Secured API Routing"
    ],
    packagePrice:14999.00,
    inCart: false,
    count: 0,
    total: 0,

},{
    packageId:"IDN-WD-01",
    packageName:"Bronze Package - Web Development",
    packageDetails:[
        "Informational Sites",
        "Static Web Pages",
        "Added SEO"
    ],
    packagePrice:7999.00,
    inCart: false,
    count: 0,
    total: 0,

},{
    packageId:"IDN-WD-02",
    packageName:"Silver Package - Web Development",
    packageDetails:[
        "Transactional Sites",
        "Dynamic Web Pages",
        "Added SEO",
        "Secured Database connection",
        "Secured API"
    ],    
    packagePrice:9999.00,
    inCart: false,
    count: 0,
    total: 0,

},{
    packageId:"IDN-WD-03",
    packageName:"Gold Package - Web Development",
    packageDetails:[
        "ECommerce Sites",
        "Dynamic Web Pages",
        "Added SEO",
        "Secured Database connection",
        "Secured API",
        "Reports",
        "Email Alerts",
        "User Profiles",
        "Secured Payment Gateway Integration"         
    ], 
    packagePrice:12999.00,
    inCart: false,
    count: 0,
    total: 0,

},{
    packageId:"IDN-WD-04",
    packageName:"Platinum Package - Web Development",
    packageDetails:[
        "ECommerce Sites",
        "Dynamic Web Pages",
        "Includes SEO",
        "Secured Database connection",
        "Secured API",
        "Reports",
        "Email Alerts",
        "User Profiles",
        "Secured Payment Gateway Integration",
        "Dynamic Catalogues",
        "Email Marketing",
        "Real Time Dashboard"
    ],
    packagePrice:14999.00,
    inCart: false,
    count: 0,
    total: 0,

},{
    packageId:"IDN-EH-01",
    packageName:"Gold Package - Personal Email",
    packageDetails:[
        "Personalized Email",
        "Web - Mobile Friendly",
        "Suitable for 5 or less ID's",
        "Shared Servers"
    ],
    packagePrice:699.00,
    inCart: false,
    count: 0,
    total: 0,

},{
    packageId:"IDN-EH-02",
    packageName:"Platinum Package - Business Email",
    packageDetails:[
        "Business Account",
        "Secured EMail Acess",
        "Dedicated Servers",
        "Suitable for 5 or more ID's"
    ],
    packagePrice:999.00,
    inCart: false,
    count: 0,
    total: 0,

}];

export const detailProduct = {
    id: 1,
    title: "BurgerNation",
    img: "img/product-1.png",
    price: 10,
    category: "Burger",
    info:
      "",
    inCart: false,
    count: 0,
    total: 0
  };

  export const tempClients = [{
        PK:"IDN",
        clientID:"1",
        clientName: "Library",
        clientStartDate:"2021-10-10",
        clientEndDate:"NA",
        ClientStatus:"Active",
        pocAddress:"Chennai",
        pocPhone:"1234567890",
        pocName:"ABC",
        pocEmail:"abc@abc.com",
        joiningSource:"Self",
        couponCode:"COUPON10"
  },{
    PK:"IDN",
    clientID:"2",
    clientName: "School",
    clientStartDate:"2021-10-12",
    clientEndDate:"NA",
    ClientStatus:"Active",
    pocAddress:"Bangalore",
    pocPhone:"1234567890",
    pocName:"XYZ",
    pocEmail:"ayx@xyz.com",
    joiningSource:"Self",
    couponCode:"COUPON10"
}];

export const temp_client_accounts = [{
    PK:"IDN",
    clientID:"1",
    AccID:"1A",
    packageStartDate:"2021-10-10",
    packageEndDate:"2022-10-10",
    packageId:"IDN-WH-01",
    packageName:"GOLD",
    packageDetails:["1111","2222","3333","4444"],
    packagePrice:4999.00,
},{
    PK:"IDN",
    clientID:"1",
    AccID:"1B",
    packageStartDate:"2020-10-10",
    packageEndDate:"2021-10-10",
    packageId:"IDN-WH-01",
    packageName:"GOLD",
    packageDetails:["1111","2222","3333","4444"],
    packagePrice:4999.00,
},{
    PK:"IDN",
    clientID:"1",
    AccID:"1C",
    packageStartDate:"2021-10-20",
    packageEndDate:"2022-10-10",
    packageId:"IDN-WD-01",
    packageName:"GOLD",
    packageDetails:["aaaa","bbbb","cccc","dddd"],
    packagePrice:2999.00,
}];

export const phoneCode=[
    {
      "Country": "USA",
      "Code": "+1"
    },
    {
      "Country": "RUS",
      "Code": "+7"
    },
    {
      "Country": "EGY",
      "Code": "+20"
    },
    {
      "Country": "ZAF",
      "Code": "+27"
    },
    {
      "Country": "GRC",
      "Code": "+30"
    },
    {
      "Country": "NLD",
      "Code": "+31"
    },
    {
      "Country": "BEL",
      "Code": "+32"
    },
    {
      "Country": "FRA",
      "Code": "+33"
    },
    {
      "Country": "ESP",
      "Code": "+34"
    },
    {
      "Country": "HUN",
      "Code": "+36"
    },
    {
      "Country": "ITA",
      "Code": "+39"
    },
    {
      "Country": "ROU",
      "Code": "+40"
    },
    {
      "Country": "CHE",
      "Code": "+41"
    },
    {
      "Country": "AUT",
      "Code": "+43"
    },
    {
      "Country": "GBR",
      "Code": "+44"
    },
    {
      "Country": "DNK",
      "Code": "+45"
    },
    {
      "Country": "SWE",
      "Code": "+46"
    },
    {
      "Country": "NOR",
      "Code": "+47"
    },
    {
      "Country": "POL",
      "Code": "+48"
    },
    {
      "Country": "DEU",
      "Code": "+49"
    },
    {
      "Country": "PER",
      "Code": "+51"
    },
    {
      "Country": "MEX",
      "Code": "+52"
    },
    {
      "Country": "CUB",
      "Code": "+53"
    },
    {
      "Country": "ARG",
      "Code": "+54"
    },
    {
      "Country": "BRA",
      "Code": "+55"
    },
    {
      "Country": "CHL",
      "Code": "+56"
    },
    {
      "Country": "COL",
      "Code": "+57"
    },
    {
      "Country": "VEN",
      "Code": "+58"
    },
    {
      "Country": "MYS",
      "Code": "+60"
    },
    {
      "Country": "AUS",
      "Code": "+61"
    },
    {
      "Country": "IDN",
      "Code": "+62"
    },
    {
      "Country": "PHL",
      "Code": "+63"
    },
    {
      "Country": "NZL",
      "Code": "+64"
    },
    {
      "Country": "SGP",
      "Code": "+65"
    },
    {
      "Country": "THA",
      "Code": "+66"
    },
    {
      "Country": "JPN",
      "Code": "+81"
    },
    {
      "Country": "KOR",
      "Code": "+82"
    },
    {
      "Country": "VNM",
      "Code": "+84"
    },
    {
      "Country": "CHN",
      "Code": "+86"
    },
    {
      "Country": "TUR",
      "Code": "+90"
    },
    {
      "Country": "IND",
      "Code": "+91"
    },
    {
      "Country": "PAK",
      "Code": "+92"
    },
    {
      "Country": "AFG",
      "Code": "+93"
    },
    {
      "Country": "LKA",
      "Code": "+94"
    },
    {
      "Country": "MMR",
      "Code": "+95"
    },
    {
      "Country": "IRN",
      "Code": "+98"
    },
    {
      "Country": "SSD",
      "Code": "+211"
    },
    {
      "Country": "ESH",
      "Code": "+212"
    },
    {
      "Country": "DZA",
      "Code": "+213"
    },
    {
      "Country": "TUN",
      "Code": "+216"
    },
    {
      "Country": "LBY",
      "Code": "+218"
    },
    {
      "Country": "GMB",
      "Code": "+220"
    },
    {
      "Country": "SEN",
      "Code": "+221"
    },
    {
      "Country": "MRT",
      "Code": "+222"
    },
    {
      "Country": "MLI",
      "Code": "+223"
    },
    {
      "Country": "GIN",
      "Code": "+224"
    },
    {
      "Country": "CIV",
      "Code": "+225"
    },
    {
      "Country": "BFA",
      "Code": "+226"
    },
    {
      "Country": "NER",
      "Code": "+227"
    },
    {
      "Country": "TGO",
      "Code": "+228"
    },
    {
      "Country": "BEN",
      "Code": "+229"
    },
    {
      "Country": "MUS",
      "Code": "+230"
    },
    {
      "Country": "LBR",
      "Code": "+231"
    },
    {
      "Country": "SLE",
      "Code": "+232"
    },
    {
      "Country": "GHA",
      "Code": "+233"
    },
    {
      "Country": "NGA",
      "Code": "+234"
    },
    {
      "Country": "TCD",
      "Code": "+235"
    },
    {
      "Country": "CAF",
      "Code": "+236"
    },
    {
      "Country": "CMR",
      "Code": "+237"
    },
    {
      "Country": "CPV",
      "Code": "+238"
    },
    {
      "Country": "STP",
      "Code": "+239"
    },
    {
      "Country": "GNQ",
      "Code": "+240"
    },
    {
      "Country": "GAB",
      "Code": "+241"
    },
    {
      "Country": "COG",
      "Code": "+242"
    },
    {
      "Country": "COD",
      "Code": "+243"
    },
    {
      "Country": "AGO",
      "Code": "+244"
    },
    {
      "Country": "GNB",
      "Code": "+245"
    },
    {
      "Country": "IOT",
      "Code": "+246"
    },
    {
      "Country": "SYC",
      "Code": "+248"
    },
    {
      "Country": "SDN",
      "Code": "+249"
    },
    {
      "Country": "RWA",
      "Code": "+250"
    },
    {
      "Country": "ETH",
      "Code": "+251"
    },
    {
      "Country": "SOM",
      "Code": "+252"
    },
    {
      "Country": "DJI",
      "Code": "+253"
    },
    {
      "Country": "KEN",
      "Code": "+254"
    },
    {
      "Country": "TZA",
      "Code": "+255"
    },
    {
      "Country": "UGA",
      "Code": "+256"
    },
    {
      "Country": "BDI",
      "Code": "+257"
    },
    {
      "Country": "MOZ",
      "Code": "+258"
    },
    {
      "Country": "ZMB",
      "Code": "+260"
    },
    {
      "Country": "MDG",
      "Code": "+261"
    },
    {
      "Country": "REU",
      "Code": "+262"
    },
    {
      "Country": "ZWE",
      "Code": "+263"
    },
    {
      "Country": "NAM",
      "Code": "+264"
    },
    {
      "Country": "MWI",
      "Code": "+265"
    },
    {
      "Country": "LSO",
      "Code": "+266"
    },
    {
      "Country": "BWA",
      "Code": "+267"
    },
    {
      "Country": "SWZ",
      "Code": "+268"
    },
    {
      "Country": "COM",
      "Code": "+269"
    },
    {
      "Country": "SHN",
      "Code": "+290"
    },
    {
      "Country": "ERI",
      "Code": "+291"
    },
    {
      "Country": "ABW",
      "Code": "+297"
    },
    {
      "Country": "FRO",
      "Code": "+298"
    },
    {
      "Country": "GRL",
      "Code": "+299"
    },
    {
      "Country": "GIB",
      "Code": "+350"
    },
    {
      "Country": "PRT",
      "Code": "+351"
    },
    {
      "Country": "LUX",
      "Code": "+352"
    },
    {
      "Country": "IRL",
      "Code": "+353"
    },
    {
      "Country": "ISL",
      "Code": "+354"
    },
    {
      "Country": "ALB",
      "Code": "+355"
    },
    {
      "Country": "MLT",
      "Code": "+356"
    },
    {
      "Country": "CYP",
      "Code": "+357"
    },
    {
      "Country": "FIN",
      "Code": "+358"
    },
    {
      "Country": "BGR",
      "Code": "+359"
    },
    {
      "Country": "LTU",
      "Code": "+370"
    },
    {
      "Country": "LVA",
      "Code": "+371"
    },
    {
      "Country": "EST",
      "Code": "+372"
    },
    {
      "Country": "MDA",
      "Code": "+373"
    },
    {
      "Country": "ARM",
      "Code": "+374"
    },
    {
      "Country": "BLR",
      "Code": "+375"
    },
    {
      "Country": "AND",
      "Code": "+376"
    },
    {
      "Country": "MCO",
      "Code": "+377"
    },
    {
      "Country": "SMR",
      "Code": "+378"
    },
    {
      "Country": "UKR",
      "Code": "+380"
    },
    {
      "Country": "SRB",
      "Code": "+381"
    },
    {
      "Country": "MNE",
      "Code": "+382"
    },
    {
      "Country": "XKX",
      "Code": "+383"
    },
    {
      "Country": "HRV",
      "Code": "+385"
    },
    {
      "Country": "SVN",
      "Code": "+386"
    },
    {
      "Country": "BIH",
      "Code": "+387"
    },
    {
      "Country": "MKD",
      "Code": "+389"
    },
    {
      "Country": "CZE",
      "Code": "+420"
    },
    {
      "Country": "SVK",
      "Code": "+421"
    },
    {
      "Country": "LIE",
      "Code": "+423"
    },
    {
      "Country": "FLK",
      "Code": "+500"
    },
    {
      "Country": "BLZ",
      "Code": "+501"
    },
    {
      "Country": "GTM",
      "Code": "+502"
    },
    {
      "Country": "SLV",
      "Code": "+503"
    },
    {
      "Country": "HND",
      "Code": "+504"
    },
    {
      "Country": "NIC",
      "Code": "+505"
    },
    {
      "Country": "CRI",
      "Code": "+506"
    },
    {
      "Country": "PAN",
      "Code": "+507"
    },
    {
      "Country": "SPM",
      "Code": "+508"
    },
    {
      "Country": "HTI",
      "Code": "+509"
    },
    {
      "Country": "BLM",
      "Code": "+590"
    },
    {
      "Country": "BOL",
      "Code": "+591"
    },
    {
      "Country": "GUY",
      "Code": "+592"
    },
    {
      "Country": "ECU",
      "Code": "+593"
    },
    {
      "Country": "PRY",
      "Code": "+595"
    },
    {
      "Country": "SUR",
      "Code": "+597"
    },
    {
      "Country": "URY",
      "Code": "+598"
    },
    {
      "Country": "ANT",
      "Code": "+599"
    },
    {
      "Country": "TLS",
      "Code": "+670"
    },
    {
      "Country": "ATA",
      "Code": "+672"
    },
    {
      "Country": "BRN",
      "Code": "+673"
    },
    {
      "Country": "NRU",
      "Code": "+674"
    },
    {
      "Country": "PNG",
      "Code": "+675"
    },
    {
      "Country": "TON",
      "Code": "+676"
    },
    {
      "Country": "SLB",
      "Code": "+677"
    },
    {
      "Country": "VUT",
      "Code": "+678"
    },
    {
      "Country": "FJI",
      "Code": "+679"
    },
    {
      "Country": "PLW",
      "Code": "+680"
    },
    {
      "Country": "WLF",
      "Code": "+681"
    },
    {
      "Country": "COK",
      "Code": "+682"
    },
    {
      "Country": "NIU",
      "Code": "+683"
    },
    {
      "Country": "WSM",
      "Code": "+685"
    },
    {
      "Country": "KIR",
      "Code": "+686"
    },
    {
      "Country": "NCL",
      "Code": "+687"
    },
    {
      "Country": "TUV",
      "Code": "+688"
    },
    {
      "Country": "PYF",
      "Code": "+689"
    },
    {
      "Country": "TKL",
      "Code": "+690"
    },
    {
      "Country": "FSM",
      "Code": "+691"
    },
    {
      "Country": "MHL",
      "Code": "+692"
    },
    {
      "Country": "PRK",
      "Code": "+850"
    },
    {
      "Country": "HKG",
      "Code": "+852"
    },
    {
      "Country": "MAC",
      "Code": "+853"
    },
    {
      "Country": "KHM",
      "Code": "+855"
    },
    {
      "Country": "LAO",
      "Code": "+856"
    },
    {
      "Country": "BGD",
      "Code": "+880"
    },
    {
      "Country": "TWN",
      "Code": "+886"
    },
    {
      "Country": "MDV",
      "Code": "+960"
    },
    {
      "Country": "LBN",
      "Code": "+961"
    },
    {
      "Country": "JOR",
      "Code": "+962"
    },
    {
      "Country": "SYR",
      "Code": "+963"
    },
    {
      "Country": "IRQ",
      "Code": "+964"
    },
    {
      "Country": "KWT",
      "Code": "+965"
    },
    {
      "Country": "SAU",
      "Code": "+966"
    },
    {
      "Country": "YEM",
      "Code": "+967"
    },
    {
      "Country": "OMN",
      "Code": "+968"
    },
    {
      "Country": "PSE",
      "Code": "+970"
    },
    {
      "Country": "ARE",
      "Code": "+971"
    },
    {
      "Country": "ISR",
      "Code": "+972"
    },
    {
      "Country": "BHR",
      "Code": "+973"
    },
    {
      "Country": "QAT",
      "Code": "+974"
    },
    {
      "Country": "BTN",
      "Code": "+975"
    },
    {
      "Country": "MNG",
      "Code": "+976"
    },
    {
      "Country": "NPL",
      "Code": "+977"
    },
    {
      "Country": "TJK",
      "Code": "+992"
    },
    {
      "Country": "TKM",
      "Code": "+993"
    },
    {
      "Country": "AZE",
      "Code": "+994"
    },
    {
      "Country": "GEO",
      "Code": "+995"
    },
    {
      "Country": "KGZ",
      "Code": "+996"
    },
    {
      "Country": "UZB",
      "Code": "+998"
    }
  ];

  
