import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Auth } from "aws-amplify";
//import dotenv from 'dotenv';
import Chatbot from "react-chatbot-kit";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

import Home from './components/Home';
//import SignIn from './components/auth/SignIn';
//import SignUp from './components/auth/SignUp';
import IdnNavBar from './components/IdnNavBar';
import MyBoard from './components/dashboard/MyBoard';
import OrderConfirmation from './components/dashboard/OrderConfirmation';
import Policy from './components/policies/Policy';
import { ProductContext } from './ProductProvider';
import useFullPageLoader from "./components/hooks/useFullPageLoader";

import './css/bootstrap.min.css';
import './App.css';
import './components/chatbot/ChatStyle.css';
import 'react-chatbot-kit/build/main.css'

import CheckOut from './components/dashboard/CheckOut';
import WebHost from './components/services/WebHost';
import WebDevelopment from './components/services/WebDevelopment';
import EmailHosting from './components/services/EmailHosting';
import Cart from './components/cart/Cart';

/// tesing imports here

import SignUpMFA from './components/auth/SignUpMFA';
import SignInMFA from './components/auth/SignInMFA';
import ForgotPassword from './components/auth/ForgotPassword';
import Bottombar from './components/bottombar/Bottombar';
import MyTransaction from './components/dashboard/MyTransaction';
import QuickProfile from './components/dashboard/QuickProfile';
//import QuickView from './components/dashboard/QuickView';
import ChangePassword from './components/auth/ChangePassword';
import MyProfile from './components/dashboard/MyProfile';
import MyEmailAcc from './components/dashboard/MyEmailAcc';
import MyWebHostingAcc from './components/dashboard/MyWebHostingAcc';
import MyWebDevAcc from './components/dashboard/MyWebDevAcc';
import OrderPreview from './components/dashboard/OrderPreview';
import ServerError from './components/auth/ServerError';
import PageNotFound from './components/auth/PageNotFound';

import ChatbotConfig from './components/chatbot/ChatbotConfig';
import MessageParser from './components/chatbot/MessageParser';
import ActionProvider from './components/chatbot/ActionProvider';
import WhatsAppIntegration from './components/whatsapp/WhatsAppIntegration';
import { FloatingWhatsApp } from './components/whatsapp/FloatingWhatsApp';
import WhatsAppFloat from './components/whatsapp/WhatsAppFloat';

//require('dotenv').config();
//dotenv.config();
//require('dotenv').config({ path: require('find-config')('.env') })
const path = require('path')
require('dotenv').config({ path: path.resolve(__dirname, '../.env') })


function App() {
  const { loggedUser, setLoggedUser} = useContext(ProductContext);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [showBot, toggleBot] = useState(false);


  useEffect(() => {
    showLoader();
    validateLogin();
    hideLoader();
    //console.log("Process ENV : ", process.env);
  }, []);

  const validateLogin = async () => {
    try {
      Auth.currentAuthenticatedUser()
        .then((user) => {
         //console.log("User : ", user);
         //const familyName=user.attributes.family_name;
          setLoggedUser({
            ...loggedUser,
            username: user.attributes.name,
            isAuthenticated: true,
            email:user.attributes.email,
            phoneno:user.attributes.phone_number,
            address:user.attributes.address,
            startDate:user.attributes.birthdate,
            clientID:user.attributes.family_name,
            profile:user.attributes.profile,
            orgName:user.attributes.given_name,
            gstNo:user.attributes.gender
          });
        })
        .catch((error) => {
          setLoggedUser({
            ...loggedUser,
            username: '',
            isAuthenticated: false,
            email:'',
            phoneno:'',
            address:'',
            startDate:'',
            clientID:'',
            profile:'',
            orgName:'',
            gstNo:''
          });
        })
    } catch (error) {
      //console.log(error);
    }
  }

  return (
    <div className="App">
    {loader}

      <Router>
        <div>
     
          <IdnNavBar />
          <Switch>
            <Route exact path="/" render={(props) => <Home {...props} />} />
         
            <Route exact path="/checkout" render={(props) => <CheckOut {...props} />} />
            <Route exact path='/order-preview' render={(props) => <OrderPreview {...props} />} />
            <Route exact path="/dashboard" render={(props) => <MyBoard {...props} />} />
            <Route exact path="/policies" render={(props) => <Policy {...props} />} />
            {/*services*/}
            <Route exact path="/webhosting" render={(props) => <WebHost {...props} />} />
            <Route exact path="/webdevelopemnt" render={(props) => <WebDevelopment {...props} />} />
            <Route exact path="/email-services" render={(props) => <EmailHosting {...props} />} />
            
            {/*My Accounts*/}
            <Route exact path="/my-email-services" render={(props) => <MyEmailAcc {...props} />} />
            <Route exact path="/my-web-hosting" render={(props) => <MyWebHostingAcc {...props} />} />
            <Route exact path="/my-web-developemnt" render={(props) => <MyWebDevAcc {...props} />} />

            {/*order details*/}
            <Route exact path="/cart" render={(props) => <Cart {...props} />} />
            <Route exact path="/my-transaction" render={(props) => <MyTransaction {...props} />} />
            <Route exact path="/orderconfirmation" render={(props) => <OrderConfirmation {...props} />} />

            {/*test*/}
            <Route exact path="/profile-view" render={(props) => <QuickProfile {...props} />} />
            <Route exact path="/my-profile" render={(props) => <MyProfile {...props} />} />


            {/*auth ones*/}
            <Route exact path="/signup" render={(props) => <SignUpMFA {...props} />} />
            <Route exact path="/signin" render={(props) => <SignInMFA {...props} />} />
            <Route exact path="/forgot-password" render={(props) => <ForgotPassword {...props} />} />
            <Route exact path="/change-password" render={(props) => <ChangePassword {...props} />} />
            <Route exact path="/server-error" render={(props) => <ServerError {...props} />} />
            <Route  path="*" render={(props) => <PageNotFound {...props} />} />

          </Switch>
          <div className="navbar-mobile">
          <Bottombar />
          </div>
          <div className="App">
          {showBot && (
        <Fade big>
          <div className="app-chatbot-container">
            <Chatbot
              config={ChatbotConfig}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
            />
          </div>
        </Fade>
      )}
       <Flip left cascade>
        <button
          className="app-chatbot-button"
          onClick={() => toggleBot((prev) => !prev)}
        >
          <div>Bot</div>
          <svg viewBox="0 0 640 512" className="app-chatbot-button-icon">
            <path d="M192,408h64V360H192ZM576,192H544a95.99975,95.99975,0,0,0-96-96H344V24a24,24,0,0,0-48,0V96H192a95.99975,95.99975,0,0,0-96,96H64a47.99987,47.99987,0,0,0-48,48V368a47.99987,47.99987,0,0,0,48,48H96a95.99975,95.99975,0,0,0,96,96H448a95.99975,95.99975,0,0,0,96-96h32a47.99987,47.99987,0,0,0,48-48V240A47.99987,47.99987,0,0,0,576,192ZM96,368H64V240H96Zm400,48a48.14061,48.14061,0,0,1-48,48H192a48.14061,48.14061,0,0,1-48-48V192a47.99987,47.99987,0,0,1,48-48H448a47.99987,47.99987,0,0,1,48,48Zm80-48H544V240h32ZM240,208a48,48,0,1,0,48,48A47.99612,47.99612,0,0,0,240,208Zm160,0a48,48,0,1,0,48,48A47.99612,47.99612,0,0,0,400,208ZM384,408h64V360H384Zm-96,0h64V360H288Z"></path>
          </svg>
        </button>
      </Flip>
      </div>
     {/*} <WhatsAppIntegration />*/}
      <div className="App">
        <WhatsAppFloat />
    </div>
        </div>
      </Router>
    </div>

  );
}

export default App;
