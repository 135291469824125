import React, {useContext} from 'react';
import CartItemCheckout from './CartItemCheckOut';
import { ProductContext } from '../../ProductProvider';

import {Col,Row, Container, Table} from 'reactstrap';

export default function CartListCheckOut() {
    const { cart } = useContext(ProductContext);

    return (
      <Container>
      <Row>
        <Col>
                  <Table hover responsive>
                    <thead>
                      <tr>
                        <th >
                          Account Id
                        </th>
                        <th >
                          Package Name
                        </th>
                        <th >
                            Quantity
                        </th>
                        <th style={{textAlign:"right"}}>
                          Total
                        </th>
                        
                      </tr>
                    </thead> 
                    <tbody>
               {cart.map(item =>{
                   return (
                   
                    <CartItemCheckout key={item.cartno} item={item} />
                   
                    )
                   
               })}
               
                </tbody>
                </Table>
                  
                </Col>
            </Row>
        </Container>   
             
          
    )
}
