import React, { useState, useEffect } from 'react';
import { Carousel } from "react-responsive-carousel";
/*import slider1 from '../images/slider/slider1.jpg';
import slider2 from '../images/slider/slider2.jpg';
import slider3 from '../images/slider/slider3.jpg';
import slider4 from '../images/slider/slider4.jpg';
import slider5 from '../images/slider/slider5.jpg';
import sliderm1 from '../images/slider/ms1.jpg';
import sliderm2 from '../images/slider/ms2.jpg';
import sliderm3 from '../images/slider/ms3.jpg';*/
import axios from "axios";
import useFullPageLoader from "./hooks/useFullPageLoader";

//import { Auth } from "aws-amplify";

const config = require('../config.json');

export default function HomeSlider () {
    const [galleryImages, setGalleryImages] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    useEffect(()=>{
      fetchSliderImages();
    },[])
  
    const fetchSliderImages =async()=>{
      try{
         // console.log("Testing");
          //const session = await Auth.currentSession();
          //const access_token=session.idToken.jwtToken;
          showLoader();
        const fileName='images/slider/';
        const bucketName="images.idntica.com";
  
      await axios.get(`${config.api.invokeUrl}/admin/images/slider?fileName=${fileName}&bucketName=${bucketName}`,{
          headers: {
           // Authorization: access_token,
            'x-api-key': config.api.key
          }}, 
      ).then((response)=>{
        const finalList=[];
       // console.log("Res : ", response);
        if(response.data.length>0){
          for (const i in response.data){
            const tValue=response.data[i];
            const tPathName=tValue.split("/");
            const tBreakUp=tPathName[2];
            const tempValue={};
            tempValue.fullPathName=response.data[i];
            tempValue.fileName=tBreakUp;
           
           // console.log("Temp Value : ", tempValue);
            //const tdoctorName=tBreakUp[1];
            //const tempDName=tdoctorName.split(".");
  
            //tempValue.doctorName=tempDName[0];
           // tempValue.projectSize=tBreakUp[2];
            //tempValue.projectDate=tBreakUp[3];
            finalList.push(tempValue);
            
          }
        }
         // console.log("Res : ", finalList);
          setGalleryImages(finalList);
          hideLoader();
          //setDispMsg(response.data.length + " - Images are found in your Gallery");
      }).catch((error)=>{
        hideLoader();
         // console.log(error.message);
          //setDispMsg(error.message);
      })
  }catch(error){
    hideLoader();
  //console.log(error.message);
      //setDispMsg(error.message);
  }
  }
     return (
            <div className="menu-slider" style={{paddingTop:"60px"}}> 
                        <div style={{textAlign:"center"}}> {loader} </div>
              <Carousel autoPlay infiniteLoop={true} showThumbs={false} showStatus={false} interval={4000} stopOnHover={false}>
              {galleryImages.map((images, index)=>  
            <img key={index} style={{height:'500px'}} className='m-0 p-0 img-fluid rounded' alt="slider" src={encodeURI(`${config.s3.sliderURL}${images.fullPathName}`)} />
        )}
              </Carousel>      

            {/*}   <div className="navbar-bg">
               <Carousel autoPlay infiniteLoop={true} showThumbs={false} showStatus={false} interval={4000} stopOnHover={false}>
             
                    <div >

                        <img className="img-fluid" src={slider1} alt="1" />

                    </div>
                    <div>
                        <img className="img-fluid" src={slider2} alt="2" />
                    </div >
                    <div >
                        <img className="img-fluid" src={slider3} alt="3" />
                    </div>
                    <div>
                        <img className="img-fluid" src={slider4} alt="4" />
                    </div>
                    <div>
                        <img className="img-fluid" src={slider5} alt="5" />
                    </div>
                    </Carousel>      
</div>

<div className="navbar-mobile">
<Carousel autoPlay infiniteLoop={true} showThumbs={false} showStatus={false} interval={4000} stopOnHover={false}>
             
             
<div >

<img className="img-fluid" src={sliderm1} alt="1" />

</div>   
             
<div >

<img className="img-fluid" src={sliderm2} alt="1" />

</div>   
             
<div >

<img className="img-fluid" src={sliderm3} alt="1" />

</div>   
 </Carousel>
</div>*/}
            
            </div>
        )
    
}
