import React from 'react';
//import { ProductContext } from '../../ProductProvider';

export default function CartItemCheckOut({item}) {
   // const { removeItem } = useContext(ProductContext);

    const { accId, packageName, total, years} =item;
    const totaltemp= parseFloat(total).toFixed(2);
    return (
       

        
<tr>
              <th scope="row" style={{textAlign:"left"}}>{accId}</th> 
              <td style={{textAlign:"left"}}>{packageName}</td>
           
            <td style={{textAlign:"center"}}>
            {years}
            </td>
            <td style={{textAlign:"right"}}>
                {totaltemp}
            </td>
            </tr>

    )
}
