import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import '../ChatStyle.css';
import UrlIcon from "../icons/call.svg";

import { getData } from "../data";
import WebPlans from "../../services/webhost/WebPlans";

const HostingPlans = () => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getStats = async () => {
      //const stats = await getData();

      // const filteredFlights = flights.filter((item) => item.Status === null);

      setStats(stats);
      setLoading(false);
    };
    //getStats();
  }, []);

  return (
    <>
    <a href="https://idntica.com/webhosting" className="tel-link">
    <h6> Click here to see your best and customized hosting plans</h6>
  </a>
  <div className="tel-link option-item options-container">
  <WebPlans />

    Looking for more options - type 'help' 
  </div>
  </>

  );
};

export default HostingPlans;
