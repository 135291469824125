import React from 'react';
import { Link } from 'react-router-dom';
import error404 from '../../images/svg/404-error.svg';
import {Helmet} from 'react-helmet-async';

export default function PageNotFound() {

return (
<>
    <Helmet>
      <title>Page Not Found - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

        <div className="login">
            <section  className="sign-in">
            <p style={{paddingTop:"100"}}><Link to="/">Go back to Home Page</Link></p>

            <div className="container" style={{paddingTop:"100"}}>
            <figure><img src={error404} alt="sign up"/></figure>
            <h2><Link to="/" className="signup-image-link">Go back to Home Page</Link></h2>
            </div>
        </section>
    </div>
    </>

    )

}