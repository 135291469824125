import React , {useContext,useEffect } from 'react';
import { Table } from 'reactstrap';
import Footer from '../Footer';
//import webhosting from '../../images/web.png';
import styled from 'styled-components';
import host from '../../images/host.png';
import scalable from '../../images/scalable.jpeg';
import FastBrowsing from '../../images/FastBrowsing.jpeg';
import { ProductContext } from '../../ProductProvider';
import 'balloon-css';
import {Helmet} from 'react-helmet-async';
import WebPlans from './webhost/WebPlans';

//import { faTeethOpen } from '@fortawesome/free-solid-svg-icons';

export default function WebHost() {
    const { packages, addToCart, setBottomValue } = useContext(ProductContext);

    useEffect(() => {
         setBottomValue('');
         window.scrollTo(0,0);
         
    }, [])

   
    const handleAddToCart =(event, id) =>{
        event.preventDefault();
        addToCart(id,"New");

    }

    return (
     <>
     <Helmet>
      <title>Web Hosting Plans - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="Web Hosting Plans - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="Web Hosting Plans - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

     
        <div className="services-bn" >
             <div style={{display:"table", paddingTop:"50px", height:"100%", width:"100%", lineHeight:"normal", textAlign:"center"}}>
                   <img src={host} alt="Web Hosting"/>
   
             </div>
           
           
                
                 
            <services id="" data-stellar-background-ratio="0.5">
   <div> 
   {/*plans and prices*/}
   <div className="section-title">
          <h3 style={{textAlign:"center", fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Check out our best in class plans that suits your needs - No hidden cost</h3>
     </div>
     <WebPlans />

   {/*<div style={{paddingLeft:"5px", paddingRight:"5px"}}>
   
   {packages.length>0?<div>

<Table hover responsive bordered>

<thead>
<tr><th>Plan ID</th>
<th>Plan Name</th>
<th>Plan Details</th>
<th>Cost/year</th>
 </tr> 
</thead>    
<tbody>
{packages.filter(plan => plan.packageId.includes('IDN-WH') ).map((searchresult,index) =>(
<tr key={index}>
<th scope="row" key={searchresult.packageId}>{searchresult.packageId}</th>
<td>{searchresult.packageName}</td>
<td>{(searchresult.packageDetails).map((plan, index)=>(<WebHostWrapper><ul key="{plan}"><li key="{plan}">{plan}</li> </ul></WebHostWrapper>))} </td>
<td><i className="fa fa-rupee"></i> {searchresult.packagePrice.toFixed(2)}<div>{searchresult.inCart?<balloon aria-label="In Cart" data-balloon-pos="up"><div style={{fontSize:"30px"}}><i className="fa fa-check-circle"></i></div></balloon>:<balloon aria-label="Add to Cart" data-balloon-pos="up"><div style={{fontSize:"30px"}}><i className="fa fa-cart-plus" onClick={(event)=>handleAddToCart(event, searchresult.packageId)}></i></div></balloon>}</div></td>
</tr>))}

</tbody></Table>


</div>:<div className="tile notification is-warning"></div>}  
     </div>*/}


{/*Guaranteed 99.9% Uptime*/}
<div className="row navbar-bg card-service">

<div className="col-md-6 col-sm-6 col-xs-12" style={{display:"table",  lineHeight:"normal"}}>
     <div className="services-info" >
         {/* <div className="section-title">
               <h3 style={{textAlign:"center", fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Email</h3>
          </div>
          */}
          <img src={scalable}  style={{display:"table-cell", verticalAlign:"middle"}} alt="store"  />
          <p className="wow fadeInUp" data-wow-delay="0.4s">
               {/*<p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}></p>*/}
          </p>
     </div>
</div>
<div className="services-main col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
          <div className="section-title">
               <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Guaranteed 99.9% Uptime</h2>
          </div>

          <address className="wow fadeInUp" data-wow-delay="0.4s" style={{textAlign:"justify"}}>
          {/*<p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Guaranteed 99.9% Uptime</p>*/}
          99.99% Guranteed Uptime!!! This means your web application is up all the time - anywhere - anydevice!. We provide highly scalable serverless cloud infrastructure as a service that can handle any amount of traffic to meet your needs. Join Us and transform your IT!
                                                   
                     {/*} <p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Superior Speed Performance</p>
               Take full advantage of the speed and power. No technical knowledge needed!
               < p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Powerful Control Panel </p>
Enjoy the great speed and power with a simple control panel optimised for everyone        */}
          </address>
          
     </div>
</div>   
</div>


  {/*4x Faster*/}

       <div className="row navbar-bg card-service">


<div className="services-main   col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
          <div className="section-title">
               <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">4x Faster</h2>
          </div>
 
          <address className="wow fadeInUp" data-wow-delay="0.4s" style={{textAlign:"justify"}}>
         
          Cloud with latest edge computing gives the power of Speed and Performance. 4 times faster than the regular web hosting. Your Customer are going to love IT - Speed is Better!
                 
          </address>
         
     </div>
</div>

<div className="services-main col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
      
          <img src={FastBrowsing}  alt="store" />
          <p className="wow fadeInUp" data-wow-delay="0.4s">
             
          </p>
     </div>
</div>



</div>

<div className=" navbar-mobile card-service">



<div className="col-md-6 col-sm-6 col-xs-12" style={{display:"table",  lineHeight:"normal"}}>
     <div className="services-info" >
         {/* <div className="section-title">
               <h3 style={{textAlign:"center", fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Email</h3>
          </div>
          */}
          <img src={scalable}  style={{display:"table-cell", verticalAlign:"middle"}} alt="store"  />
          <p className="wow fadeInUp" data-wow-delay="0.4s">
               {/*<p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}></p>*/}
          </p>
     </div>
</div>
<div className="services-main col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
          <div className="section-title">
               <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">Guaranteed 99.9% Uptime</h2>
          </div>

          <address className="wow fadeInUp" data-wow-delay="0.4s" style={{textAlign:"justify"}}>
          {/*<p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Guaranteed 99.9% Uptime</p>*/}
          99.99% Guranteed Uptime!!! This means your web application is up all the time - anywhere - anydevice!. We provide highly scalable serverless cloud infrastructure as a service that can handle any amount of traffic to meet your needs. Join Us and transform your IT!
                                                   
                     {/*} <p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Superior Speed Performance</p>
               Take full advantage of the speed and power. No technical knowledge needed!
               < p style={{fontFamily:"Oswald", fontSize:"1.0rem", color:"black"}}>Powerful Control Panel </p>
Enjoy the great speed and power with a simple control panel optimised for everyone        */}
          </address>
          
     </div>
</div>   


<div className="services col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
       <img src={FastBrowsing}  alt="store" />
          <p className="wow fadeInUp" data-wow-delay="0.4s">
           
          </p>
     </div>
</div>

<div className="services-main   col-md-6 col-sm-6 col-xs-12">
     <div className="services-info" >
          <div className="section-title">
               <h2 style={{ fontFamily: "Oswald", color:"#1362b3"}}  className="wow fadeInUp" data-wow-delay="0.2s">4x Faster</h2>
          </div>
 
          <address className="wow fadeInUp" data-wow-delay="0.4s" style={{textAlign:"justify"}}>
       
          Cloud with latest edge computing gives the power of Speed and Performance. 4 times faster than the regular web hosting. Your Customer are going to love it - Speed is Better!
        
          </address>
          

     </div>
</div>
     </div>

   </div>
    </services>
    <Footer />
    </div>
    </>
    )
}
const WebHostWrapper = styled.nav`

ul {
     list-style: none;
   }
   
   ul li:before {
     content: '✓';
     padding: 5px;
   }
 
    
    `