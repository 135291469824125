import React, { useState, useEffect, useContext } from 'react';
import { Auth } from "aws-amplify";
import signInImg from '../../images/signin-image.jpg';
import { ProductContext } from '../../ProductProvider';
import { Alert } from 'reactstrap';
import Preloader from '../preloader/Preloader';
import {Helmet} from 'react-helmet-async';

export default function ChangePassword() {
    const { loggedUser, alertDetails, setAlertDetails, alertClose } = useContext(ProductContext)
    //const [dispMsg, setDispMsg] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [loaded, setLoaded] = useState(true);
    
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    const handleChangePassword = async(event) =>{
        event.preventDefault();
        //setDispMsg('');
        try{
            if(oldPassword==="" || newPassword===""){
                //setDispMsg("Enter the password!");
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Enter Username and password"
                }); 
      
            }else{
                setLoaded(false);
            await Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, oldPassword, newPassword);
            })
            .then(data => {
                if(data==="SUCCESS"){
                    setOldPassword('');
                    setNewPassword('');
                    //setDispMsg("Password Changed Successfully!");
                    setAlertDetails({
                        ...alertDetails,
                        style:"success",
                        open:true,
                        message:"Password Changed Successfully!"
                    }); 
          
                    
                }})
            .catch(err => {
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + err.message
                }); 
                //setDispMsg(`Error Occured : ${err.message}`)});
            })}
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            //setDispMsg(`Error Occured : ${error.message}`);
       }
    }

    return (
    <>
    <Helmet>
      <title>Change Password - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="Change Password - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="Change Password - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

            <div className="login">
            {!loaded?<Preloader />:null}
            <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
                    {alertDetails.message}
            </Alert>
           <section  className="sign-in">
            <div className="container">
                <div className="signin-content">
                    <div className="signin-image">
                        <figure><img src={signInImg} alt="sign up"/></figure>
                        
                    </div>

                    <div className="signin-form">
                        <h2 style={{ fontFamily: "Oswald"}} className="form-title">Change Password</h2>
                        <form className="register-form" id="login-form">
                        <div  style={{ fontFamily: "Oswald"}} className="form-group"> 
                                <div className="form-group">
                                
                                <label id="email"><i className="fa fa-envelope"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="email" name="email" id="email" placeholder="Your Email"
                                value={loggedUser.email}
                                disabled />
                            </div>

                            <div>
                            <div className="form-group">
                                
                                <label id="code"><i className="fa fa-key"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="password" name="oldPassword" id="oldPassword" placeholder="Old Password"
                                value={oldPassword}
                                onChange={(event)=>{setOldPassword(event.target.value)}}/>
                            </div>
                            <div className="form-group">
                                
                                <label id="password"><i className="fa fa-key"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="password" name="newPassword" id="newPassword" placeholder="New Password"
                                value={newPassword}
                                onChange={(event)=>{setNewPassword(event.target.value)}}/>
                            </div>
                            </div>
                            
                                   </div>
                           
                            <button style={{ fontFamily: "Oswald", border:"2px solid #1362b3"}} className="btn" onClick={(event)=>{handleChangePassword(event)}}>Change Password</button> 
                         
                        </form>
                    
                    </div>
                </div>
            </div>
        </section>


            </div>
    </>
    )

}