import React , {useContext,useEffect } from 'react';
import { Table } from 'reactstrap';
//import webhosting from '../../images/web.png';
import styled from 'styled-components';
import host from '../../../images/host.png';
import { ProductContext } from '../../../ProductProvider';
import 'balloon-css';

//import { faTeethOpen } from '@fortawesome/free-solid-svg-icons';

export default function WebPlans() {
    const { packages, addToCart, setBottomValue } = useContext(ProductContext);

    useEffect(() => {
         setBottomValue('');
         window.scrollTo(0,0);
         
    }, [])

   
    const handleAddToCart =(event, id) =>{
        event.preventDefault();
        addToCart(id,"New");

    }

    return (
     <>
        <div className="services-bn" >           
            <services id="" data-stellar-background-ratio="0.5">
   <div> 
   {/*plans and prices*/}

   <div style={{paddingLeft:"5px", paddingRight:"5px"}}>
   
   {packages.length>0?<div>

<Table hover responsive bordered>

<thead>
<tr><th>Plan ID</th>
<th>Plan Name</th>
<th>Plan Details</th>
<th>Cost/year</th>
 </tr> 
</thead>    
<tbody>
{packages.filter(plan => plan.packageId.includes('IDN-WH') ).map((searchresult,index) =>(
<tr key={index}>
<th scope="row" key={searchresult.packageId}>{searchresult.packageId}</th>
<td>{searchresult.packageName}</td>
<td>{(searchresult.packageDetails).map((plan, index)=>(<WebHostWrapper><ul key="{plan}"><li key="{plan}">{plan}</li> </ul></WebHostWrapper>))} </td>
<td><i className="fa fa-rupee"></i> {searchresult.packagePrice.toFixed(2)}<div>{searchresult.inCart?<balloon aria-label="In Cart" data-balloon-pos="up"><div style={{fontSize:"30px"}}><i className="fa fa-check-circle"></i></div></balloon>:<balloon aria-label="Add to Cart" data-balloon-pos="up"><div style={{fontSize:"30px"}}><i className="fa fa-cart-plus" onClick={(event)=>handleAddToCart(event, searchresult.packageId)}></i></div></balloon>}</div></td>
</tr>))}

</tbody></Table>


</div>:<div className="tile notification is-warning"></div>}  
     </div>

   </div>
    </services>
    </div>
    </>
    )
}
const WebHostWrapper = styled.nav`

ul {
     list-style: none;
   }
   
   ul li:before {
     content: '✓';
     padding: 5px;
   }
 
    
    `