import React, { useState, useContext } from 'react';
import './auth.css';
import { Auth } from "aws-amplify";
import { Alert } from 'reactstrap';
import {Link} from "react-router-dom";
import { phoneCode } from '../../packageDetails';
import signupImg from '../../images/signup-image.jpg';
import Preloader from '../preloader/Preloader';
import { ProductContext } from '../../ProductProvider';
import {Helmet} from 'react-helmet-async';

//import { faThermometerEmpty } from '@fortawesome/free-solid-svg-icons';


export default function SignUp () {
  const { alertDetails, setAlertDetails, alertClose } = useContext(ProductContext)
    const [phoneNo, setPhoneNo] = useState('');
    const [countryCode, setCountryCode]=useState('+91');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [orgName, setOrgName] = useState('');
    const [password, setPassword] = useState('');
    const [codeSent, setCodeSent] = useState(false);
    const [otp, setOTP] = useState('');
    //const [dispMsg, setDispMsg] = useState('');
    const [signUpSuccess, setSignUpSuccess] = useState(false);
    const [loaded, setLoaded] = useState(true);

    const generateOrderdate=()=>{
        const current_datetime = new Date();
        const temp="";
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        const orderDate = temp.concat(date_year,"-",month_value,"-", date_value);
        return orderDate;
    }

    const generateUserAccount = async () => {
      const current_datetime = new Date();
      const accountNoTemp = "";
      const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
      var date_month = (current_datetime.getMonth() + 1).toString();
      var month_value="";
      date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
      var date_date = (current_datetime.getDate().toString());
      var date_value="";
      date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
      var date_hours = (current_datetime.getHours().toString());
      var hours_value="";
      date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
      var date_minutes = (current_datetime.getMinutes().toString());
      var minutes_value="";
      date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
      const accountNo = accountNoTemp.concat(date_year,month_value, date_value, hours_value, minutes_value);
      return accountNo;
  }

    const handleSubmit = async event => {
        event.preventDefault();
        //setDispMsg('');
        const tempNameCheck=name.split(" ").join("").toUpperCase();
        try{
          if(phoneNo===""||email===""||password===""||name===""){
            //setDispMsg("Enter all details!");
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Enter all the details"
          }); 

          }else if(tempNameCheck.length<5){
            //setDispMsg("User Name should contain atleast 5 letters!");
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"User Name should contain atleast 5 letters!"
          }); 

          }
          else{
            setLoaded(false);
              const tempPhone = countryCode.toString()+phoneNo.toString();
              const signupDate=await generateOrderdate();
              const familyName= await generateUserAccount();
             // var familyName="";
              //const tempName=name.split(" ").join("").toUpperCase();
             // const tempSignUpDate = signupDate.split("-").join("");
              // familyName=familyName.concat(tempSignUpDate,"-",tempName.substring(0,5));
            //const signupDate = new Date().toLocaleString('en-US', {timeZone: 'Asia/Kolkata'});
            //console.log(" Phone : ",tempPhone );
           // const username=email;
              await Auth.signUp({
              username:email,
              password: password,
              attributes:{
                email,
                address:"",
                birthdate: signupDate,
                phone_number:tempPhone,
                family_name:familyName,//Client ID Name
                gender:"NA",
                name: name,
                given_name:orgName, //Org Name
                preferred_username:"NA",
                nickname:"NA",
                profile:"User",
                picture:"NA",
                website:"NA"
            }
          }).then(() => {
            setCodeSent(true);
            setLoaded(true);
            //setDispMsg("OTP has been sent to your Email address for validation");
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message:"OTP has been sent to your Email address for validation"
          }); 
  
          }).catch(error => {
            setLoaded(true);
            //setDispMsg(`Error Occured : ${error.message}`);
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured " + error.message
          }); 
  
          });
          
          }
        } catch (error){
          setLoaded(true);
          //setDispMsg(`Error Occured : ${error.message}`);
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured " + error.message
        }); 

        }
      };   
      
      const validateOTP =async(event)=>{
        event.preventDefault();
        //setDispMsg('');
        setSignUpSuccess(false);
        try{
          setLoaded(false);
          const username=email;
          const code = otp;
          await Auth.confirmSignUp(username, code)
          .then((signup) => {
            if(signup==="SUCCESS"){
              //setDispMsg("Your account has been confirmed Successfully");
              setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Your account has been confirmed Successfully!"
            });     
              setSignUpSuccess(true);
            }
            setLoaded(true);
          //console.log('SignUp Response: ', signup);
          //this.showSnackbar(`Awesome! Signup was Success `, 'success');
        }).catch(error => {
          //console.log('SignUp Error: ', error);
          //setDispMsg(`Error Occured : ${error.message}`);
          setLoaded(true);
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured " + error.message
        }); 

          //this.showSnackbar(`Something went wrong! `, 'danger');
        });

        }catch(error){
          //setDispMsg(`Error Occured : ${error.message}`);
          setLoaded(true);
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured " + error.message
        }); 

          //console.log("Error : ", error);
        }

      }

      const reSendOTP =async(event)=>{
        event.preventDefault();
        //setDispMsg('');
        try{
          setLoaded(false);
          const username=email;
          await Auth.resendSignUp(username);
          setLoaded(true);
          //setDispMsg("OTP Resent successfully");
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"OTP Resent successfully!"
        }); 
          ///console.log('code resent successfully');  
        }catch(error){
          setLoaded(true);
          //setDispMsg(`Error Occured : ${error.message}`);
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured " + error.message
        }); 
          //console.log("Error : ", error);
        }
      }

      
  return (
    <>
    <Helmet>
      <title>Sign Up - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing.</title>
      <meta name="description" content="Sign Up - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." />
      <meta name="keywords" content="Sign Up - Idntica - Web Hosting | Web Designing | Email Hosting | Digital Marketing." /> 
    </Helmet>

         {!loaded?<Preloader />:null}
          <div className="login"  style={{paddingTop:"100px"}}>        
            {/* Sign up form */}
            {!codeSent ?
            <section className="signup">
            <form>
                <div className="container">
                    <div className="signup-content">
                        <div className="signup-form">
                      <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
                    {alertDetails.message}
                    </Alert>

                            <h2 style={{ fontFamily: "Oswald"}} className="form-title">Sign Up</h2>

                            <div className="form-group">
                                
                                <label id="email"><i className="fa fa-envelope"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="email" name="email" id="email" placeholder="Your Email"
                                value={email}
                                onChange={(event)=>{setEmail(event.target.value)}}/>
                            </div>
                            <div className="form-group">
                                    <label id="pass"><i className="fa fa-key"></i></label>
                                    <input style={{ fontFamily: "Oswald"}} type="password" name="pass" id="pass" placeholder="Password"
                                    value={password}
                                    onChange={(event)=>{setPassword(event.target.value)}}/>
                                </div>
                            <div className="form-group">
                                
                                    <label id="text"><i className="fa fa-user"></i></label>
                                    <input style={{ fontFamily: "Oswald"}} type="text" name="name" id="userfullname" placeholder="Your Name" 
                                    value={name}
                                    onChange={(event)=>{setName(event.target.value)}}/>
                                </div>

                                <div className="form-group">
                                
                                    <label id="text"><i className="fa fa-user"></i></label>
                                    <input style={{ fontFamily: "Oswald"}} type="text" name="orgName" id="orgName" placeholder="Org Name" 
                                    value={orgName}
                                    onChange={(event)=>{setOrgName(event.target.value)}}/>
                                </div>
                            
                              
                                <div className="form-group"> 
                                
                             {/*   <label for="name"><i className="zmdi zmdi-account material-icons-name"></i>Phone number will be your login ID</label>   */}
                       
                                <span className="input-group-text" style={{background: "none", fontFamily: "Oswald", border: "none", padding: "0 0 0 0"}}>
                                
                                
                                <label id="email"><i className="fa fa-phone"></i>
                                <select  style={{fontFamily: "Oswald", border:"none", padding: "0px",  appearance:"none",
                                cursor: "pointer"}} value={countryCode} onChange={(event)=>{setCountryCode(event.target.value)}}>
                                 
                                   
                                    {phoneCode.map(item => {
                                        return (<option key={item.Country} value={item.Code}>{item.Code}</option>);
                                    })}
                                      </select>
                                      
                                </label>

                                      
                                <input style={{ fontFamily: "Oswald"}} type="number" aria-describedby="phoneHelp" className="name" id="phoneno" placeholder="Enter Phone No" value={phoneNo}
                                onChange={(event)=>{setPhoneNo(event.target.value)}}/></span>
                                </div>
                                    <div className="form-group">
                                   
                                    By clicking on Sign Up - I agree to all statements in  <Link to="/policies" className="term-service">Terms of service.</Link>
                                </div>
                                <div className="form-group form-button">
                               
                                   {/*} <input style={{ fontFamily: "Oswald"}} type="submit" name="signup" id="signup" className="form-submit" value="Register"/>*/}
                                   <button style={{ fontFamily: "Oswald", border:"1px solid blue"}} className="btn" onClick={(event)=>{handleSubmit(event)}}>Sign Up</button>
                                   
                                </div>
                        </div>
                        <div className="signup-image">
                            <figure><img src={signupImg} alt="sing up"/></figure>
                            <Link to="/signin" className="signup-image-link">I am already member</Link>
                        </div>
                    </div>
                </div>
                </form>
            </section>:
            <section className="signup">
            <form>
                <div className="container">
                    <div className="signup-content">
                        <div className="signup-form">
                            <h2 style={{ fontFamily: "Oswald"}} className="form-title">Confirm - Sign Up</h2>
                            <div>
                                {signUpSuccess?<div></div>:<div className="form-group">
                                
                                    <label id="email"><i className="fa fa-key"></i></label>
                                    <input style={{ fontFamily: "Oswald"}} type="text" name="otp" id="otp" placeholder="Your OTP"
                                    value={otp}
                                    onChange={(event)=>{setOTP(event.target.value)}}/>
                                </div>}</div>
                                <div>
                                {signUpSuccess?<Link to="/signin" className="signup-image-link">Click here to Sign In</Link>
                                :<div className="form-group form-button">
                               
                                   {/*} <input style={{ fontFamily: "Oswald"}} type="submit" name="signup" id="signup" className="form-submit" value="Register"/>*/}
                                   <button style={{marginRight:'25px'}} onClick={(event)=>{validateOTP(event)}}>Confirm</button>
                                   <button style={{marginRight:'25px'}} onClick={(event)=>{reSendOTP(event)}}>Re-Send OTP</button>

                                </div>}</div>
                        </div>
                        <div className="signup-image">
                            <figure><img src={signupImg} alt="sign up"/></figure>
                            <Link to="/signin" className="signup-image-link">I am already member</Link>
                        </div>
                    </div>
                </div>
                </form>
            </section>}
     </div>
     </>
        )
    
}
